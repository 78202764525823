import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import MenuItem from '@mui/material/MenuItem';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { move_category } from 'modules/api/market-api';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function MoveCategoryDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_item_url, setEditedItemUrl ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  const [list_name, setListName] = React.useState('');

  const handleChange = (event: SelectChangeEvent) => {
    setListName(event.target.value);
  };

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // if(props.products.length > 0 && props.edit_item_index !== -1) {
    //   let acitve_index = props.products.findIndex((ele:any) => ele.active === true)
    //   setEditedItemUrl(props.products[acitve_index].items[props.edit_item_index].DetailPageURL)
    // }
  }, [])

  const handleClose = () => {
    props.setMoveCategoryIndex(-1)
    setListName('');
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        if ( list_name === '' ) {

        } else {
          
          setIsLoading(true)

          const update_result = await move_category(props.products[props.index]._id, list_name)

          if ( update_result.status === 'success' ) {
            props.setProducts((prev:any[]) => {
              let next = [...prev]

              next.splice(props.index, 1)

              return next
            })
            setIsLoading(false)
            props.setOpen(false)
          } else {
            setIsLoading(false)
            props.setOpen(false)
          }
        }
        
        // setIsLoading(true)
        // let acitve_index = props.products.findIndex((ele:any) => ele.active === true)
        // let update_result = await update_item(props.products[acitve_index]._id, props.edit_item_index, edited_item_url)

        // if ( update_result.status === 'success' ) {
        //   props.setProducts((prev: any[]) => {
        //     let next = [...prev]
        //     let acitve_index = next.findIndex((ele:any) => ele.active === true)
        //     next[acitve_index].items[props.edit_item_index].DetailPageURL = edited_item_url

        //     return next
        //   })
        //   setIsLoading(false)
        //   props.setOpen(false);
        // } else if ( update_result.status === 'error' ) {
        //   setIsLoading(false)
        //   props.setOpen(false);
        // }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItemUrl(e.target.value)
  }

  return (
    isLoading === true ? 
        <> 
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
              >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Saving Data ...</p>
              </Backdrop>
              <DialogTitle>Move Category</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  move to:
                </DialogContentText>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={list_name}
                  onChange={handleChange}
                  label=""
                  sx={{width: '100%'}}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    props.move_list_array.length > 0 && props.move_list_array.map((ele:any, index:number) => {
                      return (
                        <MenuItem key={index} value={ele.title._id}>{ele.title._id}</MenuItem>
                      )
                    })
                  }
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </> :
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <DialogTitle>Move Category</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  move to:
                </DialogContentText>
                <Select
                  labelId="demo-simple-select-standard-label"
                  id="demo-simple-select-standard"
                  value={list_name}
                  onChange={handleChange}
                  label=""
                  sx={{width: '100%'}}
                >
                  <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  {
                    props.move_list_array.length > 0 && props.move_list_array.map((ele:any, index:number) => {
                      return (
                        <MenuItem key={index} value={ele.title._id}>{ele.title._id}</MenuItem>
                      )
                    })
                  }
                </Select>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}
