import styled from 'styled-components'
import { Box } from '@mui/material'

const TrustBox = (props: any) => {
    
    return (
        <CustomBox className="parent_box">
            <CustomBox className="center_box">
                <div className="why_title_div">
                    <span className="why_title">
                        Why trust us?
                    </span>
                </div>
                <div className="why_text_div">
                    <span className="why_text">
                    Our Machine Learning models parse through thousands of data bases to cut through all the noise, and bring you only the products that deserve your attention. Spend your time enjoying life, not shopping!
                    </span>
                </div>    
            </CustomBox>
            <CustomBox className="right_box">
                <img src={require('assets/trust.svg').default} alt="" />
            </CustomBox>
        </CustomBox>
    )
}

const CustomBox = styled(Box)`
    width: 100%;
    display: flex;
    margin: 10px 0px;
    border-radius: 10px;
    position: relative;
    &.parent_box {
        background-color: #1e947e;
    }
    .left_box {
        margin: 0;
        width: 180px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding-left: 30px;
        img { 
            // width: 175px;
            // height: 175px;
            
        }
    }
    .center_box {
        margin: 0;
        width: auto;
        flex-grow: 1;
        display: flex!important;
        flex-direction: column;
        align-items: start!important;
        justify-content: center!important;
        padding-left: 30px;
        padding-right: 30px;
        
        .why_title_div {
            padding: 25px 0px;
        }

        .why_title {
            font-family: GT Pressura,sans-serif;
            font-weight: 700;
            line-height: 1.25;
            font-size: 32px;
            letter-spacing: 0.01em;
            color: white;
        }

        .why_text_div {
            padding: 0 0 50px 0px;
        }

        .why_text {
            font-family: sans-serif;
            font-size: 18px;
            margin: 0;
            color: #FDFDFD;
            display: block;
        }
        
    }
    .right_box {
        margin: 0;
        display: flex!important;
        align-items: center!important;
        justify-content: end!important;
        padding-right: 45px;
        img {
            width: 100%;
            height: 110px;
            }
        .MuiBox-root {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
`

export default TrustBox