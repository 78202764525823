import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { ListItemButton } from '@mui/material'
import { uploadImage, update_each_quiz_image_url_by_id, generate_img_via_openai_for_each_quiz, save_image_on_server_and_update_each_quiz_image_url_by_id, save_image_on_server_and_s3_and_update_each_quiz_image_url_by_id } from 'modules/api/market-api'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import "./tabpanel.css"
import { IconButton  } from '@mui/material'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto';
import { screenSizes } from 'shared/styles/theme'
import WbAutoIcon from '@mui/icons-material/WbAuto';
import history from 'modules/app/components/history';
import GenerateAiImageDialog from './landing_page/GenerateAiImageDialog'

const QuizDropzone = (props: any) => {
    const [ imageSaveBtn_show, setImageSaveBtnShow ] = useState<boolean>(false)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ loadingStr, setLoadingStr ] = useState<string>('')

    const [ files, setFiles ] = useState<any>([])
    const [ avatar, setAvatar ] = useState<string | undefined>('')
    const [ image_location_flag, setImageLocationFlag ] = useState<string>('');

    const [ generateAiImageDialogOpen, setGenerateAiImageDialogOpen ] = useState<boolean>(false)
    const [ prompt, setPrompt ] = useState<string>('')
    const [ editProductId, setEditProductId ] = useState<string>('')
    const [ edit_quiz_index, setEditQuizIndex ] = useState<number>(0)

    useEffect(() => {
        setImageSaveBtnShow(false)
        if ( props.image_url === undefined ) {
            setFiles([])
        } else {
            setFiles([{preview: props.image_url}])
            
        }

        setEditProductId(props.products[props.editProductindex]._id)
        setEditQuizIndex(props.edit_quiz_index)

        // console.log(props.products[props.editProductindex].quiz[props.edit_quiz_index].prompt)
        if (props.products[props.editProductindex].quiz[props.edit_quiz_index].prompt === undefined || props.products[props.editProductindex].quiz[props.edit_quiz_index].prompt.trim() === "") {
            let each_quiz = props.products[props.editProductindex].quiz[props.edit_quiz_index]
            // console.log(each_quiz)
            let prompt = ''
            prompt = prompt + each_quiz.question
            for (let i = 0; i < each_quiz.choices.length; i ++) {
                prompt = prompt + '\n' + '- ' + each_quiz.choices[i].choice
            }
            
            prompt = prompt + '\n\n' + 'For the above question and answers, please provide a photo of ' + props.products[props.editProductindex].product_name + ' without any text'
            setPrompt(prompt)
        } else {
            setPrompt(props.products[props.editProductindex].quiz[props.edit_quiz_index].prompt)
        }
    }, [props])

    // useEffect(() => {
        
    // }, [generateAiImageDialogOpen])

    const generate_img_via_openai = (e:any) => {
        (async () => {
            try {
                e.stopPropagation()
                setLoadingStr("Generating Image ... ")
                setIsLoading(true)

                let generate_result:any = await generate_img_via_openai_for_each_quiz(props.products[props.editProductindex]._id, props.edit_quiz_index)

                if ( generate_result.status === 'success' ) {
                    // console.log(generate_result.image_url)
                    setFiles([{preview: generate_result.image_url}])
                    setImageLocationFlag('openai')
                    setImageSaveBtnShow(true)

                    setIsLoading(false)

                } else if ( generate_result.status === 'error' ) {
                    setIsLoading(false)
                    history.push('/admin/signin')
                }
            } catch (e) {
                setIsLoading(false)
                history.push('/admin/signin')
                console.log(e)
            }
        })()
    }

    const save_edited_image = (e: any) => {
        try {
            (async () => {
                e.stopPropagation()
                setLoadingStr("Saving Image ... ")
                setIsLoading(true)

                if ( image_location_flag === 'local' ) {
                    let formData: FormData = new FormData()
                    formData.append('file', files[0])
                    let img_upload_result = await uploadImage(formData)
                    
                    if ( img_upload_result.status === 'success' ) {
                        let image_url = img_upload_result.data.Location
                        let update_result:any = await update_each_quiz_image_url_by_id(props.products[props.editProductindex]._id, props.edit_quiz_index, image_url)
            
                        if ( update_result.status === 'success' ) {
                          props.setProducts((prev: any[]) => {
                            let next = [...prev]
                            next[props.editProductindex].quiz[props.edit_quiz_index] = Object.assign(next[props.editProductindex].quiz[props.edit_quiz_index], {image_url: image_url})
                            return next
                          })
            
                          setIsLoading(false)
                        } else if ( update_result.status === 'error' ) {
                          setIsLoading(false)
                        }
                    } else if ( img_upload_result.status === 'error' ) {
                        setIsLoading(false)
                    }
                } else if ( image_location_flag === 'openai' ) {
                    let image_url = files[0].preview
                    // let update_result:any = await save_image_on_server_and_update_each_quiz_image_url_by_id(props.products[props.editProductindex]._id, props.edit_quiz_index, image_url, prompt)
                    let update_result:any = await save_image_on_server_and_s3_and_update_each_quiz_image_url_by_id(props.products[props.editProductindex]._id, props.edit_quiz_index, image_url, prompt)
        
                    if ( update_result.status === 'success' ) {
                      props.setProducts((prev: any[]) => {
                        let next = [...prev]
                        next[props.editProductindex].quiz[props.edit_quiz_index] = Object.assign(next[props.editProductindex].quiz[props.edit_quiz_index], {image_url: image_url})
                        next[props.editProductindex].quiz[props.edit_quiz_index] = Object.assign(next[props.editProductindex].quiz[props.edit_quiz_index], {prompt: prompt})
                        return next
                      })
        
                      setIsLoading(false)
                    } else if ( update_result.status === 'error' ) {
                      setIsLoading(false)
                    }
                }
            })()
        } catch ( e ) {
            // console.log(e)
            setIsLoading(false)
            return 
        }
    }

    const { getRootProps: getRootFileProps, getInputProps: getInputFileProps } = useDropzone({
        accept: {
            'image/*': [],
        },
        onDrop: (acceptedFiles) => {
            setImageLocationFlag("local")
            setFiles(
                acceptedFiles.map((file) =>{
                    return Object.assign(file, {
                        preview: URL.createObjectURL(file),
                    })
                    }
                )
            )

            setImageSaveBtnShow(true)
        },
        multiple: false
    })

    const thumbs = files.map((file:any, index:number) => (
        <div className="imageuploadinner" key={index}>
            {
                file.preview === '' || file.preview === undefined ? <></>
                :
                <img
                    src={file.preview}
                    // Revoke data uri after image is loaded
                    onLoad={() => {
                    URL.revokeObjectURL(file.preview)
                    }}
                />
            }
        </div>
    ))

    const generate_btn_click = (e: any) => {
        e.stopPropagation()
        setGenerateAiImageDialogOpen(true)
      }
    
    return (
        <>
        <CustomDiv1 className={'img_div'}>
        {
            isLoading === true ?
            <>
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                    {/* <p>&nbsp;&nbsp; Saving Data ...</p> */}
                    <p>&nbsp;&nbsp; {loadingStr}</p>
                </Backdrop>
            </>
            :<></>
        }
            <Page>
            <section className="imginner">
                <aside className="imageupload">
                    <div className={'img_btn_container'}>
                        <div {...getRootFileProps({ className: 'dropzone' })}>
                            <input {...getInputFileProps()} />
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                className="icon_btn_right"
                            >
                                <InsertPhotoIcon />
                            </IconButton>
                        </div>
                        <div>
                            <IconButton
                                aria-label="more"
                                id="long-button"
                                aria-haspopup="true"
                                className="icon_btn_left"
                                // onClick={(e) => {generate_img_via_openai(e)}}
                                onClick={(e) => {generate_btn_click(e)}}
                            >
                                <WbAutoIcon />
                            </IconButton>
                        </div>
                    </div>
                    {
                        files.length > 0 ? (
                            thumbs
                        ) : 
                        avatar === '' || avatar === undefined ? (
                            <div className="imageuploadinner">
                                <img src={require('assets/empty.png').default} alt="Blank" />
                            </div>
                        ) : (
                            <div className="imageuploadinner">
                                <img src={avatar} alt="Avatar" />
                            </div>
                        )
                    }
                </aside>
                <div className="dropzoneOuter">  
                {
                    imageSaveBtn_show === true ?
                    <CustomListItemButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        className="image_save_btn"
                        onClick={(e) => save_edited_image(e)}
                    >
                        save
                    </CustomListItemButton>
                    : <></>
                }                                              
                </div>
                
            </section>
            </Page>
        </CustomDiv1>
        <GenerateAiImageDialog
            open={generateAiImageDialogOpen}
            setOpen={setGenerateAiImageDialogOpen}
            prompt={prompt}
            setPrompt={setPrompt}
            setFiles={setFiles}
            setImageSaveBtnShow={setImageSaveBtnShow}
            setImageLocationFlag={setImageLocationFlag}
            editProductId={editProductId}
            edit_quiz_index={edit_quiz_index}
        />
        </>
    )
}

const CustomDiv1 = styled.div`
  
`
const Page = styled.div`
  color: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;
  
  .imginner {
    // border: 1px solid rgba(0,0,0,0.05)!important;
    // border-radius: 24px!important;
  }
  
  .imageupload {
    display: flex;
    flex-direction: column;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 200px;
        height: 200px;
      }
      width: 150px;
      height: 150px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        border-radius: 24px!important;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white!important;
          color: black;
        }
      }
    }
    .img_btn_container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
    }
    .icon_btn_right {
      color: rgb(120, 115, 115);
      border:1px solid transparent!important;
    //   position: absolute;
    //   top: 2%;
    //   right: 2%;
      transition: all 300ms ease-in-out;
      &:hover {
        background-color: #ffffff7a!important;
        color: #049cf7;
        border: 1px solid #e3ecf35e!important;
        box-shadow: 0 1px 15px rgb(178 217 247 / 52%), 0 1px 15px rgb(117 169 235 / 23%)!important;
      }
    }
    .icon_btn_left {
        color: rgb(120, 115, 115);
        border:1px solid transparent!important;
        // position: absolute;
        // top: 2%;
        // left: 2%;
        transition: all 300ms ease-in-out;
        &:hover {
          background-color: #ffffff7a!important;
          color: #049cf7;
          border: 1px solid #e3ecf35e!important;
          box-shadow: 0 1px 15px rgb(178 217 247 / 52%), 0 1px 15px rgb(117 169 235 / 23%)!important;
        }
      }
  }
  
  .dropzoneOuter {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
  
`

const CustomListItemButton = styled(ListItemButton)`
    &.image_save_btn {
        width: fit-content!important;
        margin: auto!important;
        color: rgba(0, 0, 0, 0.4);
    }
    &.image_save_btn:hover {
        width: fit-content!important;
        margin: auto!important;
        color: rgba(0, 0, 0);
    }
`

export default QuizDropzone