import styled from 'styled-components'
import { Divider, Box } from '@mui/material'
 
interface Data {
  username: string;
  gclid: string;
  msclkid: string;
  amazon_tracking_id: string;
  visited_url: string;
  levanta_source_name: string;
  levanta_source_id: string;
  levanta_link_id: string;
  time_stamp: string;
}

interface Props {
  rows: Data[]
}

const MobileRecords = (props: Props) => {
  const { rows } = props

  return (
    <>
      {
        rows.map((row: Data, index: number) => {
          return (
            <CustomBox key={index} className="parent_box">
              <CustomDiv className="child_div d_column">
                <CustomSpan>User Name: </CustomSpan>
                <CustomSpan className="value">{row.username}</CustomSpan>
              </CustomDiv>
              <Divider />
              <CustomDiv className="child_div d_column">
                <CustomSpan>Tracking Id: </CustomSpan>
                <CustomSpan className="value">{row.amazon_tracking_id}</CustomSpan>
              </CustomDiv>
              <Divider />
              <CustomDiv className="child_div d_column">
                <CustomSpan>Gcl Id: </CustomSpan>
                <CustomSpan className="value">{row.gclid}</CustomSpan>
              </CustomDiv>
              <Divider />
              <CustomDiv className="child_div d_column">
                <CustomSpan>Msclk Id: </CustomSpan>
                <CustomSpan className="value">{row.msclkid}</CustomSpan>
              </CustomDiv>
              <Divider />
              <CustomDiv className="child_div d_column">
                <CustomSpan>Visited URL: </CustomSpan>
                <CustomSpan className="value">{row.visited_url}</CustomSpan>
              </CustomDiv>
              <CustomDiv className="child_div d_column">
                <CustomSpan>levanta source name: </CustomSpan>
                <CustomSpan className="value">{row.levanta_source_name}</CustomSpan>
              </CustomDiv>
              <CustomDiv className="child_div d_column">
                <CustomSpan>levanta source id: </CustomSpan>
                <CustomSpan className="value">{row.levanta_source_id}</CustomSpan>
              </CustomDiv>
              <CustomDiv className="child_div d_column">
                <CustomSpan>levanta link id: </CustomSpan>
                <CustomSpan className="value">{row.levanta_link_id}</CustomSpan>
              </CustomDiv>
              <CustomDiv className="child_div d_column">
                <CustomSpan>Time Stamp: </CustomSpan>
                <CustomSpan className="value">{row.time_stamp}</CustomSpan>
              </CustomDiv>
            </CustomBox>
          )
        })
      }
      
    </>                                                               
  );
};

const CustomDiv = styled.div`

`
const CustomSpan = styled.span`
  &.value {
    padding-left: 5px!important;
  }
`

const CustomBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 3px 0;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  &.parent_box {
    padding: 3px 9px!important;
    margin: 10px 0!important;
    .child_div {
      margin: 6px 0!important;
    }
    .d_column {
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
    }
  }
`

export default MobileRecords