import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_item_title } from 'modules/api/market-api';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function EditItemDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_item_brand, setEditedItemBrand ] = React.useState<string>("");
  const [ edited_item_title, setEditedItemTitle ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    if(props.products.length > 0 && props.edit_item_title_index !== -1) {
      let acitve_index = props.products.findIndex((ele:any) => ele.active === true)
      setEditedItemBrand(props.products[acitve_index].items[props.edit_item_title_index].ItemInfo.ByLineInfo.Brand.DisplayValue)
      setEditedItemTitle(props.products[acitve_index].items[props.edit_item_title_index].ItemInfo.Title.DisplayValue)
    }
  }, [props.edit_item_title_index])

  const handleClose = () => {
    props.setEditItemTitleIndex(-1)
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)
        let acitve_index = props.products.findIndex((ele:any) => ele.active === true)
        let temp_items = props.products[acitve_index].items
        temp_items[props.edit_item_title_index].ItemInfo.ByLineInfo.Brand.DisplayValue = edited_item_brand
        temp_items[props.edit_item_title_index].ItemInfo.Title.DisplayValue = edited_item_title
        
        let update_result = await update_item_title(props.products[acitve_index]._id, props.edit_item_title_index, temp_items)

        if ( update_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            let acitve_index = next.findIndex((ele:any) => ele.active === true)
            next[acitve_index].items[props.edit_item_title_index].ItemInfo.ByLineInfo.Brand.DisplayValue = edited_item_brand
            next[acitve_index].items[props.edit_item_title_index].ItemInfo.Title.DisplayValue = edited_item_title

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( update_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleBrandChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItemBrand(e.target.value)
  }

  const handleTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedItemTitle(e.target.value)
  }

  return (
    isLoading === true ? 
        <> 
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
              >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Saving Data ...</p>
              </Backdrop>
              <DialogTitle>Edit Item's Brand and Title</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Brand:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={1}
                  maxRows={1}
                  value={edited_item_brand}
                  onChange={handleBrandChange}
                  // onKeyDown={handleKeyDown}
                />
                <DialogContentText>
                  Title:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={5}
                  maxRows={5}
                  value={edited_item_title}
                  onChange={handleTitleChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </> :
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <DialogTitle>Edit Item's Brand and Title</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Brand:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={1}
                  maxRows={1}
                  value={edited_item_brand}
                  onChange={handleBrandChange}
                  // onKeyDown={handleKeyDown}
                />
                <DialogContentText>
                  Title:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={5}
                  maxRows={5}
                  value={edited_item_title}
                  onChange={handleTitleChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}
