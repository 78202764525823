import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { add_new_item_to_product } from 'modules/api/market-api';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function AddItemDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ item_detail_page_url, setItemDetailPageUrl ] = React.useState<string>('');
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // setItemData(props.imgArr)
  }, [props.imgArr])

  const handleClose = () => {
    setItemDetailPageUrl('')
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)
        let active_product_index = props.products.findIndex((ele:any) => ele.active === true)

        let added_result = await add_new_item_to_product(props.products[active_product_index]._id, item_detail_page_url)

        if ( added_result.status === 'success' ) {

          props.setProducts((prev: any[]) => {
            let next = [...prev]
            let active_index = next.findIndex(ele => ele.active === true)

            next[active_index].items = [...added_result.add_result.items]

            return next
          })

          setIsLoading(false)
          props.setOpen(false);
          setItemDetailPageUrl("")
        } else if ( added_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          setItemDetailPageUrl("")
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleCategoryChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setItemDetailPageUrl(e.target.value)
  }

  return (
    isLoading === true ? 
        <> 
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
              >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Saving Data ...</p>
              </Backdrop>
              <DialogTitle>Add New Item</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Url:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={10}
                  maxRows={10}
                  value={item_detail_page_url}
                  onChange={handleCategoryChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </> :
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth >
              <DialogTitle>Add New Item</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Url:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={10}
                  maxRows={10}
                  value={item_detail_page_url}
                  onChange={handleCategoryChange}
                  // onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}