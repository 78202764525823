import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { delete_item_from_product } from 'modules/api/market-api';

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function DelItemDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
  }, [props.open])

  React.useEffect(() => {
    // setItemData(props.imgArr)
  }, [props.imgArr])

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let active_product_index = props.products.findIndex((ele:any) => ele.active === true)
        let active_product_id = props.products[active_product_index]._id

        const delete_result = await delete_item_from_product(active_product_id, props.index)

        if( delete_result.status === 'success' ) {
            props.setProducts((prev: any[]) => {
                let new_items = [...prev]    

                let active_product_index = new_items.findIndex((ele:any) => ele.active === true)
                new_items[active_product_index].items.splice(props.index, 1)
                return new_items
            })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( delete_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  return (
    isLoading === true ? 
        <> 
          <div>
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="draggable-dialog-title"
          >
          <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
          >
              <CircularProgress color="inherit"/>
              <p>&nbsp;&nbsp; Deleting Item ...</p>
          </Backdrop>
            <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
              Delete!
            </DialogTitle>
            <DialogContent>
              <DialogContentText>
                This item will be deleted, are you okay?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button onClick={handleClose}>Delete</Button>
            </DialogActions>
          </Dialog>
          </div>
        </> :
        <>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
            >
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Delete!
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This item will be deleted, are you okay?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>Delete</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}