import { withRouter } from 'react-router'
import styled from 'styled-components'
import {
  MobileNavWrapper,
  MobileNavCont,
  MobNavHeader,
  MobNavItemCont,
  MobNavDivider,
} from './style'

import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import history from 'modules/app/components/history'

const MobileSideNav = (props: any) => {
  const { showSideNav, setShowSideNav, menu_data } = props

  const changeProduct = (product: any) => {
    let product_url = product.toLowerCase().split(' ').join('-')
    history.push(`/${product_url}`)
    setShowSideNav(false)
  }

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 99 }}
      open={showSideNav}
      onClick={() => { setShowSideNav(false) }}
    >
        
    <MobileNavWrapper open={showSideNav} onClick={(e: any) => { e.stopPropagation() }}>
      <MobileNavCont>
        <MobNavHeader>
          <FlexSBCont>
            <FlexCont>
              <img src={require('assets/logo_avatar3.png').default} alt="" />
              &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan>
            </FlexCont>
            <ClearIcon className="cross-icon" onClick={() => setShowSideNav(false)}/>
          </FlexSBCont>
        </MobNavHeader>
        <MobNavItemCont>
          {
            menu_data.length > 0 && menu_data.map((item: any, key: number) => (
            // menuData.length > 0 && menuData.map((item: any, key: number) => (
              <CustomAccordion key={key}>
                <CustomAccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1a-content"
                  id="panel1a-header"
                >
                  {item.title._id}
                </CustomAccordionSummary>
                {
                  item.options.length > 0 && item.options.map((option: any, key: number) => (
                    <CustomAccordionDetails key={key} onClick={() => {changeProduct(option.product_name)}}>
                      {option.product_name}
                    </CustomAccordionDetails>
                  ))
                }
              </CustomAccordion>
            ))
          }

          <MobNavDivider />
        </MobNavItemCont>
      </MobileNavCont>
    </MobileNavWrapper>
    </Backdrop>

  )
}

const CustomAccordion = styled(Accordion)`
  background: none!important;
  box-shadow: none!important;
`

const CustomAccordionSummary = styled(AccordionSummary)`
  color: black!important;
  font-size: 18px!important;
  font-weight: 800!important;
  font-family: GT Pressura,sans-serif;
`

const CustomAccordionDetails = styled(AccordionDetails)`

`

const FlexSBCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  div.discountCard{
    display:flex;
    height:75px;
  }
  &.discountLevel{
    display: grid;
  }
  &.mobileDropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
  }
  @media (min-width: 800px) {
    div.borrowLimit{
      display: flex;
    }
  }
  
`

const FlexCont = styled.div`
  display: flex;
  align-items: center;
  img {
    // width: 128px;
    // height: 33px;
    width: 33px;
    height: 33px;
  }
  &.borrow-limit{
    width: auto;
    text-align: center;
  }
  &.netApyWeb{
    display: flex;
    justify-content: center;
  }
  &.LendingStats{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const CustomSpan = styled.span`
  font-size: 20px;
  color: black!important;
  @media (min-width: 800px) {
    font-size: 25px;
  }
`

const BoldSpan = styled.span`
  font-weight: 700;
`

export default withRouter(MobileSideNav)
