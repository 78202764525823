import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_quiz_product_url_by_id } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function EditProductUrlDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_product_url, setEditedProductUrl ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
    if(props.products.length > 0 && props.edit_product_index !== -1) {
      setEditedProductUrl(props.products[props.edit_product_index].models[props.products[props.edit_product_index].selected_model_index].product_url)
    }
  }, [props.open])

  const handleClose = () => {
    // props.setEditProductIndex(-1)
    setEditedProductUrl("")
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)

        let update_result = await update_quiz_product_url_by_id(props.products[props.edit_product_index]._id, props.products[props.edit_product_index].selected_model_index, edited_product_url.trim())

        if ( update_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            next[props.edit_product_index].models[next[props.edit_product_index].selected_model_index].product_url = edited_product_url.trim()

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( update_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleProductUrlChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedProductUrl(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  return (
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
              {
                isLoading === true?
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                >
                    <CircularProgress color="inherit"/>
                    <p>&nbsp;&nbsp; Saving Data ...</p>
                </Backdrop>
                : <></>
              }
              <DialogTitle>Edit Product Url</DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Product Url:
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={5}
                  maxRows={5}
                  value={edited_product_url}
                  onChange={handleProductUrlChange}
                  onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}