import { SET_CURRENT_PRODUCT, SET_CURRENT_PRODUCT_ASIN, SET_CURRENT_GCLID_CURRENT_SEARCH } from './action.config'

export const setCurrentProductUrl = (productUrl: string | undefined) => {
    return {
        type: SET_CURRENT_PRODUCT,
        payload: { productUrl }
    }
}

export const setCurrentProductAsin = (asin: string | undefined) => {
    return {
        type: SET_CURRENT_PRODUCT_ASIN, 
        payload: { asin }
    }
}

export const setCurrentGclidAndCurrentSearch = (gclid: string | undefined, msclkid: string | undefined, search: string | undefined) => {
    return {
        type: SET_CURRENT_GCLID_CURRENT_SEARCH,
        payload: { gclid, msclkid, search }
    }
}
