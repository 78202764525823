import styled from 'styled-components'
import { Grid, Tab, Box } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import TabContext from '@mui/lab/TabContext'
import TabList from '@mui/lab/TabList'
import TabPanel from '@mui/lab/TabPanel'
import MobileRecords from './components/MobileRecords'
import MobileUsers from './components/MobileUsers'

const MobileDiv = (props: any) => {
    const {value, setValue, rows_records, rows_users} = props;
    // const [value, setValue] = useState('1')
    const handleChange = (event: React.SyntheticEvent, newValue: string) => {
        setValue(newValue)
    }

    return (
        <CustomWrapperItems>
            <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important'}}>
                <CustomGrid item container spacing={0} sm={12} className="grid_main" sx={{background: 'transparent!important'}} >
                    <Box sx={{ width: '100%', typography: 'body1' }}>
                        <TabContext value={value}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <TabList onChange={handleChange} aria-label="lab API tabs example">
                                    <Tab label="Records" value="1" />
                                    <Tab label="Users" value="2" />
                                </TabList>
                            </Box>
                            <CustomTabPanel value="1"><MobileRecords rows={rows_records} /></CustomTabPanel>
                            <CustomTabPanel value="2"><MobileUsers rows={rows_users} /></CustomTabPanel>
                        </TabContext>
                    </Box>
                </CustomGrid>
            </CustomGrid>
        </CustomWrapperItems> 
    );
};

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`

const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  .grid_main {
    display: flex;
    justify-content: start;
  }
`

const CustomTabPanel = styled(TabPanel)`
  padding: 0!important;
`

export default MobileDiv