import { useState, useEffect } from 'react'
import { useDropzone } from 'react-dropzone'
import styled from 'styled-components'
import { Grid, ListItemButton } from '@mui/material'
import { read_all_quiz, uploadImage, update_quiz_image_url_by_id, update_quiz_selected_model_index_by_id } from 'modules/api/market-api'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd'
import AddQuizDialog from './landing_page/AddQuizDialog'
import Backdrop from '@mui/material/Backdrop'
import CircularProgress from '@mui/material/CircularProgress'
import history from 'modules/app/components/history'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline'
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline'
import './tabpanel.css'
import EditProductUrlDialog from './landing_page/EditProductUrlDialog'
import EditProductNameDialog from './landing_page/EditProductNameDialog'
import EditBestUrlDialog from './landing_page/EditBestUrlDialog'
import EditModelNameDialog from './landing_page/EditModelNameDialog'
import EditIframeUrlDialog from './landing_page/EditIframeUrlDialog'
import EditDescriptionDialog from './landing_page/EditDescriptionDialog'
import EditResultPromptDialog from './landing_page/EditResultPromptDialog'
import RegenQuestionDialog from './landing_page/RegenQuestionDialog'
import AddOneQuizDialog from './landing_page/AddOneQuizDialog'
import EditOneQuizDialog from './landing_page/EditOneQuizDialog'
import DeleteOneQuizDialog from './landing_page/DeleteOneQuizDialog'
import DeleteQuizDialog from './landing_page/DeleteQuizDialog'
import AddIcon from '@mui/icons-material/Add'
import { IconButton } from '@mui/material'
import InsertPhotoIcon from '@mui/icons-material/InsertPhoto'
import PublishedWithChangesIcon from '@mui/icons-material/PublishedWithChanges'
import { screenSizes } from 'shared/styles/theme'
import { Theme } from '@mui/material/styles'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'
import AddNewModelDialog from './landing_page/AddNewModelDialog'
import AddMultpleNewModelsDialog from './landing_page/AddMultpleNewModelsDialog'
import MobileHeader from './mobile_page/MobileHeader'
import QuizDropzone from './QuizDropzone'

const TabPanelDiv = (props: any) => {
  const { list_name } = props
  const [products, setProducts] = useState<any>([])
  const [selected_product, setSelectedProduct] = useState<any>(null)
  const [addDialogOpen, setAddDialogOpen] = useState<boolean>(false)
  const [addNewModelDialogOpen, setAddNewModelDialogOpen] = useState<boolean>(false)
  const [addMultipleNewModelsDialogOpen, setAddMultipleNewModelsDialogOpen] = useState<boolean>(false)

  const [isFirstLoading, setIsFirstLoading] = useState<boolean>(false)
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [imageSaveBtn_show, setImageSaveBtnShow] = useState<boolean>(false)
  const [imageSaveBtn_show1, setImageSaveBtnShow1] = useState<boolean>(false)

  const [editProductindex, setEditProductIndex] = useState<number>(-1)
  const [deleteProductindex, setDeleteProductIndex] = useState<number>(-1)
  const [editProductUrlDialogOpen, setEditProductUrlDialogOpen] = useState<boolean>(false)
  const [editProductNameDialogOpen, setEditProductNameDialogOpen] = useState<boolean>(false)
  const [editBestUrlDialogOpen, setEditBestUrlDialogOpen] = useState<boolean>(false)
  const [editModelNameDialogOpen, setEditModelNameDialogOpen] = useState<boolean>(false)
  const [editIframeUrlDialogOpen, setEditIframeUrlDialogOpen] = useState<boolean>(false)
  const [regenQuestionDialogOpen, setRegenQuestionDialogOpen] = useState<boolean>(false)
  const [addOneQuizDialogOpen, setAddOneQuizDialogOpen] = useState<boolean>(false)
  const [editOneQuizDialogOpen, setEditOneQuizDialogOpen] = useState<boolean>(false)
  const [editDescriptionDialogOpen, setEditDescriptionDialogOpen] = useState<boolean>(false)
  const [editResultPromptDialogOpen, setEditResultPromptDialogOpen] = useState<boolean>(false)
  const [deleteOneQuizDialogOpen, setDeleteOneQuizDialogOpen] = useState<boolean>(false)
  const [deleteQuizDialogOpen, setDeleteQuizDialogOpen] = useState<boolean>(false)

  const [quiz_index, setQuizIndex] = useState<number>(-1)

  const [files, setFiles] = useState<any>([])
  const [avatar, setAvatar] = useState<string | undefined>('')

  const [image_position, setImagePosition] = useState<number>(-2)

  useEffect(() => {
    try {
      (async () => {
        setIsFirstLoading(true)

        const result: any = await read_all_quiz()
        if (result.status === 'success') {
          let products = result.result
          products = products.map((product: any, index: number) => {
            if (product.result_prompt === '' || product.result_prompt === undefined || product.result_prompt === null) {
              product.result_prompt =
                '[question_and_choices] \n Based on the above pairs of questions and answers, tell me why [model_name] is the best one for me in a bulleted format. \n Please base specific questions and responses of the quiz to explain why certain features of [model_name] are a good fit based on my responses. \n The [model_name] has the following features: \n [description]'
            } else if (product.result_prompt.trim() === '') {
              product.result_prompt =
                '[question_and_choices] \n Based on the above pairs of questions and answers, tell me why [model_name] is the best one for me in a bulleted format. \n Please base specific questions and responses of the quiz to explain why certain features of [model_name] are a good fit based on my responses. \n The [model_name] has the following features: \n [description]'
            } else {
            }

            product = Object.assign(product, { active: false })
            return product
          })
          setProducts(products)

          setIsFirstLoading(false)
        } else if (result.status === 'error') {
          setIsFirstLoading(false)

          history.push('/admin/signin')
        }
      })()
    } catch (e) {
      // console.log(e)
    }
  }, [])

  useEffect(() => {
    try {
      (async () => {
        let active_product_index = products.findIndex((ele: any) => ele.active === true)
        if (active_product_index !== -1) {
          // console.log(products[active_product_index])
          setSelectedProduct(products[active_product_index])
          setEditProductIndex(active_product_index)
          setModel(products[active_product_index].selected_model_index)
          setFiles([{ preview: products[active_product_index].models[products[active_product_index].selected_model_index].image_url }])
          setImageSaveBtnShow(false)
          setImageSaveBtnShow1(false)
        } else if (active_product_index === -1) {
          setSelectedProduct(null)
        }
      })()
    } catch (e) {
      // console.log(e)
    }
  }, [products])

  const add_quiz = () => {
    setAddDialogOpen(true)
  }

  const save_edited_image = (e: any) => {
    try {
      ; (async () => {
        e.stopPropagation()
        // console.log('save image ... ')
        setIsLoading(true)
        let formData: FormData = new FormData()
        formData.append('file', files[0])
        let img_upload_result = await uploadImage(formData)

        if (img_upload_result.status === 'success') {
          // console.log(img_upload_result.type)
          // let image_url = img_upload_result.image_url
          let image_url = img_upload_result.data.Location

          let update_result: any = await update_quiz_image_url_by_id(
            products[editProductindex]._id,
            products[editProductindex].selected_model_index,
            image_url
          )

          if (update_result.status === 'success') {
            setProducts((prev: any[]) => {
              let next = [...prev]

              next[editProductindex].models[next[editProductindex].selected_model_index].image_url = image_url

              return next
            })

            setIsLoading(false)
          } else if (update_result.status === 'error') {
            setIsLoading(false)
          }
        } else if (img_upload_result.status === 'error') {
          // console.log('upload image error ... ')
          setIsLoading(false)
        }
      })()
    } catch (e) {
      // console.log(e)
      setIsLoading(false)
      return
    }
  }

  const edit_quiz_product_url = (e: any) => {
    e.stopPropagation()
    setEditProductUrlDialogOpen(true)
  }

  const edit_quiz_product_name = (e: any) => {
    e.stopPropagation()
    setEditProductNameDialogOpen(true)
  }

  const edit_quiz_best_url = (e: any) => {
    e.stopPropagation()
    setEditBestUrlDialogOpen(true)
  }

  const edit_quiz_model_name = (e: any) => {
    e.stopPropagation()
    setEditModelNameDialogOpen(true)
  }

  const edit_quiz_iframe_url = (e: any) => {
    e.stopPropagation()
    setEditIframeUrlDialogOpen(true)
  }

  const edit_quiz_description = (e: any) => {
    e.stopPropagation()
    setEditDescriptionDialogOpen(true)
  }

  const edit_result_prompt = (e: any) => {
    e.stopPropagation()
    setEditResultPromptDialogOpen(true)
  }

  const regenerate_question = (e: any) => {
    e.stopPropagation()
    setRegenQuestionDialogOpen(true)
  }

  const add_one_quiz = (e: any) => {
    e.stopPropagation()
    setAddOneQuizDialogOpen(true)
  }

  const edit_one_quiz = (e: any, quiz_index: number) => {
    e.stopPropagation()
    setQuizIndex(quiz_index)
    setEditOneQuizDialogOpen(true)
  }

  const delete_one_quiz = (e: any, quiz_index: number) => {
    e.stopPropagation()
    setQuizIndex(quiz_index)
    setDeleteOneQuizDialogOpen(true)
  }

  const clicked_product = (active: boolean, index: number, product_name: string, product_id: string) => {
    if (active === true) {
    } else if (active === false) {
      setProducts((prev: any[]) => {
        let next = [...prev]

        let prev_active_index = next.findIndex((ele) => ele.active === true)

        if (prev_active_index !== -1) {
          next[prev_active_index].active = false
        }

        next[index].active = true

        return next
      })

      // setSelectedProduct(products[index])
    }
  }

  const clicked_view_quiz_on_live = (e: any, quiz_url: string) => {
    e.stopPropagation()
    window.open(`/quiz/${quiz_url}`, '_blank')
  }

  const clicked_delete_quiz = (e: any, index: number, quiz_id: string) => {
    e.stopPropagation()
    setDeleteProductIndex(index)
    setDeleteQuizDialogOpen(true)
  }

  const { getRootProps: getRootFileProps, getInputProps: getInputFileProps } = useDropzone({
    accept: {
      'image/*': [],
    },
    onDrop: (acceptedFiles) => {
      console.log(image_position)
      setFiles(
        acceptedFiles.map((file) => {
          return Object.assign(file, {
            preview: URL.createObjectURL(file),
          })
        })
      )

      setImageSaveBtnShow(true)
      // console.log('save button is active ... ')
    },
    multiple: false,
  })

  const thumbs = files.map((file: any, index: number) => (
    <div className="imageuploadinner" key={index}>
      {file.preview === '' || file.preview === undefined ? (
        <></>
      ) : (
        <img
          src={file.preview}
          // Revoke data uri after image is loaded
          onLoad={() => {
            URL.revokeObjectURL(file.preview)
          }}
        />
      )}
    </div>
  ))

  const [model, setModel] = useState<number>(0)

  const handleChange = (event: any) => {
    try {
      (async () => {
        if ((event.target.value as number) === 99999) {
          // console.log('add new model ... ')
          event.stopPropagation()
          setAddNewModelDialogOpen(true)
        } else if ((event.target.value as number) === 999999) {
          // console.log('add multiple new models ... ')
          event.stopPropagation()
          setAddMultipleNewModelsDialogOpen(true)
        } else {
          setModel(event.target.value as number)

          setIsLoading(true)
          let update_result = await update_quiz_selected_model_index_by_id(products[editProductindex]._id, event.target.value)

          if (update_result.status === 'success') {
            setProducts((prev: any[]) => {
              let next = [...prev]
              next[editProductindex].selected_model_index = event.target.value

              return next
            })
            setIsLoading(false)
            // props.setOpen(false);
          } else if (update_result.status === 'error') {
            setIsLoading(false)
            // props.setOpen(false);
            history.push('/admin/signin')
          }
        }
      })()
    } catch (e) {
      // console.log(e)
    }
  }

  return (
    <>
      <CustomGrid container spacing={0}>
        {isFirstLoading === true ? (
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isFirstLoading}
            // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
              <p>&nbsp;&nbsp; Loading Data ...</p>
            </Backdrop>
          </>
        ) : (
          <></>
        )}
        {isLoading === true ? (
          <>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
            // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
              <p>&nbsp;&nbsp; Saving Data ...</p>
            </Backdrop>
          </>
        ) : (
          <></>
        )}
        <CustomGrid item xs={12} className={'grid_header'}>
          <MobileHeader
            products={products}
            clicked_product={clicked_product}
            clicked_view_quiz_on_live={clicked_view_quiz_on_live}
            clicked_delete_quiz={clicked_delete_quiz}
            add_quiz={add_quiz}
          />
        </CustomGrid>
        <CustomGrid item xs={12} md={3} className={'grid_list'}>
          <CustomDiv className={'add_category_div'}>
            <CustomListItemButton className={'min-width-0 add_category_icon'} onClick={(e) => add_quiz()}>
              <PlaylistAddIcon sx={{ width: '32px', height: '32px' }} />
            </CustomListItemButton>
          </CustomDiv>
          <CustomDiv className={'list_div'}>
            {products.length > 0 &&
              products.map((product: any, index: number) => (
                <CustomDiv key={index} className={'btn_div'}>
                  <CustomDiv
                    className={product.active === true ? 'active_list_item btn_style' : 'btn_style'}
                    onClick={() => clicked_product(product.active, index, product.product_name, product._id)}
                  >
                    <CustomDiv className={'txt_div'}>{product.product_name}</CustomDiv>
                    <CustomDiv className={'min-width-0 edit_icon'} onClick={(e: any) => clicked_view_quiz_on_live(e, product.quiz_url)}>
                      <VisibilityIcon fontSize="small" />
                    </CustomDiv>
                    {/* <CustomDiv className={"min-width-0 move_icon"} onClick={(e:any)=> clicked_move_category(e, index, product.product_name, product._id)}>
                                            <DriveFileMoveOutlinedIcon fontSize="small"/>
                                        </CustomDiv> */}
                    <CustomDiv className={'min-width-0 delete_icon'} onClick={(e: any) => clicked_delete_quiz(e, index, product._id)}>
                      <DeleteOutlineIcon fontSize="small" />
                    </CustomDiv>
                  </CustomDiv>
                </CustomDiv>
              ))}
          </CustomDiv>
        </CustomGrid>
        <CustomGrid item xs={12} md={9}>
          <CustomDiv className={'add_item_div'}></CustomDiv>
          <CustomDiv className={'content_div'}>
            {selected_product !== null ? (
              <>
                <div className={'quiz_content_item_div'}>
                  <CustomGrid container spacing={0}>
                    <CustomGrid item xs={10} className={'mui_grid_flex_name'}>
                      <div className={'quiz_content_item_title'}>Product Name: </div>
                      <div style={{ padding: '0px 15px' }}>{selected_product.product_name}</div>
                    </CustomGrid>
                    <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                      <CustomModeEditOutlineIcon fontSize="small" className={'edit_icon'} onClick={(e) => edit_quiz_product_name(e)} />
                    </CustomGrid>
                  </CustomGrid>
                </div>
                <div className={'sameline_title_name quiz_content_item_div'}>
                  <CustomGrid container spacing={0}>
                    <CustomGrid item xs={10} className={'mui_grid_flex'}>
                      <div className={'quiz_content_item_title'}>Add first question screener: </div>
                      <div className={'quiz_content_item_text'}> {selected_product.best_url?.best_url} </div>
                    </CustomGrid>
                    <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                      <CustomModeEditOutlineIcon fontSize="small" className={'edit_icon'} onClick={(e) => edit_quiz_best_url(e)} />
                    </CustomGrid>
                  </CustomGrid>
                </div>
                <p></p>
                <div className={'quiz_content_item_div'}>
                  <CustomGrid container spacing={0}>
                    <CustomGrid item xs={12} className={'mui_grid_flex_name'}>
                      <FormControl fullWidth>
                        <Select
                          labelId="demo-simple-select-label"
                          id="demo-simple-select"
                          value={model}
                          // value={selected_product.selected_model_index}
                          // label="Age"
                          onChange={handleChange}
                        >
                          {selected_product.models.map((model: any, index: number) => {
                            return (
                              <MenuItem key={index} value={index}>
                                {'model' + (index + 1)}
                              </MenuItem>
                            )
                          })}
                          <MenuItem value={99999}>{'Add New Model ... '}</MenuItem>
                          <MenuItem value={999999}>{'Add Multiple New Models ... '}</MenuItem>
                        </Select>
                      </FormControl>
                    </CustomGrid>
                  </CustomGrid>
                  <p></p>
                  <CustomDiv1 className={'img_text_container'}>
                    <CustomGrid container spacing={0}>
                      <CustomGrid item xs={12} md={4}>
                        <CustomDiv1 className={'img_div'}>
                          <Page>
                            <section className="imginner">
                              <aside className="imageupload">
                                {files.length > 0 ? (
                                  thumbs
                                ) : avatar === '' || avatar === undefined ? (
                                  <div className="imageuploadinner">
                                    {/* <img src={require('assets/cover.png').default} alt="Blank" /> */}
                                  </div>
                                ) : (
                                  <div className="imageuploadinner">
                                    <img src={avatar} alt="Avatar" />
                                  </div>
                                )}
                                <div {...getRootFileProps({ className: 'dropzone' })}>
                                  <input {...getInputFileProps()} />
                                  <IconButton aria-label="more" id="long-button" aria-haspopup="true" className="icon_btn_right">
                                    <InsertPhotoIcon />
                                  </IconButton>
                                </div>
                              </aside>
                              <div className="dropzoneOuter">
                                {imageSaveBtn_show === true ? (
                                  <CustomListItemButton
                                    aria-label="more"
                                    id="long-button"
                                    aria-haspopup="true"
                                    className="image_save_btn"
                                    onClick={(e) => save_edited_image(e)}
                                  >
                                    save
                                  </CustomListItemButton>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </section>
                          </Page>
                        </CustomDiv1>
                      </CustomGrid>
                      <CustomGrid item xs={12} md={8} className={'grid_url_model_name'}>
                        <CustomDiv1 className={'text_div'}>
                          <div className={'sameline_title_name quiz_content_item_div'}>
                            <CustomGrid container spacing={0}>
                              <CustomGrid item xs={10} className={'mui_grid_flex'}>
                                <div className={'quiz_content_item_title'}>Product Url: </div>
                                <div className={'quiz_content_item_text'}>
                                  {' '}
                                  {selected_product.models[selected_product.selected_model_index].product_url}{' '}
                                </div>
                              </CustomGrid>
                              <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                                <CustomModeEditOutlineIcon
                                  fontSize="small"
                                  className={'edit_icon'}
                                  onClick={(e) => edit_quiz_product_url(e)}
                                />
                              </CustomGrid>
                            </CustomGrid>
                          </div>

                          <div className={'sameline_title_name quiz_content_item_div'}>
                            <CustomGrid container spacing={0}>
                              <CustomGrid item xs={10} className={'mui_grid_flex'}>
                                <div className={'quiz_content_item_title'}>Model Name: </div>
                                <div className={'quiz_content_item_text'}>
                                  {' '}
                                  {selected_product.models[selected_product.selected_model_index].model_name}{' '}
                                </div>
                              </CustomGrid>
                              <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                                <CustomModeEditOutlineIcon
                                  fontSize="small"
                                  className={'edit_icon'}
                                  onClick={(e) => edit_quiz_model_name(e)}
                                />
                              </CustomGrid>
                            </CustomGrid>
                          </div>

                          <div className={'sameline_title_name quiz_content_item_div'}>
                            <CustomGrid container spacing={0}>
                              <CustomGrid item xs={10} className={'mui_grid_flex'}>
                                <div className={'quiz_content_item_title'}>Iframe Url: </div>
                                <div className={'quiz_content_item_text'}>
                                  {' '}
                                  {selected_product.models[selected_product.selected_model_index].iframe_url}{' '}
                                </div>
                              </CustomGrid>
                              <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                                <CustomModeEditOutlineIcon
                                  fontSize="small"
                                  className={'edit_icon'}
                                  onClick={(e) => edit_quiz_iframe_url(e)}
                                />
                              </CustomGrid>
                            </CustomGrid>
                          </div>
                        </CustomDiv1>
                      </CustomGrid>
                    </CustomGrid>
                  </CustomDiv1>
                  <p></p>
                  <div className={'quiz_content_item_div'}>
                    <CustomGrid container spacing={0}>
                      <CustomGrid item xs={10} className={'mui_grid_flex'}>
                        <div className={'quiz_content_item_title'}>Description: </div>
                      </CustomGrid>
                      <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                        <CustomModeEditOutlineIcon fontSize="small" className={'edit_icon'} onClick={(e) => edit_quiz_description(e)} />
                      </CustomGrid>
                    </CustomGrid>
                    <div style={{ padding: '15px 15px 0px' }}>
                      {selected_product.models[selected_product.selected_model_index].description}
                    </div>
                  </div>
                </div>

                <p></p>
                <div className={'quiz_content_item_div'}>
                  <CustomGrid container spacing={0}>
                    <CustomGrid item xs={10} className={'mui_grid_flex'}>
                      <div className={'quiz_content_item_title'}>Quiz: </div>
                    </CustomGrid>

                    <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                      <CustomPublishedWithChangesIcon fontSize="small" className={'edit_icon'} onClick={(e) => regenerate_question(e)} />
                      <CustomAddIcon fontSize="small" className={'edit_icon'} onClick={(e) => add_one_quiz(e)} />
                    </CustomGrid>
                  </CustomGrid>
                  <p></p>
                  {selected_product.quiz &&
                    selected_product.quiz.length > 0 &&
                    selected_product.quiz.map((ele: any, index: number) => {
                      // console.log(index)
                      // console.log(ele.image_url)
                      return (
                        <div key={index} className={'quiz_child_div'}>
                          <CustomGrid container spacing={0}>
                            <CustomGrid item xs={10} className={'mui_grid_flex'}>
                              <div>
                                {index + 1 + '.'}&nbsp;{ele !== null && ele.question}
                              </div>
                            </CustomGrid>
                            <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                              <CustomModeEditOutlineIcon
                                fontSize="small"
                                className={'edit_icon'}
                                onClick={(e) => edit_one_quiz(e, index)}
                              />
                              <CustomDeleteOutlineIcon
                                fontSize="small"
                                className={'delete_icon'}
                                onClick={(e) => delete_one_quiz(e, index)}
                              />
                            </CustomGrid>
                          </CustomGrid>
                          <CustomGrid container spacing={0}>
                            <CustomGrid item xs={12} md={6} className={'choice_div'}>
                              {ele.choices &&
                                ele.choices.length > 0 &&
                                ele.choices.map((ele1: any, index1: number) => {
                                  // return <div key={index1}  style={{padding: '0 15px'}}>-&nbsp;{ele1}</div>
                                  return (
                                    <div key={index1} style={{ padding: '0 35px' }}>
                                      -&nbsp;{ele1 !== null && ele1.choice}
                                    </div>
                                  )
                                })}
                            </CustomGrid>
                            <CustomGrid item xs={12} md={6} className={'quiz_img_div'}>
                              <QuizDropzone
                                image_url={ele.image_url}
                                edit_quiz_index={index}
                                editProductindex={editProductindex}
                                products={products}
                                setProducts={setProducts}
                              />
                            </CustomGrid>
                          </CustomGrid>
                        </div>
                      )
                    })}
                </div>
                <p></p>
                <div className={'quiz_content_item_div'}>
                  <CustomGrid container spacing={0}>
                    <CustomGrid item xs={10} className={'mui_grid_flex'}>
                      <div className={'quiz_content_item_title'}>ChatGPT prompt for generating the result: </div>
                    </CustomGrid>
                    <CustomGrid item xs={2} className={'mui_grid_flex mui_grid_item_end'}>
                      <CustomModeEditOutlineIcon fontSize="small" className={'edit_icon'} onClick={(e) => edit_result_prompt(e)} />
                    </CustomGrid>
                  </CustomGrid>
                  <div style={{ padding: '15px 15px 0px' }}>{selected_product.result_prompt}</div>
                </div>
              </>
            ) : (
              <></>
            )}
          </CustomDiv>
        </CustomGrid>
      </CustomGrid>

      <AddQuizDialog open={addDialogOpen} setOpen={setAddDialogOpen} products={products} setProducts={setProducts} />
      <AddMultpleNewModelsDialog
        open={addMultipleNewModelsDialogOpen}
        setOpen={setAddMultipleNewModelsDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <AddNewModelDialog
        open={addNewModelDialogOpen}
        setOpen={setAddNewModelDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditProductUrlDialog
        open={editProductUrlDialogOpen}
        setOpen={setEditProductUrlDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditProductNameDialog
        open={editProductNameDialogOpen}
        setOpen={setEditProductNameDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditBestUrlDialog
        open={editBestUrlDialogOpen}
        setOpen={setEditBestUrlDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditModelNameDialog
        open={editModelNameDialogOpen}
        setOpen={setEditModelNameDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditIframeUrlDialog
        open={editIframeUrlDialogOpen}
        setOpen={setEditIframeUrlDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditDescriptionDialog
        open={editDescriptionDialogOpen}
        setOpen={setEditDescriptionDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditResultPromptDialog
        open={editResultPromptDialogOpen}
        setOpen={setEditResultPromptDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <RegenQuestionDialog
        open={regenQuestionDialogOpen}
        setOpen={setRegenQuestionDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <AddOneQuizDialog
        open={addOneQuizDialogOpen}
        setOpen={setAddOneQuizDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
      />
      <EditOneQuizDialog
        open={editOneQuizDialogOpen}
        setOpen={setEditOneQuizDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
        quiz_index={quiz_index}
        setQuizIndex={setQuizIndex}
      />
      <DeleteOneQuizDialog
        open={deleteOneQuizDialogOpen}
        setOpen={setDeleteOneQuizDialogOpen}
        edit_product_index={editProductindex}
        setEditProductIndex={setEditProductIndex}
        products={products}
        setProducts={setProducts}
        quiz_index={quiz_index}
        setQuizIndex={setQuizIndex}
      />
      <DeleteQuizDialog
        open={deleteQuizDialogOpen}
        setOpen={setDeleteQuizDialogOpen}
        delete_product_index={deleteProductindex}
        setDeleteProductIndex={setDeleteProductIndex}
        products={products}
        setProducts={setProducts}
      />
    </>
  )
}

const CustomDiv1 = styled.div`
  &.img_text_container {
    display: flex !important;
    margin-bottom: 20px;
    .img_div {
    }
    .text_div {
      width: 100%;
      //   margin-left: 20px;
      display: flex;
      flex-direction: column;
      justify-content: start;
    }
  }
  &.num_quiz_div {
    display: flex !important;
    flex-direction: row !important;
    align-items: center !important;
    input {
      margin-left: 10px;
      padding: 4px;
      font-size: 18px;
      line-height: 30px;
      border: 1px solid #c4c4c4 !important;
      border-radius: 5px !important;
      width: 50px !important;
    }
    input:hover {
      border-color: black !important;
    }
    input:focus-visible {
      border: 2px solid #1976d2 !important;
      outline: none !important;
    }
  }
`
const Page = styled.div`
  color: #fff;
  display: flex;
  justify-content: Center;
  align-items: Center;

  .imginner {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 24px !important;
  }
  .imginner1 {
    border: 1px solid rgba(0, 0, 0, 0.05) !important;
    border-radius: 24px !important;
  }
  .imageupload {
    display: flex;
    justify-content: Center;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      @media (min-width: ${screenSizes.mediaM}px) {
        width: 300px;
        height: 300px;
      }
      width: 250px;
      height: 250px;
      overflow: hidden;
      img {
        height: 100%;
        width: 100%;
        border-radius: 24px !important;
        object-fit: contain;
      }
      .icon_btn {
        color: rgb(120, 115, 115);
        position: absolute;
        top: 30%;
        left: 30%;
        &:hover {
          background-color: white !important;
          color: black;
        }
      }
    }
    .icon_btn_right {
      color: rgb(120, 115, 115);
      border: 1px solid transparent !important;
      position: absolute;
      top: 2%;
      right: 2%;
      transition: all 300ms ease-in-out;
      &:hover {
        background-color: #ffffff7a !important;
        color: #049cf7;
        border: 1px solid #e3ecf35e !important;
        box-shadow: 0 1px 15px rgb(178 217 247 / 52%), 0 1px 15px rgb(117 169 235 / 23%) !important;
      }
    }
  }

  .dropzoneOuter {
    color: rgba(0, 0, 0, 0.4);
    text-align: center;
    max-width: 200px;
    margin: auto;
  }
`

const CustomAddIcon = styled(AddIcon)`
  cursor: pointer;
  &.edit_icon: hover {
    color: #06d305 !important;
  }
`
const CustomPublishedWithChangesIcon = styled(PublishedWithChangesIcon)`
  cursor: pointer;
  &.edit_icon: hover {
    color: #00ceff !important;
  }
`
const CustomModeEditOutlineIcon = styled(ModeEditOutlineIcon)`
  cursor: pointer;
  &.edit_icon: hover {
    color: #06d305 !important;
  }
`

const CustomDeleteOutlineIcon = styled(DeleteOutlineIcon)`
  cursor: pointer;
  &.delete_icon: hover {
    color: #ff3100cf !important;
  }
`

const CustomGrid = styled(Grid)`
  &.mui_grid_flex_name {
    display: flex !important;
    flex-direction: row;
  }
  &.mui_grid_flex {
    display: flex !important;
    flex-direction: column;
  }
  &.mui_grid_item_end {
    flex-direction: row !important;
    justify-content: end;
  }

  &.grid_header {
    @media (min-width: ${screenSizes.mediaM}px) {
      display: none !important;
    }
  }

  &.grid_list {
    @media (max-width: ${screenSizes.mediaM}px) {
      display: none !important;
    }
  }

  &.grid_url_model_name {
    @media (min-width: ${screenSizes.mediaM}px) {
      padding: 0 0 0 10px !important;
    }
    padding: 10px 0 0 0 !important;
  }

  &.choice_div {
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &.quiz_img_div {
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const CustomListItemButton = styled(ListItemButton)`
  &.active_list_item {
    background-color: #1e947e;
    color: white;
  }
  &.edit_cell {
    display: flex;
    justify-content: center;
  }
  &.delete_cell {
    display: flex;
    justify-content: center;
  }
  &.edit_cell:hover {
    color: #009fff;
    background-color: #0db9f51f;
  }
  &.delete_cell:hover {
    color: #ff6000;
    background-color: #ff60002e;
  }

  &.add_category_icon {
    display: flex;
    justify-content: center;
    color: #1e947e;
  }

  &.add_item_icon {
    display: flex;
    justify-content: center;
    color: #1e947e;
  }
  &.add_category_icon: hover {
    color: #1e947e;
  }

  &.add_item_icon: hover {
    color: #1e947e;
  }

  &.arrow {
    display: flex;
    justify-content: center;
  }
  &.image_save_btn {
    width: fit-content !important;
    margin: auto !important;
    color: rgba(0, 0, 0, 0.4);
  }
  &.image_save_btn:hover {
    width: fit-content !important;
    margin: auto !important;
    color: rgba(0, 0, 0);
  }
`

const CustomDiv = styled.div`
  &.add_category_div {
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    height: 50px !important;
  }

  &.add_item_div {
    display: flex;
    justify-content: center;
    padding: 0px 0px 5px 0px;
    height: 50px !important;
  }

  &.txt_div {
    flex: 1 1 auto !important;
  }

  &.list_div {
    height: 80vh;
    overflow: auto;
    border-right: 1px solid darkgray !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
  }

  &.content_div {
    height: 80vh;
    overflow: auto;
    padding-left: 5px !important;
    padding-right: 5px !important;
    padding-top: 5px !important;
  }

  &.btn_style {
    display: flex;
    padding: 8px 16px;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }

  &.btn_div {
    margin: 2px 0px;
    transition: all 350ms ease-in-out;
  }
  &.btn_div:hover {
    background-color: #00000022 !important;
  }

  &.active_list_item {
    background-color: #1e947e;
    color: white;
    transition: all 300ms ease-in-out;
  }

  &.min-width-0 {
    min-width: 0px !important;
    margin: 0 1px !important;
  }

  &.move_icon: hover {
    color: #fded0a !important;
  }

  &.edit_icon: hover {
    color: #00ceff !important;
  }

  &.delete_icon: hover {
    color: #ff3100cf !important;
  }
`

export default TabPanelDiv
