import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { update_quiz_result_prompt_by_id } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function EditResultPromptDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ edited_result_prompt, setEditedResultPrompt ] = React.useState<string>("");
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
    if(props.products.length > 0 && props.edit_product_index !== -1) {
      setEditedResultPrompt(props.products[props.edit_product_index].result_prompt)
    }
  }, [props.open])

  const handleClose = () => {
    // props.setEditProductIndex(-1)
    setEditedResultPrompt("")
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        let temp_edited_result_prompt = edited_result_prompt
        if ( temp_edited_result_prompt === '' || temp_edited_result_prompt === undefined || temp_edited_result_prompt === null ) temp_edited_result_prompt = ' '
        setIsLoading(true)
        let update_result = await update_quiz_result_prompt_by_id(props.products[props.edit_product_index]._id, temp_edited_result_prompt.trim())

        if ( update_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = [...prev]
            next[props.edit_product_index].result_prompt = update_result.update_result.result_prompt

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( update_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setEditedResultPrompt(e.target.value)
  }

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    
  }

  return (
        <>
          <div>
            <Dialog open={open} onClose={handleClose} fullWidth maxWidth={'md'}>
              {
                isLoading === true ? 
                <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                    <p>&nbsp;&nbsp; Saving Data ...</p>
                </Backdrop>
                :<></>
              }
              <DialogTitle>Edit Result Prompt</DialogTitle>
              <DialogContent>
                  {/* <div>model name: <span style={{color: 'green'}}>[model_name]</span>, &nbsp;&nbsp;description: <span style={{color: 'green'}}>[description]</span>, &nbsp;&nbsp;question and choices: <span style={{color: 'green'}}>[question_and_choices]</span></div> */}
                  <div>models: <span style={{color: 'green'}}>[models]</span>, &nbsp;&nbsp;question and choices: <span style={{color: 'green'}}>[question_and_choices]</span></div>
                  <div></div>
                  <div></div>
                  <p></p>
                <DialogContentText>
                  Result Prompt:
                  
                </DialogContentText>
                <TextField
                  autoFocus
                  margin="dense"
                  fullWidth
                  multiline
                  rows={15}
                  maxRows={15}
                  value={edited_result_prompt}
                  onChange={handleDescriptionChange}
                  onKeyDown={handleKeyDown}
                />
              </DialogContent>
              <DialogActions>
                <Button onClick={handleSave}>Save</Button>
                <Button onClick={handleClose}>Cancel</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}