import { Fragment, useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import { MobileSideNav } from './side-nav'
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import history from 'modules/app/components/history'

const MobileHeader = (props: any) => {
  const location = useLocation();
  const [showSideNav, setShowSideNav] = useState(false)

  useEffect(() => {
    (async () => {
      
    })()
      
  }, [])

  const goStartPage = () => {
      history.push(`/`)
  } 

  useEffect(() => {
   
  }, [location])

  return (
    <>
    {
      <>
        <Fragment>
          <MobileHeaderCont>
            <FlexCont>
              <IconButton
                aria-label="more"
                id="long-button"
                aria-haspopup="true"
                onClick={() => setShowSideNav(true)}
                style={{ padding: 0, marginRight: '10px'}}
              >
                <MenuIcon style={{ fontSize: '28px', color: 'black'}} />
              </IconButton>
              <MobileHeaderTitleCont onClick={goStartPage}>
                <img src={require('assets/logo_avatar3.png').default} alt="" width="30" height="30" />
                &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan>
              </MobileHeaderTitleCont>
            </FlexCont>
          </MobileHeaderCont>
          <MobileSideNav 
            showSideNav={showSideNav} 
            setShowSideNav={setShowSideNav} 
            products={props.products} 
            clicked_product = {props.clicked_product}
            clicked_view_quiz_on_live = {props.clicked_view_quiz_on_live}
            clicked_delete_quiz = {props.clicked_delete_quiz}
            add_quiz={props.add_quiz}
          />
        </Fragment>
      </>
    }
    </>
  )
}

const FlexCont = styled.div`
  display: flex;
  align-items: center;
  &.borrow-limit{
    width: auto;
    text-align: center;
  }
  &.netApyWeb{
    display: flex;
    justify-content: center;
  }
  &.LendingStats{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`

const MobileHeaderCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 20px 20px 20px;
  background: white;
  border-bottom: 1px solid #FFFFFF26;
  width: 100%;
  height: 70px;
  top: 0;
  left: 0;
  box-sizing: border-box;
  z-index: 10;
  @supports (-moz-appearance: none) {
    background: #000000;
  }
  @media (min-width: 800px) {
    display: none;
  }
  border-bottom: 1px solid gray;
`
const MobileHeaderTitleCont = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  img {
    margin-left: 7px;
  }
  p {
    font-size: 20px;
    line-height: 24px;
    color: #FFFFFF;
    font-family: 'Montserrat', sans-serif;
    margin: 0;
  }
`
const BoldSpan = styled.span`
  font-weight: 700;
`
const CustomSpan = styled.span`
  font-size: 25px;
  @media (min-width: 800px) {
    font-size: 25px;
  }
`

export default withRouter(MobileHeader)
