import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, Box   } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import { recordUserAndIds } from 'modules/api/market-api'

interface Props {
    item: any,
}

const ProductBox = (props: Props) => {
    const { item } = props;
    const selector = useSelector((state: any) => state)

    const recordIds = () => {
        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.DetailPageURL, search, _id, undefined)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
    }


    return (
        <CustomA href={item.DetailPageURL} target="_blank" onClick={recordIds}>
            <CustomBox className="parent_box">
                <CustomBox className="left_box"><img src={item.Images.Primary.Large.URL ? item.Images.Primary.Large.URL : require('assets/1.jpg').default} alt="" /></CustomBox>
                <CustomBox className="center_box">
                    <Box className="main_box">
                        <Box className="part_box1">
                            <Box className="part_box1_right">{item.ItemInfo.ByLineInfo != null && item.ItemInfo.ByLineInfo.Brand != null ? item.ItemInfo.ByLineInfo.Brand.DisplayValue : ''}</Box>
                        </Box>
                        <CustomA href={item.DetailPageURL} target="_blank" onClick={recordIds}><Box className="part_box2">{item.ItemInfo.Title.DisplayValue}</Box></CustomA>
                    </Box>
                </CustomBox>
                <CustomBox className="right_box">
                    <Box>
                        <CustomA href={item.DetailPageURL} target="_blank" onClick={recordIds}><CustomButton sx={{ textTransform: "none" }}>Check Price</CustomButton></CustomA>
                        <img src={require('assets/buttonUnderImage.webp').default} alt="" />
                    </Box>
                </CustomBox>
            </CustomBox>
        </CustomA>
    )
}

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;
`
const CustomButton = styled(Button)`
`

const CustomBox = styled(Box)`
        width: 100%;
        height: 180px;
        display: flex;
        background: aliceblue;
        margin: 10px 0;
        border-radius: 10px;
        position: relative;
        .left_box {
            background: white;
            margin: 0;
            width: 180px;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding-left: 30px;
            img { 
                // @media (min-width: ${screenSizes.mediaM}px) {
                //     width: 140px;
                //     height: 140px;
                // }
                // @media (min-width: ${screenSizes.mediaL}px) {
                //     width: 164px;
                //     height: 164px;
                // }
                width: 175px;
                height: 175px;
                
            }
        }
        .center_box {
            background: white;
            margin: 0;
            width: auto;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding-left: 30px;
            padding-right: 30px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .part_box1{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 10px;
                    .part_box1_left {
                        // width: 32px;
                        height: 32px;
                        margin-right: 10px;
                        background: #0c90f1;
                        color: white;
                        border-radius: 4px;
                        display: flex;
                        -webkit-box-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        align-items: center;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        padding: 0 5px;
                    }
                    .part_box1_right {
                        font-family: sans-serif;
                        font-weight: 700;
                        line-height: 1.33;
                        font-size: 24px;
                        letter-spacing: 0.01em;
                        color: #0f1a16;
                    }
                }
                .part_box2{
                    margin: 0px;
                    color: #1275bb;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: Inter, Arial, sans-serif;
                    font-weight: 600;
                    cursor: pointer;
                    &:hover {
                        background-color: #edecec;
                    }
                }
            }
        }
        .right_box {
            background: white;
            margin: 0;
            width: 200px;
            display: flex!important;
            align-items: center!important;
            justify-content: end!important;
            padding-right: 45px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .MuiBox-root {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        ${CustomButton} {
            width: 133px;
            padding: 15px;
            cursor: pointer;
            font-family: sans-serif;
            font-weight: 600;
            line-height: 1.33;
            font-size: 15px!important;
            letter-spacing: 0.03em!important;
            color: white!important;
            background-color: #37a4f1!important;
            border: 1px solid #107ebf!important;
            
            &:hover {
                background-color: #107ebf!important;
            }  
        }
`

export default ProductBox