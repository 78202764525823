import { useState, useEffect } from 'react'
import styled from 'styled-components'
import jwt_decode from 'jwt-decode'
import { Grid, Divider, Box, Tab,  } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import TabPanelDiv from './sub_components/TabPanelDiv'
import { get_menu_data } from 'modules/api/market-api'
import AdminHeader from 'shared/AdminHeader'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const Admin = (props: any) => {
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    const [ isFirstLoading, setIsFirstLoading ] = useState<boolean>(false)

    const [ menu_data, setMenuData ] = useState<any>([])

    const [value, setValue] = useState<string>('0');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    useEffect(() => {
      (async () => {
        // setIsLoading(true)
        setIsFirstLoading(true)

        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {
              let menu_data_result = await get_menu_data()
              setMenuData(menu_data_result.menu_data)
              
              // setIsLoading(false)

            } else {
                // setIsLoading(false)
                setIsFirstLoading(false)

                history.push(`/admin/signin`)
            }
        } else {
          // setIsLoading(false)
          setIsFirstLoading(false)
          
          history.push(`/admin/signin`)
        }
      })()
        
    }, [])

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <>
          {
              isFirstLoading === true ?
              <>
                  <Backdrop
                      sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                      open={isFirstLoading}
                      // onClick={handleClose}
                  >
                      <CircularProgress color="inherit"/>
                      <p>&nbsp;&nbsp; Loading Data ...</p>
                  </Backdrop>
              </>
              :<></>
          }
          <AdminHeader />
          {/* <HeaderContainer>
            <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
              <CustomGrid item xs={4} className="grid-header-left header_logo" onClick={goStartPage} >
                <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
                &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
              </CustomGrid>
              <CustomGrid item xs={8} className="grid-flex-item-end grid-header-right" >
                
              </CustomGrid>
            </CustomGrid>
            <Divider />
          </HeaderContainer> */}
          <CustomGrid container spacing={0} className={"pt-0"}>
            <CustomGrid item xs={12}  className={"pt-0"}>
              <Box sx={{ typography: 'body1' }}>
                <TabContext value={value}>
                  <CustomBox sx={{ borderBottom: 1, borderColor: 'divider' }} className={"tab_box"}>
                    <TabList onChange={handleTabChange} aria-label="lab API tabs example" >
                        {
                          menu_data.length > 0 && menu_data.map((item:any, index:number) => {
                            return <CustomTab key={index} label={item.title._id} value={index.toString()} className='lowcasetab' />
                          })
                        }
                    </TabList>
                  </CustomBox>
                  {
                    menu_data.length > 0 && menu_data.map((item:any, index:number) => {
                      let temp_arr = [...menu_data]
                      temp_arr.splice(index, 1)
                      return (
                        <TabPanel key={index} value={index.toString()} sx={{padding: "0"}}>
                          <CustomGrid container spacing={0} sx={{'paddingTop': '1px'}}>
                            <CustomGrid item xs={12} sx={{'paddingTop': '1px'}}>
                              <TabPanelDiv list_name={item.title._id} move_list_array={temp_arr} setIsFirstLoading={setIsFirstLoading} />
                            </CustomGrid>
                          </CustomGrid>
                        </TabPanel>
                      )
                    })
                  }
                </TabContext>
              </Box>
            </CustomGrid>
          </CustomGrid>
        </>
    );
};

const CustomBox = styled(Box)`
  &.tab_box {
    span.css-1aquho2-MuiTabs-indicator {
      background-color: #1e947e!important;
    }
    span.css-ttwr4n {
      background-color: #1e947e!important;
    }
  }
`

const CustomTab = styled(Tab)`
    &.lowcasetab {
      text-transform: none!important;
    } 
    &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #1e947e!important;
    }
    &.css-1q2h7u5.Mui-selected {
      color: #1e947e!important;
    }
`

const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 4px 10px;
  margin: 0px!important;

  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }

  .pt-0 {
    padding-top: 0px!important;
  }
`


const HeaderContainer = styled.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
const BoldSpan = styled.span`
  font-weight: 700;
`
export default Admin