import styled from 'styled-components'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { PlusIconWhite } from 'assets/tsicons'

const PrivacyPolicy = () => {
    return (
    <>
        <CustomGrid container spacing={0} >
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={2}>

            </CustomGrid>
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={8} className="center_grid">
                <CustomGrid item container spacing={0} xs={12} >
                    <Toptitle>Privacy Policy</Toptitle>
                </CustomGrid>
                <CustomGrid item container spacing={0} xs={12} className="text_body_grid">
                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc1" id="acc1">
                            <CustomTypography>1. Overview</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Topbestsellers.org LLC (“Topbestsellers,” “we,” “us,” and “our”) respects your privacy and is committed to protecting it through compliance with this Privacy Notice (“Privacy Notice”). This Privacy Notice describes the types of Information we may collect from you when you visit the website at www.Topbestsellers.org (the “Site”), and that you may provide in electronic messages to Topbestsellers. It also describes our practices for collecting, using, maintaining, protecting, and disclosing that Information.</p>

                            <p>Please read this Privacy Notice to understand our policies and practices regarding your Information and how we will handle it. If you do not agree with our policies and practices, do not use the Site. By accessing or using the Site, you agree to this Privacy Notice.</p>

                            <p>Topbestsellers may change this Privacy Notice at any time, at its discretion. Your continued use of the Site after we make changes is deemed to be acceptance of those changes, so please check the Privacy Notice periodically for updates.</p>

                            <p>This Privacy Notice is subject to and governed by the Topbestsellers Terms of Use (“Terms of Use”) available at the Site.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc2" id="acc2">
                            <CustomTypography>2. The Types of Information That Topbestsellers Collects About You and How Topbestsellers Collects Information About You</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Topbestsellers may collect two types of information from you when you visit the Site: Personal Information and Non-Personal Information (collectively “Information”).</p>

                            <p>We collect Information:</p>

                            <p>Information You Provide Directly to Topbestsellers. The Information that you provide us directly through our Site may include:</p>

                            <p>Information Automatically Collected from You. The Information that Topbestsellers may automatically collect and store about you when you visit the Site may include:</p>

                            <p>Google Analytics Advertising Features and Similar Services. We also may have the following Google Analytics Advertising Features Implemented: Demographics and Interest reporting, Remarketing, GDN Impression Reporting and Double Click Campaign Manager. These features collect data using Google advertising cookies and anonymous identifiers. Topbestsellers and third party vendors use these first-party cookies to access the success of online marketing campaigns, inform future campaigns and improve usability on the Site. To learn how to opt-out of the Google Analytics Advertising Features we use, please see Google Analytics’ currently available opt-outs for the web at https://tools.google.com/dlpage/gaoptout.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc3" id="acc3">
                            <CustomTypography>3. How Topbestsellers Uses Information It Collects About You and the Purpose for the Collection and Use</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We use Information that we collect about you or that you provide to us, including any Personal Information, for the following purposes:</p>

                            <p>Site statistics are compiled into traffic reports, which help Topbestsellers understand, anticipate, and respond to user needs. If we learn, for example, of heightened interest in certain aspects of the Topbestsellers website, we are likely to highlight that information on the Site home page. This Information helps us create a better overall experience for Site users.</p>



                            <p>Topbestsellers will retain your Information for as long as necessary to provide the services you have requested, comply with our legal obligations, resolve disputes, enforce our agreements, and achieve other business purposes.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc4" id="acc4">
                            <CustomTypography>4. How Topbestsellers Protects Your Information</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We have implemented measures designed to protect your Information from accidental loss and from unauthorized access, use, alteration, and disclosure. The internet is not completely secure. Although we are working to protect your Information, we cannot guarantee the security of your Information either in transmission or when stored. We are not responsible for circumvention of any privacy settings or security measures contained on the Site.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc5" id="acc5">
                            <CustomTypography>5. When Topbestsellers Shares Your Information</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We may use and disclose Non-Personal Information about our users without restriction. We may disclose Personal Information that we collect or you provide as described in this Privacy Notice:</p>
                            <p>Topbestsellers does not sell any of your Personal Information.</p>
                            <p>Topbestsellers is one of several subsidiaries and affiliates ("Affiliates") that are part of a corporate group. Topbestsellers may share your Information with its Affiliates, which may use your Information as described in this Privacy Notice and the privacy notices available on their websites. This may include using your Information to send you marketing communications regardgint those other Affiliates, the products and services they provide, and their websites.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc6" id="acc6">
                            <CustomTypography>6. Third Parties Providing Services On Our Behalf</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We use third parties to host the Site, design and operate the Site features, and to perform administrative and analytics processing. Services these third parties provide include providing information to Topbestsellers about the use of the Site and viewing of our content. We may disclose your Information to these companies so they can carry out these services. These companies are bound by contractual obligations to keep Information confidential.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc7" id="acc7">
                            <CustomTypography>7. Information from Children</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>This Site is not intended for children under 13 in the United States per the Children's Online Privacy Protection Act or under 16 years of age elsewhere. We do not knowingly collect, use, or disclose Inofrmation from children under those ages. If we learn that we have collected the Personal Information of a child under those ages, we will take steps to delete the Information as soon as possible. If you are under those ages, do not provide any Information about yourself to Topbestsellers, including your name, address, telephone number or email address. If you become aware that Information of a child below these ages has been provided, please contact us at contact@Topbestsellers.org.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc8" id="acc8 ">
                            <CustomTypography>8. Links to Other Websites and Services</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>We are not responsible for the practices employed by websites or services linked to or from the Site, including the information or content contained therein. Our Privacy Notice does not apply to third-party websites or services.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc9" id="acc9 ">
                            <CustomTypography>9. Do Not Track</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Some browsers incorporate a “Do Not Track” (“DNT”) feature that, when turned on, signals to websites and online services that you do not want to be tracked. At this time, the Site does not respond to DNT signals.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc10" id="acc10 ">
                            <CustomTypography>10. Residents of the European Economic Area (“EEA”) and United Kingdom (“UK”)</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>If you are located in the EEA or UK, you may have certain rights under European and UK law with respect to your Personal Information that are described below.</p>
                            <p>Controller of Personal Information. Topbestsellers is the Controller of your Personal Information. Topbestsellers’s primary place of business is topbestsellers.org LLC, 929 108th Ave NE, Suite 1410, Bellevue, WA 98004.</p>
                            <p>Processing. Topbestsellers may process your Personal Information on one or more of the following legal bases:</p>
                            <p>Your Rights. Subject to applicable law, you may have the right to:</p>
                            <p>You can exercise your rights of access, rectification, erasure, restriction, objection, and data portability by contacting us at the email address in Section 13 below.</p>
                            <p>When you consent to our processing your Personal Information for a specified purpose, you may withdraw your consent at any time, and we will stop any further processing of your data for that purpose.</p>
                            <p>The categories of Personal Information that Topbestsellers collects are identified in Section 2, “The Types of Information That Topbestsellers Collects about You and How Topbestsellers Collects Information About You.” Additional information about how long Topbestsellers will retain your Information and how it shares your Information is found in Section 3, “How Topbestsellers Uses Information It Collects About You and the Purpose for the Collection and Use,” and Section 5, “When Topbestsellers Shares Your Information” above</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc11" id="acc11 ">
                            <CustomTypography>11. Your Choices About Information</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>If you choose not to provide Information, you may be unable to access all or portions of the Site. If you no longer wish to receive marketing or promotional communications from us, click on the “unsubscribe link” provided in such communications or send us an email to contact@Topbestsellers.org</p>

                            <p>If you opt out of receiving marketing/promotional emails, we may still send you non-promotional emails, such as emails about your accounts or our ongoing business relations. Please report your concerns about collection or use of Personal Information through the Site to contact@Topbestsellers.org.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc12" id="acc12 ">
                            <CustomTypography>12. Your California Privacy Rights</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>California’s “Shine the Light” law (Civil Code Section § 1798.83) permits users of our Site that are California residents to request certain information regarding our disclosure of Personal Information to third parties for their direct marketing purposes. To make such a request, please contact us at the physical address or email address included below.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc13" id="acc13 ">
                            <CustomTypography>13. How To Contact Us About This Privacy Notice</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>To ask questions about this Privacy Notice and our privacy practices, contact us contact@topbestsellers.org or by mail at:</p>

                            <p>Topbestsellers.com LLC</p>

                            <p>929 108th Ave NE, Suite 1410</p>

                            <p>Bellevue, WA 98004</p>

                            <p>EFFECTIVE DATE: September 21, 2022</p>
                        </AccordionDetails>
                    </CustomAccordion>

                </CustomGrid>
            </CustomGrid>
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={2}>

            </CustomGrid>
        </CustomGrid>
    </>                                                               
    );
};

const CustomAccordion = styled(Accordion)`
    width: 100%!important;
`
const CustomTypography = styled(Typography)`
    font-size: 16px!important;
    font-weight: 600!important;
`
const Toptitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
`
const CustomGrid = styled(Grid)`
    // min-height: 100vh;
    margin-top: 0px!important;
    .center_grid {
        // background-color: gray;
        // height:600px;
        padding: 15px 15px;
    }
    .text_body_grid {
        // padding-left: 15px;
        font-family: sans-serif!important;
        line-height: 1.5;
        // text-indent: 1.5em; 
        // text-align: justify;
    }
    // .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
    //     width: 100%!important;
    // }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: rotate(225deg) !important;
      }
`

export default PrivacyPolicy