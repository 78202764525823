import styled from 'styled-components'
import { Box } from '@mui/material'

const TrustBoxMobile = (props: any) => {
    return (
            <CustomDiv className="parent_box">
                <CustomDiv className="center_box">
                    <div className="why_title_div">
                        <span className="why_title">
                            Why trust us?
                        </span>
                    </div>
                    <div className="why_text_div">
                        <span className="why_text">
                        Our Machine Learning models parse through thousands of data bases to cut through all the noise, and bring you only the products that deserve your attention. Spend your time enjoying life, not shopping!
                        </span>
                    </div>   
                    <img src={require('assets/trust.svg').default} alt="" width="230" height="85" /> 
                </CustomDiv>
            </CustomDiv>
    )
}


const CustomDiv = styled.div`
        width: 100%;
        height: auto;
        display: flex;
        margin: 10px 0px;
        border-radius: 10px;
        position: relative;
        &.parent_box {
            background-color: #187664;
        }
     
        .center_box {
            margin: 0;
            width: auto;
            flex-grow: 1;
            display: flex!important;
            flex-direction: column;
            align-items: start!important;
            justify-content: center!important;
            padding-left: 30px;
            padding-right: 30px;
            
            .why_title_div {
                padding: 15px 0px;
            }

            .why_title {
                font-family: GT Pressura,sans-serif;
                font-weight: 700;
                line-height: 1.25;
                font-size: 32px;
                letter-spacing: 0.01em;
                color: white;
            }

            .why_text_div {
                padding: 0 0 20px 0px;
            }

            .why_text {
                font-family: GT Pressura,sans-serif;
                font-size: 18px;
                margin: 0;
                color: #FDFDFD;
                display: block;
            }
            img {
                object-fit: contain;
            }
        }
`

export default TrustBoxMobile