import styled from 'styled-components'
import { Grid } from '@mui/material'
import Accordion from '@mui/material/Accordion'
import AccordionSummary from '@mui/material/AccordionSummary'
import AccordionDetails from '@mui/material/AccordionDetails'
import Typography from '@mui/material/Typography'
import { PlusIconWhite } from 'assets/tsicons'

const TermsOfUse = () => {
    return (
    <>
        <CustomGrid container spacing={0} >
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={2}>

            </CustomGrid>
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={8} className="center_grid">
                <CustomGrid item container spacing={0} xs={12} >
                    <Toptitle>Terms of Use</Toptitle>
                </CustomGrid>
                <CustomGrid item container spacing={0} xs={12} className="text_body_grid">
                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc1" id="acc1">
                            <CustomTypography>1. Overview</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>These terms of use (“Terms of Use”) are entered into between you and Topbestsellers.org LLC (“Topbestsellers,” “we,” or “us”). The Terms of Use govern your access to and use of the Topbestsellers website at www.Topbestsellers.org, including any content, functionality and services offered on or through it (the “Site”).</p>

                            <p>By using the Site, you consent to and agree to be bound by and comply with these Terms of Use. Topbestsellers may terminate your ability to use the Site without notice if you do not comply with the Terms of Use. If you do not agree to these Terms of Use, you must not access or use the Site.</p>

                            <p>Topbestsellers reserves the right to make changes to the Site and to the Terms of Use at any time. All changes are effective immediately when posted. Your continued use of the Site following the posting of the revised Terms of Use means that you accept and agree to the changes.</p>

                            <p>All Information Topbestsellers collects on this Site is subject to our Privacy Notice posted at the Site. By using the Site, you consent to all actions taken by us with respect to your Information (as defined in the Privacy Notice) in compliance with the Privacy Notice. The Privacy Notice is incorporated into and governed by these Terms of Use. To the extent there is a conflict, the Terms of Use supersede the Privacy Notice.</p>

                            <p>All Site Content (as defined below) is current as of the date it is posted on the Site to the best of Topbestsellers’s knowledge.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc2" id="acc2">
                            <CustomTypography>2. Intellectual Property</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>The Site and its entire contents, data, features, and functionality (including but not limited to text, graphics, video, logos, button icons, databases, and images) (“Site Content”) are the property of Topbestsellers or its licensors and are protected by copyright, trademark and other intellectual property laws, except as indicated below.</p>

                            <p>The Topbestsellers name and related logos are trademarks and service marks (“Marks”) of Topbestsellers. Topbestsellers Marks may not be used without advance written permission of Topbestsellers, including in connection with any product or service that is not provided by Topbestsellers, in any manner that is likely to cause confusion, or in any manner that disparages, discredits, or misrepresents Topbestsellers. Other products or company names mentioned on the Site may be trademarks or service marks of their respective owners.</p>

                            <p>If you believe that any content on the Site violates your intellectual property rights, please notify Topbestsellers as described in Section 15.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc3" id="acc3">
                            <CustomTypography>3. Limited License And Prohibited Uses</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Topbestsellers grants you a personal, royalty-free, non-assignable, revocable, and non-exclusive license to access and use the Site Content as an informative resource while using the Site. Any other use, including the reproduction, modification, distribution, transmission, republication, framing, display or performance of Site Content without prior permission of Topbestsellers is strictly prohibited. You may not download, print, copy, distribute, or otherwise use Site Content for commercial purposes, including publication, sale, or personal gain. You may not remove any Marks or other proprietary notices, including, without limitation, attribution information, credits, and copyright notices that have been placed on or near the Site Content.</p>

                            <p>You may use the Site only for lawful purposes and in accordance with these Terms of Use. You agree that you will not:</p>



                            <p>This Site is not intended for children under 16 years of age—or the equivalent minimum age depending on the jurisdiction, such as 13 in the United States per the Children’s Online Privacy Protection Act. For more information, please see our Privacy Notice.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc4" id="acc4">
                            <CustomTypography>4. Third Party Content, Products and Services, and Product Rankings</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>As an Amazon Assocciate, we earn from qualifying purchases. When you click on links on the Site and then make a purchase, this can result in us earning a commission. Some of the content on the Site comes from third parties. This content is provided 'as is' and is subject to change or removal at any time.</p>

                            <p>Topbestsellers generates product rankings at its discretion using proprietary methods. Some of the products ranked on the Site are products offered by our affiliates and subsidiaries. We also may receive commissions from products that are ranked higher than other products on our Site.</p>
                            <p>This Site may include content provided by third parties. All statements and opinions expressed by third parties are solely the opinions and the responsibility of the person or entity providing those materials. Those materials do not necessarily reflect the opinion of Topbestsellers. Topbestsellers is not responsible for the content or accuracy of any materials provided by any third parties.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc5" id="acc5">
                            <CustomTypography>5. Links To Other Web Sites</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Topbestsellers provides links to external web sites for the convenience of Site users. The inclusion of an external link on this Site does not constitute or imply support or endorsement of any kind. Topbestsellers does not control those web sites, is not responsible for their content or function, and is not responsible for any loss or damage that may arise from your use of them. If you decide to access the third party sites linked to this Site, you do so entirely at your own risk and subject to the terms and conditions of use and the privacy notice for such Sites.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc6" id="acc6">
                            <CustomTypography>6. Disclaimer of Warranties</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>THE INFORMATION ON THE SITE IS MADE AVAILABLE SOLELY FOR INFORMATIONAL PURPOSES. YOU EXPRESSLY AGREE THAT YOUR USE OF THE SITE AND CONDUCTING TRANSACTIONS RELATED TO IT ARE AT YOUR SOLE RISK. WHILE Topbestsellers ATTEMPTS TO PRESENT ACCURATE INFORMATION ON THE SITE, THIS SITE IS PROVIDED ON AN “AS-IS” BASIS. Topbestsellers MAKES NO REPRESENTATIONS OR WARRANTIES OF ANY KIND, EXPRESS OR IMPLIED, AS TO THE OPERATION OR CONTENT OF THIS SITE OR ANY OTHER SITE TO WHICH IT IS LINKED. TO THE EXTENT PERMITTED BY LAW, Topbestsellers DISCLAIMS ALL WARRANTIES, EXPRESS OR IMPLIED, INCLUDING WITHOUT LIMITATION ANY IMPLIED WARRANTIES OF MERCHANTABILITY OR FITNESS FOR A PARTICULAR PURPOSE, OR ANY WARRANTIES OF NON-INFRINGEMENT OF ANY PATENT OR OTHER INTELLECTUAL PROPERTY RIGHTS OF A THIRD PARTY.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc7" id="acc7">
                            <CustomTypography>7. Limitation of Liability</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>Topbestsellers, ITS DIRECTORS, ITS CONTRACTORS, AND ITS EMPLOYEES WILL NOT BE LIABLE FOR ANY DAMAGES OF ANY KIND ARISING FROM THE USE OF THIS SITE OR ANY SITE FOR WHICH IT PROVIDES LINKS, INCLUDING BUT NOT LIMITED TO DIRECT, INDIRECT, PUNITIVE AND CONSEQUENTIAL DAMAGES. YOUR SOLE REMEDY UNDER THESE TERMS OF USE IS TO STOP USING THE SITE. Topbestsellers WILL NOT BE LIABLE FOR ANY REASON IF ALL OR ANY PART OF THE SITE IS UNAVAILABLE AT ANY TIME OR FOR ANY PERIOD.</p>

                            <p>Topbestsellers’S MAXIMUM LIABILITY, WHETHER BY STATUTE, IN CONTRACT, WARRANTY, TORT, NEGLIGENCE, PRODUCT LIABILITY, STRICT LIABILITY, OR OTHER THEORY, ARISING OUT OF OR IN CONNECTION WITH YOUR USE OF THE SITE OR ITS CONTENT, WILL NOT EXCEED $100.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc8" id="acc8 ">
                            <CustomTypography>8. Indemnification</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>You agree to defend, indemnify and hold harmless Topbestsellers, its affiliates, licensors and service providers, and its and their respective officers, directors, employees, contractors, agents, licensors, suppliers, successors and assigns from and against any liabilities, damages, judgments, awards, losses, costs, third party claims, expenses and fees (including reasonable attorneys’ fees) arising out of or relating to your violation of these Terms of Use or your use of the Site, including, but not limited to, your content, any use of the Site Content, services, and products other than as expressly authorized in these Terms of Use.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc9" id="acc9 ">
                            <CustomTypography>9. Limitation On Time To File Claims</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>ANY CAUSE OF ACTION OR CLAIM YOU MAY HAVE ARISING OUT OF OR RELATING TO THESE TERMS OF USE OR THE SITE MUST BE COMMENCED WITHIN THREE (3) MONTHS AFTER THE EVENT GIVING RISE TO THE ACTION OR CLAIM OCCURRED, REGARDLESS OF WHEN YOU KNEW OR SHOULD HAVE KNOWN ABOUT IT; OTHERWISE, SUCH CAUSE OF ACTION OR CLAIM IS PERMANENTLY BARRED.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc10" id="acc10 ">
                            <CustomTypography>10. Injunctive Relief</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>You agree that a breach of these Terms of Use will cause irreparable injury to Topbestsellers for which monetary damages would not be an adequate remedy and Topbestsellers shall be entitled to seek equitable relief, in addition to any remedies it may have hereunder or at law, without having to post a bond or other security.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc11" id="acc11 ">
                            <CustomTypography>11. Waiver And Severability</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>No waiver by Topbestsellers of a term or condition set forth in these Terms of Use shall be deemed a continuing waiver of such term or condition or a waiver of any other term or condition. Any failure of Topbestsellers to assert a right or provision under these Terms of Use shall not constitute a waiver of such right or provision.</p>

                            <p>If any provision of these Terms of Use is held by a court or other tribunal of competent jurisdiction to be invalid, illegal or unenforceable for any reason, such provision shall be eliminated or limited to the minimum extent such that the remaining provisions of the Terms of Use will continue in full force and effect.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc12" id="acc12 ">
                            <CustomTypography>12. Entire Agreement</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>The Terms of Use and our Privacy Notice constitute the sole and entire agreement between you and Topbestsellers with respect to the Site and supersede all prior and contemporaneous understandings, agreements, representations and warranties, both written and oral, with respect to the Site.</p>
                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc13" id="acc13 ">
                            <CustomTypography>13. Term and Termination</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>These Terms of Use will remain in full force and effect while you use the Site. Even after you are no longer a user of the Site, those provisions of these Terms that by their nature are intended to survive will remain binding on you, including Sections 1, 2, 6-15, and the Privacy Notice.</p>

                            <p>You agree that Topbestsellers, in its sole discretion, may suspend or terminate your access to the Site (or any part thereof) for any reason, with or without notice, and without any liability to you or to any third party for any claims, damages, costs or losses resulting therefrom.</p>


                        </AccordionDetails>
                    </CustomAccordion>

                    <CustomAccordion>
                        <AccordionSummary expandIcon={<PlusIconWhite />} aria-controls="acc14" id="acc14 ">
                            <CustomTypography>14. Applicable Law, Binding Arbitration, and Class Action Waiver</CustomTypography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <p>PLEASE READ THE FOLLOWING PARAGRAPHS CAREFULLY BECAUSE THEY REQUIRE YOU TO AGREE TO RESOLVE ALL DISPUTES BETWEEN US THROUGH BINDING INDIVIDUAL ARBITRATION.</p>

                            <p>The laws of the State of Washington will govern these Terms of Use and any disputes under them, without giving effect to any principles of conflicts of laws.</p>

                            <p>Any controversy or claim arising out of or relating to these Terms of Use shall be exclusively settled by arbitration administered by the American Arbitration Association in accordance with Commercial Arbitration Rules, then in effect. This arbitration provision is governed by the Federal Arbitration Act. The arbitration proceedings shall be held in King County, Washington. Any arbitration award may be entered in a court of competent jurisdiction.</p>

                            <p>All claims and disputes within the scope of this arbitration agreement must be arbitrated or litigated on an individual basis and not on a class basis. Claims of more than one customer or user cannot be arbitrated or litigated jointly or consolidated with those of any other customer or user.</p>

                            <p>Communications and Contact Information</p>

                            <p>Topbestsellers may contact you regarding these Terms of Use or the Privacy Notice using any Information you provide, or by any other means if you do not provide contact Information. If you no longer wish to receive communications from Topbestsellers, you can click on the “unsubscribe link” provided in such communications or contact us at contact@topbestsellers.org.</p>

                            <p>For all other feedback, comments, requests for technical support, and other communications relating to the Site, these Terms of Use, and the Privacy Notice, please contact us at contact@Topbestsellers.org or by mail at:</p>

                            <p>Topbestsellers.org LLC</p>

                            <p>929 108th Ave NE, Suite 1410</p>

                            <p>Bellevue, WA 98004</p>

                            <p>EFFECTIVE DATE: September 21, 2022</p>
                        </AccordionDetails>
                    </CustomAccordion>
                </CustomGrid>
            </CustomGrid>
            <CustomGrid item container spacing={0} xs={12} sm={12} md={12} lg={2}>

            </CustomGrid>
        </CustomGrid>
    </>                                                             
    );
};

const CustomAccordion = styled(Accordion)`
    width: 100%!important;
`
const CustomTypography = styled(Typography)`
    font-size: 16px!important;
    font-weight: 600!important;
`
const Toptitle = styled.span`
    font-size: 32px;
    font-weight: 700;
    margin: 10px 0;
`
const CustomGrid = styled(Grid)`
    margin-top: 0px!important;
    .center_grid {
        padding: 15px 15px;
    }
    .text_body_grid {
        font-family: sans-serif!important;
        line-height: 1.5;
    }
    .css-1elwnq4-MuiPaper-root-MuiAccordion-root {
        width: 100%!important;
    }
    .MuiAccordionSummary-expandIconWrapper.Mui-expanded{
        transform: rotate(225deg) !important;
    }
`
export default TermsOfUse