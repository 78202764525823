import { withRouter } from 'react-router'
import styled from 'styled-components'
import {
  MobileNavWrapper,
  MobileNavCont,
  MobNavHeader,
  MobNavItemCont,
  MobNavDivider,
} from './style'

import ClearIcon from '@mui/icons-material/Clear';
import Backdrop from '@mui/material/Backdrop';
import { useEffect } from 'react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import { ListItemButton } from '@mui/material'
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

const MobileSideNav = (props: any) => {
  const { showSideNav, setShowSideNav, products, clicked_product, clicked_view_quiz_on_live, clicked_delete_quiz, add_quiz } = props
useEffect(() => {
  // console.log(props.products)
}, [])

const clicked_product_sidenav = (product_active:boolean, index:number, product_name:string, _id:string) => {
  clicked_product(product_active, index, product_name, _id)
  setShowSideNav(false)
}

  return (
    <Backdrop
      sx={{ color: '#fff', zIndex: 99 }}
      open={showSideNav}
      onClick={() => { setShowSideNav(false) }}
    >
    <MobileNavWrapper open={showSideNav} onClick={(e: any) => { e.stopPropagation() }}>
      <MobileNavCont>
        <MobNavHeader>
          <FlexSBCont>
            <FlexCont>
              <img src={require('assets/logo_avatar3.png').default} alt="" />
              &nbsp;<CustomSpan ><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</CustomSpan>
            </FlexCont>
            <ClearIcon className="cross-icon" onClick={() => setShowSideNav(false)}/>
          </FlexSBCont>
        </MobNavHeader>
        <MobNavItemCont>
          <CustomDiv className={"add_category_div"}>
            <CustomListItemButton className={"min-width-0 add_category_icon"} onClick={(e)=> add_quiz()}>
                <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/>
            </CustomListItemButton>
          </CustomDiv>
          <CustomDiv className={"list_div"}>
          {
            products.length > 0 && products.map((product:any, index:number) => (
              <CustomDiv key={index} className={"btn_div"}>
                <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_product_sidenav(product.active, index, product.product_name, product._id)}>
                  <CustomDiv className={"txt_div"}>
                    {product.product_name}
                  </CustomDiv>                                        
                  <CustomDiv className={"min-width-0 edit_icon"} onClick={(e:any)=> clicked_view_quiz_on_live(e, product.quiz_url)}>
                    <VisibilityIcon fontSize="small"/>
                  </CustomDiv>
                  <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_delete_quiz(e, index, product._id)}>
                    <DeleteOutlineIcon fontSize="small" />
                  </CustomDiv>
                </CustomDiv>
              </CustomDiv>
            ))
          } 
          </CustomDiv>
          <MobNavDivider />
        </MobNavItemCont>
      </MobileNavCont>
    </MobileNavWrapper>
    </Backdrop>
  )
}

const CustomDiv = styled.div`
    &.add_category_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
        height: 50px!important;
    }

    &.add_item_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
        height: 50px!important;
    }

    &.txt_div {
        flex: 1 1 auto!important;
    }

    &.list_div {
        height: 80vh;
        overflow: auto;
    }

    &.btn_style {
        color: black;
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &.btn_div {
       margin: 2px 0px;
       transition: all 350ms ease-in-out;
    }
    &.btn_div:hover {
       background-color: #00000022!important;
    }

    &.active_list_item {
        background-color: #1e947e;
        color: white;
        transition: all 300ms ease-in-out;
    }

    &.min-width-0 {
        min-width: 0px!important;
        margin: 0 1px!important;
    }

    &.move_icon: hover {
        color: #fded0a!important
    }

    &.edit_icon: hover {
        color: #00ceff!important;
    }

    &.delete_icon: hover {
        color: #ff3100cf!important;
    }
`

const FlexSBCont = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  div.discountCard{
    display:flex;
    height:75px;
  }
  &.discountLevel{
    display: grid;
  }
  &.mobileDropdown{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 50%;
    margin-left: 25%;
    margin-top: 30px;
  }
  @media (min-width: 800px) {
    div.borrowLimit{
      display: flex;
    }
  }
  
`

const FlexCont = styled.div`
  display: flex;
  align-items: center;
  img {
    width: 33px;
    height: 33px;
  }
  &.borrow-limit{
    width: auto;
    text-align: center;
  }
  &.netApyWeb{
    display: flex;
    justify-content: center;
  }
  &.LendingStats{
    display: flex;
    justify-content: center;
    align-items: center;
  }
`
const CustomSpan = styled.span`
  font-size: 20px;
  color: black!important;
  @media (min-width: 800px) {
    font-size: 25px;
  }
`

const BoldSpan = styled.span`
  font-weight: 700;
`
const CustomListItemButton = styled(ListItemButton)`
    &.add_category_icon {
        display: flex;
        justify-content: center;
        color: #1e947e;
    }

    &.add_category_icon: hover {
        color: #1e947e;
    }
`

export default withRouter(MobileSideNav)
