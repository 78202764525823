/* global chrome */

import * as React from 'react';
import { useState, useEffect } from "react";
import { useParams } from 'react-router-dom'
import history from 'modules/app/components/history'

export default function Quiz_Old() {
  const { old_url } = useParams<{ old_url:string }>();
  
  useEffect(() => {
  }, [])

  useEffect(() => {
    (async () => {
      // console.log(old_url)
      if ( old_url === 'ma_1705462799739') {
        history.push('/quiz/best-massage-chair')
      } else if ( old_url === 'el_1706325229782') {
        history.push('/quiz/best-electric-toothbrush')
      } else if ( old_url === 'pr_1701231701126') {
        history.push('/quiz/best-protein-powder')
      } else {
        history.push('/quiz/list/all')
      }
    })()    
  }, [old_url])

  return ( 
    <>
    </>  
  );
}