import { useState } from 'react'
import styled from 'styled-components'
import { screenSizes } from 'shared/styles/theme'
import CheckIcon from '@mui/icons-material/Check';
import { Grid, LinearProgress, CircularProgress } from '@mui/material'
import './mobile_quiz_list.css'

const Mobile_Quiz = (props: any) => {
    const { products, visible } = props

    return (
        <CustomWrapperItems>
            <div className="mobile_quiz_list_header">
          <div className="mobile_quiz_list_header_container">
            <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_left">
                {/* <a href="/quiz" className="quiz_back_href" >
                  <svg width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"></path></svg>
                </a> */}
            </div>
            <div className="mobile_quiz_col_auto mobile_quiz_p_0">
              <div className="mobile_quiz_list_navbar_brand">
                <a href="/quiz/list/all" className="mobile_quiz_list_brand_href">
                Product Matcher
                </a>
              </div>
            </div>
            <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_right">
             
            </div>
          </div>
          
        </div>
        <div className={visible?'mobile_quiz_list_div_fadeIn':'mobile_quiz_list_div_fadeOut'}>
          <CustomGrid container spacing={0} className="container_grid">
            <CustomGrid item sm={12} className="main_grid">
              <div className="mobile_quiz_list_container">
                {/* <div className="choice_div"> */}
                    {/* <div> */}
                      {/* <ul> */}
                        {products.length > 0 && products.map((product:any, index:number) => (
                          <div
                            onClick={() => props.click_product(product.quiz_url)}
                            key={index}
                            className={'mobile_product_div'}
                          >
                            {product.product_name}
                          </div>
                        ))}
                      {/* </ul> */}
                    {/* </div> */}
                {/* </div> */}
              </div>
              
            </CustomGrid>
          </CustomGrid>
          </div>
        </CustomWrapperItems> 
    );
};

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
    background: #fff;
`
const CustomGrid = styled(Grid)`
  
    &.container_grid {
        display: flex!important;
        justify-content: center!important;
        padding: 0px 10px!important;
    }
    .main_grid {
        padding: 0px 10px 0px!important;
    }
`


export default Mobile_Quiz