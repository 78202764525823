import styled from 'styled-components'
import { Divider, Box   } from '@mui/material'

interface Data {
    no: number;
    username: string;
    gclid: string;
    amazon_tracking_id: string;
}

interface Props {
    rows: Data[]
}

const MobileUsers = (props: Props) => {
    const { rows } = props

    return (
        <>
        {
            rows.map((row: Data, index: number) => {
                return (
                    <CustomBox key={index} className="parent_box">
                        {/* <CustomDiv className="child_div">
                            <CustomSpan>No: </CustomSpan>
                            <CustomSpan className="value">{row.no}</CustomSpan>
                        </CustomDiv>
                        <Divider /> */}
                        <CustomDiv className="child_div d_column">
                            <CustomSpan>User Name: </CustomSpan>
                            <CustomSpan className="value">{row.username}</CustomSpan>
                        </CustomDiv>
                        <Divider />
                        <CustomDiv className="child_div d_column">
                            <CustomSpan>Tracking Id: </CustomSpan>
                            <CustomSpan className="value">{row.amazon_tracking_id}</CustomSpan>
                        </CustomDiv>
                        <Divider />
                        <CustomDiv className="child_div d_column">
                            <CustomSpan>Gcl Id: </CustomSpan>
                            <CustomSpan className="value">{row.gclid}</CustomSpan>
                        </CustomDiv>
                    </CustomBox>
                )
            })
        }
        </>                                                               
    );
};

const CustomDiv = styled.div`

`
const CustomSpan = styled.span`
  &.value {
    padding-left: 5px!important;
  }
`

const CustomBox = styled(Box)`
  width: 100%;
  display: flex;
  flex-direction: column;
  background: white;
  margin: 3px 0;
  border-radius: 10px;
  position: relative;
  box-sizing: border-box;
  &.parent_box {
    padding: 3px 9px!important;
    margin: 10px 0!important;
    .child_div {
      margin: 6px 0!important;
    }
    .d_column {
      display: flex;
      flex-direction: column;
      word-wrap: break-word;
    }
  }
`
export default MobileUsers