import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import styled from 'styled-components'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import { delete_user_of_quiz } from 'modules/api/market-api';
import history from 'modules/app/components/history'

interface Props {
  selected: boolean;
}

const CustomDiv = styled.div<Props>`
  padding-top: 8px!important;
  border: ${props => { 
    if ( props.selected === true ) { 
      return '2px solid #1976d2' 
    } else {
      return 'none'
    } 
  }};
`;

export default function DeleteUserOfQuizDialog(props:any) {
  const [ open, setOpen ] = React.useState<boolean>(false);
  const [ isLoading, setIsLoading ] = React.useState<boolean>(false)

  React.useEffect(() => {
    setOpen(props.open)
    // console.log(props.open)
    // if(props.open === true) {
    //   let temp_quiz = JSON.parse(JSON.stringify(props.products[props.selected_product_index].quiz))
    //   let temp_users = JSON.parse(JSON.stringify(props.products[props.selected_product_index].users))
    //   console.log(temp_quiz)
    //   console.log(temp_users)
    // }
    
  }, [props.open])

  const handleClose = () => {
    props.setOpen(false);
  };

  const handleSave = () => {
    try {
      (async () => {
        setIsLoading(true)
        let del_user_name = props.del_user_name
        // console.log(del_user_name)
        let temp_quiz = JSON.parse(JSON.stringify(props.products[props.selected_product_index].quiz))
        let temp_users = JSON.parse(JSON.stringify(props.products[props.selected_product_index].users))
// console.log(temp_quiz)
// console.log(temp_users)

        temp_quiz = temp_quiz.map((ele:any, index:number) => {
          ele.users = ele.users.filter((ele1:any) => ele1.user_name !== del_user_name)

          return ele
        })

        temp_users = temp_users.filter((ele:any) => ele.user_name !== del_user_name)

// console.log(temp_quiz)
// console.log(temp_users)

        const delete_result = await delete_user_of_quiz(props.products[props.selected_product_index]._id, temp_quiz, temp_users)

        // console.log(delete_result)
        if ( delete_result.status === 'success' ) {
          props.setProducts((prev: any[]) => {
            let next = JSON.parse(JSON.stringify(prev))

            next[props.selected_product_index].quiz = temp_quiz
            next[props.selected_product_index].users = temp_users

            return next
          })
          setIsLoading(false)
          props.setOpen(false);
        } else if ( delete_result.status === 'error' ) {
          setIsLoading(false)
          props.setOpen(false);
          history.push('/admin/signin')
        }
      })()
    } catch ( e ) {
      // console.log(e)
    }
    
  };

  return (
        <>
          <div>
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="draggable-dialog-title"
            >
              {
                isLoading === true ? 
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                  open={isLoading}
                  // onClick={handleClose}
                >
                  <CircularProgress color="inherit"/>
                  <p>&nbsp;&nbsp; Deleting Quiz ...</p>
                </Backdrop>
                : <></>
              }
              <DialogTitle style={{ cursor: 'move' }} id="draggable-dialog-title">
                Delete!
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  This quiz will be deleted, are you okay?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button onClick={handleSave}>Delete</Button>
              </DialogActions>
            </Dialog>
          </div>
        </>
    
  );
}