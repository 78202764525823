import { useState } from 'react'
import styled from 'styled-components'
import { screenSizes } from 'shared/styles/theme'
import CheckIcon from '@mui/icons-material/Check';
import { Grid, LinearProgress, CircularProgress, Box, Button } from '@mui/material'
import './mobile_quiz.css'
import Backdrop from '@mui/material/Backdrop';

function createMarkup(str:string) {
    return {__html: str};
}

const Mobile_Quiz = (props: any) => {
    const { 
        showResult, 
        activeQuestion, 
        questions, 
        progress, 
        visible, 
        question, 
        choices, 
        selectedAnswerIndex, 
        isLoading, 
        analyze_result, 
        circleprogress, 
        isCircleLoading, 
        CircularProgressWithLabel, 
        product_name, 
        image_url, 
        product_url 
    } = props

    return (
        <>
            {
                isLoading === true || isCircleLoading === true ?
                <CustomWrapperItems>
                     {/* <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isLoading}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit"/>
                        <p>&nbsp;&nbsp; Analyzing ...</p>
                    </Backdrop> */}
                    <div className="mobile_quiz_header">
                    <div className="mobile_quiz_header_container">
                        <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_left">
                        {!showResult ? (
                            <div onClick={() => props.click_prev()} className="mobile_quiz_back_href" >
                            <svg width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"></path></svg>
                            </div>
                        ) : (
                            <></>
                        )}
                        
                        </div>
                        <div className="mobile_quiz_col_auto quiz_p_0">
                        <div className="mobile_quiz_navbar_brand">
                            <a href="/quiz/list/all" className="mobile_quiz_brand_href">
                            Product Matcher
                            </a>
                        </div>
                        </div>
                        <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_right">
                        <div className="mobile_quiz_step_count">
                            {!showResult ? (
                            <div>
                                <span className="mobile_active-question-no">{props.addLeadingZero(activeQuestion + 1)}</span>
                                <span className="mobile_total-question">/{props.addLeadingZero(questions.length)}</span>
                            </div>
                            ) : (
                            <></>
                            )}
                            
                        </div>
                        </div>
                    </div>
                    <div className="mobile_progress_div"> 
                        <CustomLinearProgress variant="determinate" value={progress} color="primary" />
                    </div>
                    </div>
                    <div className={visible?'mobile_quiz_div_fadeIn':'mobile_quiz_div_fadeOut'}>
                        
                        <div className="result">
                            <CustomGrid container spacing={0} className="container_grid">
                                <CustomGrid item sm={12} className="circle_loading_main_grid">
                                    <CircularProgressWithLabel value={circleprogress} size={180} text_size="h2"/>
                                </CustomGrid>
                                <CustomGrid container spacing={0} className="circle_loading_text_div">
                                    Finding the perfect {product_name} specifically for you
                                </CustomGrid>
                            </CustomGrid>
                        </div>
                    </div>
                </CustomWrapperItems> 
                :
                <CustomWrapperItems>
                    <div className="mobile_quiz_header">
                    <div className="mobile_quiz_header_container">
                        <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_left">
                        {!showResult ? (
                            <div onClick={() => props.click_prev()} className="mobile_quiz_back_href" >
                            <svg width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"></path></svg>
                            </div>
                        ) : (
                            <></>
                        )}
                        
                        </div>
                        <div className="mobile_quiz_col_auto quiz_p_0">
                        <div className="mobile_quiz_navbar_brand">
                            <a href="/quiz/list/all" className="mobile_quiz_brand_href">
                            Product Matcher
                            </a>
                        </div>
                        </div>
                        <div className="mobile_quiz_col_2 mobile_quiz_p_0 mobile_quiz_col_2_right">
                        <div className="mobile_quiz_step_count">
                            {!showResult ? (
                            <div>
                                <span className="mobile_active-question-no">{props.addLeadingZero(activeQuestion + 1)}</span>
                                <span className="mobile_total-question">/{props.addLeadingZero(questions.length)}</span>
                            </div>
                            ) : (
                            <></>
                            )}
                            
                        </div>
                        </div>
                    </div>
                    <div className="mobile_progress_div"> 
                        <CustomLinearProgress variant="determinate" value={progress} color="primary" />
                    </div>
                    </div>
                    <div className={visible?'mobile_quiz_div_fadeIn':'mobile_quiz_div_fadeOut'}>
                        {!showResult ? (
                        <div className="mobile_quiz-container">
                            <div >
                            <div>
                                <h2>{question}</h2>
                                {/* <span>{question}</span> */}
                            </div>
                            </div>
                            <div className="mobile_choice_div">
                            <div>
                                <ul>
                                {choices.length > 0 && choices.map((answer:any, index:number) => (
                                    <li
                                    onClick={() => props.onAnswerSelected(question, answer, index)}
                                    key={index}
                                    className={selectedAnswerIndex[activeQuestion] === index ? 'mobile_selected-answer' : undefined}
                                    >
                                    {answer.choice}
                                    <div className={selectedAnswerIndex[activeQuestion] === index ? "mobile_check_div_fadeIn": "mobile_check_div_fadeOut"}>
                                        {selectedAnswerIndex[activeQuestion] === index ? <CheckIcon color='primary' />: <></>}
                                    </div>
                                    </li>
                                ))}
                                </ul>
                            </div>
                            </div>
                        </div>
                        ) : (
                        <div className="result">
                            <CustomGrid container spacing={0} className="container_grid">
                                <CustomGrid item sm={12} className="main_grid">
                                <h3>{props.model_name}</h3>
                                </CustomGrid>
                            </CustomGrid>
                            
                            <CustomGrid container spacing={0} className="container_grid result_container_grid">
                                {/* <CustomGrid item sm={6} className="result_div">
                                    {
                                        result.map((ele: any, index: number) => {
                                        return (
                                            <div key={index}>
                                            <div className={"question_div"}><span> {index + 1} &nbsp;{ele.question}</span></div>
                                            <div className={"answer_div"}><span>&nbsp;{ele.answer}</span></div>
                                            <p></p>
                                            </div>
                                        )
                                        })
                                    }
                                </CustomGrid> */}
                                <CustomGrid item sm={12} className="analyze_result_div">
                                    <Page>
                                        <section className="imginner">
                                            <aside 
                                            className="imageupload" 
                                            >
                                            <div className="imageuploadinner" onClick={() => {window.open(`${product_url}`, "_blank")}}>
                                                <img
                                                src={image_url}
                                                // Revoke data uri after image is loaded
                                                onLoad={() => {
                                                    URL.revokeObjectURL(image_url)
                                                }}
                                                />
                                            </div>
                                            </aside>
                                            <div className="dropzoneOuter">
                                            </div>
                                            
                                        </section>
                                    </Page>
                                    <div>
                                        <CustomBox>
                                            <CustomA href={product_url} target="_blank" onClick={() => {}}><CustomButton sx={{ textTransform: "none" }} >Check Price</CustomButton></CustomA>
                                            {
                                                product_url.indexOf("amazon.com") > -1 ? <img src={require('assets/buttonUnderImage.webp').default} alt="" />
                                                :
                                                <></>
                                            }
                                        </CustomBox>
                                    </div>
                                </CustomGrid>
                                <CustomGrid item sm={12} className="analyze_result_div">
                                    <div dangerouslySetInnerHTML={createMarkup(analyze_result)} />
                                    {/* {analyze_result.replaceAll('\n', '\n\n')} */}
                                </CustomGrid>
                            </CustomGrid>
                        </div>
                        )}
                    </div>
                </CustomWrapperItems> 
            }
        </>
        
    );
};

const CustomA = styled.a`
    text-decoration: none;
    // width: 100%;

`

const CustomButton = styled(Button)`
`

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px;

  img {
    width: 133px;
    height: 24px;
    margin-top: 5px;
  }

  ${CustomButton} {
    width: 133px;
    padding: 15px;
    cursor: pointer;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 1.33;
    font-size: 15px!important;
    letter-spacing: 0.03em!important;
    color: white!important;
    background-color: #f2a842!important;
    border: 1px solid #f09637!important;
    
    &:hover {
        border: 1px solid #f2a842!important;
        background-color: #f09637!important;
    }  
  }
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
    background: #fff;
`
const CustomGrid = styled(Grid)`
  
    &.result_container_grid {
      padding: 5px 0px 15px!important;
    }

    &.container_grid {
        display: flex!important;
        justify-content: center!important;
        padding: 0px 10px!important;
    }
  .main_grid {
    // background: linear-gradient(90.04deg,#0d5ff5 0.03%,#c0e5ff 99.96%)!important;
    // background: linear-gradient(145deg,#5c8fed 0.03%,#d1f0f7 99.96%)!important;
    padding: 0px 50px 0px!important;
  }

  .circle_loading_main_grid {
    padding: 50px 50px 0px!important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div {
    display: flex!important;
    justify-content: center!important;
    font-size: 22px!important;
    font-weight: 500!important;
    padding: 20px 0!important;
    text-align:center!important;
  }

  .result_div {
    border-right: 1px solid gray!important;
    padding: 10px!important;
  }
  .analyze_result_div {
    white-space: pre-wrap!important;
    padding: 20px 20px!important;
    font-size: 18px!important;
  }
`
const CustomLinearProgress = styled(LinearProgress)`
  &.css-eglki6-MuiLinearProgress-root {
    background-color: #f0f1f1a8;
  }
`

const Page = styled.div`
  color: #fff;
  // border: 1px solid rgba(0,0,0,0.05);
  // border-radius: 24px;
  // width: fit-content;
  // min-width: 400px;
  // min-height: 360px;
  // margin-left: 20%;
  display: flex;
  justify-content: Center;
  align-items: Center;
  // @media (max-width: 1200px) {
  //   margin: auto;
  //   margin-bottom: 30px;
  // }
  .imageupload {
    display: flex;
    justify-content: Center;
    // background: #b4fbf8c7;
    // padding: 40px 130px;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      width: 230px;
      height: 230px;
    //   border: 1px solid gray;
    // border-radius: 5px;
      // border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
  
`

export default Mobile_Quiz