import styled from 'styled-components'
import { Box } from '@mui/material'

const OverviewBoxMobile = (props: any) => {
    const { overview } = props;

    return (
            <CustomBox className="parent_box">
                <CustomBox className="center_box">
                    <Box className="main_box">
                        <span className='overview_text'>
                            {overview}
                        </span>
                    </Box>
                </CustomBox>
            </CustomBox>
    )
}

const CustomBox = styled(Box)`
        width: 100%;
        display: flex;
        background: aliceblue;
        margin: 10px 0;
        border-radius: 10px;
        position: relative;
        .center_box {
            background: white;
            margin: 0;
            width: auto;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding: 20px 15px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .overview_text {
                    color: #0F1A16;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                }
            }
        }
        
`

export default OverviewBoxMobile