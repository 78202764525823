/* global chrome */

import * as React from 'react';
import { useState, useEffect, useRef } from "react";
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import './quiz_list.css'
// import LinearProgress from '@mui/material/LinearProgress';
import { useLocation, useParams } from 'react-router-dom'
import { read_all_quiz } from 'modules/api/market-api'
import history from 'modules/app/components/history';
import Mobile_Quiz_List from './Mobile_Quiz_List';

export default function Quiz_list() {
  const [ visible, setVisible ] = useState<boolean>(false)
  const [ products, setProducts ] = useState<any>([])

  useEffect(() => {
    (async () => {
      setVisible(true)
      const result:any = await read_all_quiz()
      if( result.status === 'success' ) {
        let products = result.result
        setProducts(products)
    } else if ( result.status === 'error' ) {
        history.push('/admin/signin')
    }
    })()
  }, [])

  const click_product = (quiz_url:string) => {
    // window.open(`/quiz/${quiz_url}`, "_blank");
    history.push(`/quiz/${quiz_url}`);
  }

  return (
    <>
      {/* <div style={{backgroundColor: 'white'}}> */}
      <CustomWrapperItems >
        <div className="quiz_list_header">
          <div className="quiz_list_header_container">
            <div className="quiz_col_2 quiz_p_0 quiz_col_2_left">
                {/* <a href="/quiz" className="quiz_back_href" >
                  <svg width="24" height="24" viewBox="0 0 24 24"><path fillRule="evenodd" clipRule="evenodd" d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"></path></svg>
                </a> */}
            </div>
            <div className="quiz_col_auto quiz_p_0">
              <div className="quiz_list_navbar_brand">
                <a href="/quiz/list/all" className="quiz_list_brand_href">
                Product Matcher
                </a>
              </div>
            </div>
            <div className="quiz_col_2 quiz_p_0 quiz_col_2_right">
             
            </div>
          </div>
          
        </div>
        <div className={visible?'quiz_list_div_fadeIn':'quiz_list_div_fadeOut'}>
          <CustomGrid container spacing={0} className="container_grid">
            <CustomGrid item sm={12} className="main_grid">
              <div className="quiz_list_container">
                {/* <div className="choice_div"> */}
                    {/* <div> */}
                      {/* <ul> */}
                        {products.length > 0 && products.map((product:any, index:number) => (
                          <div
                            onClick={() => click_product(product.quiz_url)}
                            key={index}
                            className={'product_div'}
                          >
                            {product.product_name}
                          </div>
                        ))}
                      {/* </ul> */}
                    {/* </div> */}
                {/* </div> */}
              </div>
              
            </CustomGrid>
          </CustomGrid>
          </div>
        </CustomWrapperItems>
      {/* </div> */}

        <Mobile_Quiz_List 
          products={products}
          visible={visible}
          click_product={click_product}
        />
    </>
  );
}

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
    // background: linear-gradient(145deg,#ffffff 0.03%,#147cfd 99.96%)!important;
    background: #fff;
`

const CustomGrid = styled(Grid)`
  &.container_grid {
    @media (max-width: ${screenSizes.mediaXLL}px) {
      padding: 0px 20px!important;
    }
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding: 0px 30px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding: 0px 40px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding: 0px 50px!important;
    }
  }

  .main_grid {
    padding: 25px 50px!important;
  }

`
// const CustomLinearProgress = styled(LinearProgress)`
//   &.css-eglki6-MuiLinearProgress-root {
//     background-color: #f0f1f1a8;
//   }
// `
