import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { ActionCard, CardBText, ImageAndText, CardSubText } from './style'
import ProductBox from './components/ProductBox';
import { getAmazonJPN_mongo, checkAndAdd_userAndId } from 'modules/api/market-api'
import { setCurrentProductUrl, setCurrentGclidAndCurrentSearch } from 'logic/action/base.action'
import MobileDiv from './MobileDiv'
import history from 'modules/app/components/history'
// import TermsOfUse from './components/TermsOfUse'
// import PrivacyPolicy from './components/PrivacyPolicy'
// import Data from './components/Data'
import TrustBox from './components/TrustBox'
import OverviewBox from './components/OverviewBox'
import BuyersGuideBox from './components/BuyersGuideBox'
import Backdrop from '@mui/material/Backdrop';
import CircularProgress, {
    CircularProgressProps,
  } from '@mui/material/CircularProgress';

const Base = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const location = useLocation()
    const { product_url } = useParams<{ product_url: string }>();

    const [ items, setItems ] = useState<any>([])
    const [ title, setTitle ] = useState<string>('')
    const [ product, setProduct ] = useState<string>('')
    const [ overview, setOverview ] = useState<string>('')
    const [ buyer_guide, setBuyerGuide ] = useState<string>('')
    const [ buyer_guide_title, setBuyerGuideTitle ] = useState<string>('')
    const [ evaluated_number, setEvaluatedNumber ] = useState<string>("0")
    // const [ e_num, setENum ] = useState<number>(889)
    const [ review_number, setReviewNumber ] = useState<string>("0")
    // const [ r_num, setRNum ] = useState<number>(3289)
    // const [ e_duration, setEDuration ] = useState<number>(1)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)

   
    const e_func = () => {
        const e_num = 889
        let start = 0
        let start1 = 0
        const end = parseInt(String(e_num).substring(0,2))
        const end1 = parseInt(String(e_num).substring(2))

        if( start === end || start1 === end1) return;

        let timer = setInterval(() => {
            if( start < end ) {
                start += 3
            }
            if ( start1 < end1 ) {
                start1 += 1
            }
            
            setEvaluatedNumber(String(start) + String(start1))
            if( start >= end && start1 >= end1 ) {
                setEvaluatedNumber(String(end) + String(end1)) 
                clearInterval(timer)
            }    
        }, 10)
    }
   
    // const r_func = () => {
    //     const r_num = 3289
    
    //     let start = 0
    //     let start1 = 0
    //     const end = parseInt(String(r_num).substring(0,2))
    //     const end1 = parseInt(String(r_num).substring(2))

    //     if( start === end || start1 === end1) return;

    //     let timer = setInterval(() => {
    //         if( start < end ) {
    //             start += 3
    //         }
    //         if ( start1 < end1 ) {
    //             start1 += 5
    //         }
            
    //         setReviewNumber(String(start) + String(start1))
    //         if( start >= end && start1 >= end1 ) {
    //             setReviewNumber(String(end) + String(end1)) 
    //             clearInterval(timer)
    //         }    
    //     }, 10)
    // }

    useEffect(() => {
        let link = window.location.href
        let search = location.search
        let gclid = location.search
        let msclkid = location.search

        // console.log(location)
        // console.log(location.search)

        if(gclid.indexOf('gclid=') === -1) {
            gclid = '0'
        } else {
            gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
            if(gclid.indexOf('&') !== -1){
                gclid = gclid.slice(0, gclid.indexOf('&'))
            }
        }

        if(msclkid.indexOf('msclkid=') === -1) {
            msclkid = '0'
        } else {
            msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
            if(msclkid.indexOf('&') !== -1){
                msclkid = msclkid.slice(0, msclkid.indexOf('&'))
            }
        }

        // dispatch(setCurrentGclidAndCurrentSearch(gclid, msclkid, search))
        localStorage.setItem('gclid', gclid)
        localStorage.setItem('msclkid', msclkid)
        localStorage.setItem('search', search)
        localStorage.setItem('link', link)

        if(product_url !== undefined && product_url !== '' ){
            setIsLoading(true)
            // checkAndAdd_userAndId(selector.gclid.currentGclid, selector.gclid.currentMsclkid, selector.gclid.currentSearch)
            // checkAndAdd_userAndId(gclid, msclkid, search)
            checkAndAdd_userAndId()
                .then(result => {
                    getAmazonJPN_mongo(product_url)
                        .then((result:any) => {
                            // console.log(result.data)
                            let items = result.data.items
                            
                            items = items.map((item: any, index: number) => {
                                // item.DetailPageURL = item.DetailPageURL.replace('?tag=revisions05-20', '?tag=' + localStorage.getItem('amazon_tracking_id'))

                                if(item.DetailPageURL.indexOf("tag=") !== -1){
                                    let temp_str = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="));
                                    let length = temp_str.indexOf("&")
                                    let temp_str1;
                                    
                                    if(length === -1){
                                        temp_str1 = item.DetailPageURL.substring(item.DetailPageURL.indexOf("tag="))
                                    } else if(length !== -1){
                                        temp_str1 = item.DetailPageURL.substr(item.DetailPageURL.indexOf("tag="), length)
                                    }
                                    
                                    item.DetailPageURL = item.DetailPageURL.replace(temp_str1, "tag=" + localStorage.getItem('amazon_tracking_id'))
                                    
                                    
                                } else if(item.DetailPageURL.indexOf("tag=") === -1) {
                                    item.DetailPageURL = item.DetailPageURL + "&tag=" + localStorage.getItem('amazon_tracking_id')
                                }

                                return item
                            })
                            // setItems(result.data.items)
                            setItems(items)
                            setTitle(result.data.family_name)
                            setProduct(result.data.product_name)
                            setOverview(result.data.overview)
                            setBuyerGuide(result.data.buyer_guide)
                            setBuyerGuideTitle(result.data.buyer_guide_title)
                            
                            setIsLoading(false)

                            e_func()
                            // r_func()
                        })
                })
            }

            // if(product_url !== 'terms-of-use' && product_url !== 'privacy-policy' && product_url !== 'data' && product_url !== 'admin') {
            //     dispatch(setCurrentProductUrl(product_url))
            // }
    }, [product_url])

    // useEffect(() => {
        
    // }, [selector.base.currentProductUrl])

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <>
            {
                // product_url === 'terms-of-use' ? <TermsOfUse />
                // : product_url === 'privacy-policy' ? <PrivacyPolicy />
                //  product_url === 'data' ? <Data />
                product_url === 'admin' ? <></>
                : <>
                    {
                        isLoading  ? 
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={isLoading}
                            // onClick={handleClose}
                        >
                            <CircularProgress color="inherit"/>
                            <p>&nbsp;&nbsp; Loading ...</p>
                        </Backdrop> : <></>
                    }
                    <CustomWrapperItems>
                        <CustomGrid container spacing={0} >
                            <CustomGrid item container spacing={0} sm={6} className="grid-header-left grid-flex-item-start">
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                        <ShortSpan onClick={goStartPage}>
                                            <HomeIcon  />
                                        </ShortSpan>
                                        <ChevronRightIcon />
                                        <ShortSpan>
                                            {title}
                                        </ShortSpan>
                                        <ChevronRightIcon />
                                        <ShortSpan className="lastSpan">
                                            {product}
                                        </ShortSpan>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                                        <CategoryTitle>Best {product} of 2023</CategoryTitle>
                                    </CustomGrid>
                                </CustomGrid>
                                <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                                    <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                        <UpdatedSpan>Last Updated September 2023</UpdatedSpan>
                                    </CustomGrid>
                                </CustomGrid>
                            </CustomGrid>
                            <CustomGrid item sm={6} className="grid-header-right grid-flex-item-end">
                                <ActionCard >
                                    <ImageAndText>
                                        <img src={require('assets/nstar1.svg').default} alt="" />
                                        {/* <img src={require('assets/star1.svg').default} alt="" /> */}
                                        {/* <CardSubText>889</CardSubText> */}
                                        <CardSubText>{evaluated_number}</CardSubText>
                                    </ImageAndText>
                                    <CardBText>Products evaluated</CardBText>
                                </ActionCard>

                                {/* <ActionCard >
                                    <ImageAndText>
                                        <img src={require('assets/nstar2.svg').default} alt="" />
                                        <CardSubText>{review_number}</CardSubText>
                                    </ImageAndText>
                                    <CardBText>Reviews analyzed</CardBText>
                                </ActionCard> */}
                            </CustomGrid>
                        </CustomGrid> 
                        <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} />
                        <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important'}}>
                            {/* <CustomGrid item container spacing={0} sm={3} className="grid-header-left grid-header-right grid-flex-item-start-overview" sx={{background: 'transparent!important'}} > */}
                            <CustomGrid item container spacing={0} sm={4} className="grid-header-left grid-flex-item-start-overview" sx={{background: 'transparent!important'}} >
                                <TabTitle>Overview</TabTitle>
                                <OverviewBox overview={overview} />
                                <BuyersGuideBox buyer_guide={buyer_guide} product={product} buyer_guide_title={buyer_guide_title}/>
                            </CustomGrid>
                            {/* <CustomGrid item container spacing={0} sm={9} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} > */}
                            <CustomGrid item container spacing={0} sm={8} className="grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                                <TabTitle>Rankings</TabTitle>
                                
                                {   items.length !== 0 ?
                                    items.length > 0 && items.map((item: any, index: number)=> {
                                        if( index < 3) {
                                            return <ProductBox key={index} item={item} num={index + 1}/>
                                        } else {
                                            return <div key={index}></div>
                                        }
                                    }
                                    ) : ''
                                }
                                { <TrustBox /> }
                                {/* <div style={{width: '100%'}}>
                                    <iframe src="https://topbestsellers.org/quiz/dq/cr_1701065091657" height="700" width="100%" title="quiz iframe">
                                    </iframe>
                                </div> */}
                                {   items.length !== 0 ?
                                    items.length > 0 && items.map((item: any, index: number)=> {
                                        if( index >= 3) {
                                            return <ProductBox key={index} item={item} num={index + 1}/>
                                        } else {
                                            return <div key={index}></div>
                                        }
                                    }
                                    ) : ''
                                }
                            </CustomGrid>
                        </CustomGrid>
                    </CustomWrapperItems> 

                    <MobileDiv items={items} title={title} product={product} overview={overview} buyer_guide={buyer_guide} buyer_guide_title={buyer_guide_title} review_number={review_number} evaluated_number={evaluated_number} />
                </>  
            }
        </>
    );
};

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`



const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 8px 20px;
  margin: 0px!important;

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
    align-items: center;
    align-content: flex-start;
  }

  .grid-flex-item-start-overview {
    display: flex;
    justify-content: start;
    align-items: start;
    flex-direction: column;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
        color: #1e947e!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 30px;
`
const UpdatedSpan = styled.span`
    font-family: Inter,Arial,sans-serif;
    color: gray!important;
    font-size: 18px;
`

const TabTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 22px;
`
export default Base