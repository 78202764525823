import styled from 'styled-components'
import { Box } from '@mui/material'

const BuyersGuideBoxMobile = (props: any) => {
    const { buyer_guide, buyer_guide_title } = props;

    return (
            <CustomBox className="parent_box">
                <CustomBox className="center_box">
                    <Box className="main_box">
                        <span className='buyer_guide_title_sub'>{ buyer_guide_title }</span>
                        <span className='buyer_guide_text'>
                            {buyer_guide}
                        </span>
                    </Box>
                </CustomBox>
            </CustomBox>
    )
}

const CustomBox = styled(Box)`
        width: 100%;
        // height: 180px;
        display: flex;
        background: aliceblue;
        margin: 10px 0;
        border-radius: 10px;
        position: relative;
        .center_box {
            background: white;
            margin: 0;
            width: auto;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding: 20px 15px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .buyer_guide_title {
                    font-family: GT Pressura,sans-serif;
                    font-weight: 700;
                    line-height: 1.25;
                    font-size: 32px;
                    letter-spacing: 0.01em;
                }
                .buyer_guide_title_sub {
                    letter-spacing: 0.01em;
                    color: #747574;
                    font-family: Inter,Arial,sans-serif;
                    line-height: 1.5rem;
                    font-weight: 600;
                    font-size: 1rem;
                    margin-bottom: 16px;
                }
                .buyer_guide_text {
                    color: #0F1A16;
                    font-family: "Roboto","Helvetica","Arial",sans-serif;
                    font-weight: 400;
                    font-size: 1rem;
                    line-height: 1.5;
                    letter-spacing: 0.00938em;
                }
            }
        }
`

export default BuyersGuideBoxMobile