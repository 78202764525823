import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button } from '@mui/material'
import { recordUserAndIds } from 'modules/api/market-api'

interface Props {
    item: any,
    num: number,
}

const ProductBoxMobile = (props: Props) => {
    const { item, num } = props;
    const selector = useSelector((state: any) => state)

    const recordIds = (e:any) => {
        e.stopPropagation()

        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.DetailPageURL, search, _id, undefined)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
    }

    return (
        <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
        {/* <div> */}
            <CustomDiv className="parent_box">
                <CustomDiv className="top_box"><img src={item.Images.Primary.Large.URL ? item.Images.Primary.Large.URL : require('assets/1.jpg').default} alt="" width="90" height="90"/></CustomDiv>
                <CustomDiv className="middle_box">
                    <div className="main_box">
                        <div className="part_box1">
                            <div className="part_box1_right">{item.ItemInfo.ByLineInfo != null && item.ItemInfo.ByLineInfo.Brand != null ? item.ItemInfo.ByLineInfo.Brand.DisplayValue : ''}</div>
                        </div>
                        <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}><div className="part_box2">{item.ItemInfo.Title.DisplayValue}</div></CustomA>
                    </div>
                </CustomDiv>
                <CustomDiv className="bottom_box">
                    <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}><CustomButton sx={{ textTransform: "none" }}>Check Price</CustomButton></CustomA>
                    <img src={require('assets/buttonUnderImage.webp').default} alt="" width="100" height="20" />
                </CustomDiv>
                <img src={require('assets/nbage1.svg').default} width="52" height="32" style={{ position: 'absolute', left: '-15px', top: '15px'}} alt="" />
                <img src={require('assets/nbage2.svg').default} width="52" height="32" style={{ position: 'absolute', left: '-22px', top: '43px', zIndex: '-1'}} alt="" />
                <OrderNumber >{num}</OrderNumber>
            </CustomDiv>
        </CustomA>
        // </div>
    )
}

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;
`
const CustomButton = styled(Button)`
`
const OrderNumber = styled.span`
    position: absolute;
    width: 52px;
    height: 32px;
    left: 5px;
    top: 18px;
    font-size: 18px;
    font-weight: 600;
    color: white;
    font-family: Inter;
`

const CustomDiv = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    margin: 3px 0;
    border-radius: 10px;
    position: relative;
    box-sizing: border-box;
    .top_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding: 10px 0 5px 0;
        img { 
            // width: 40%!important;
            // height: 40%!important;
            object-fit: contain;
            
        }
    }
    .middle_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: start!important;
        padding: 5px 10px;
        img {
            // width: 133px;
            // height: 24px;
            margin-top: 5px;
            }
        .main_box {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            width: 100%;
            .part_box1{
                display: flex;
                flex-direction: row;
                justify-content: flex-start;
                align-items: center;
                
                .part_box1_right {
                    font-family: GT Pressura,sans-serif;
                    font-weight: 700;
                    line-height: 1.33;
                    font-size: 20px;
                    letter-spacing: 0.01em;
                    color: #0f1a16;
                }
            }
            .part_box2{
                margin: 0px;
                color: #187664;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
                font-family: Inter, Arial, sans-serif;
                font-weight: 600;
                cursor: pointer;
                &:hover {
                    background-color: #edecec;
                }
            }
        }
    }
    .bottom_box {
        background: white;
        margin: 0px 0px;
        display: flex!important;
        align-items: center!important;
        justify-content: end!important;
        padding: 10px 10px;
        img {
            // width: 133px;
            // height: 24px;
            margin-top: 10px;
        }
        .MuiBox-root {
            display: flex;
            flex-direction: column;
            align-items: center;
        }
    }
    ${CustomButton} {
        width: 100%;
        padding: 10px;
        cursor: pointer;
        font-family: sans-serif;
        font-weight: 600;
        line-height: 1.33;
        font-size: 15px!important;
        letter-spacing: 0.03em!important;
        color: white!important;
        background-color: #f2a842!important;
        border: 1px solid #f09637!important;
        
        &:hover {
            border: 1px solid #f2a842!important;
            background-color: #f09637!important;
        }  
    }
`

export default ProductBoxMobile