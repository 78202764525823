import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, ListItemButton } from '@mui/material'
import { get_products_with_family_name, update_item_position } from 'modules/api/market-api'

import DriveFileMoveOutlinedIcon from '@mui/icons-material/DriveFileMoveOutlined';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import PlaylistAddIcon from '@mui/icons-material/PlaylistAdd';

import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import AddCategoryDialog from './AddCategoryDialog'
import EditCategoryDialog from './EditCategoryDialog'
import AddItemDialog from './AddItemDialog copy'
import EditItemDialog from './EditItemDialog'
import EditItemTitleDialog from './EditItemTitleDialog'
import DelItemDialog from './DelItemDialog'
import DelCategoryDialog from './DelCategoryDialog'
import MoveCategoryDialog from './MoveCategoryDialog';
import KeyboardDoubleArrowUpIcon from '@mui/icons-material/KeyboardDoubleArrowUp';
import KeyboardDoubleArrowDownIcon from '@mui/icons-material/KeyboardDoubleArrowDown';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import history from 'modules/app/components/history'

const TabPanelDiv = (props: any) => {
    const { list_name } = props;
    const [ products, setProducts ] = useState<any>([])
    const [ content_items, setContentItems ] = useState<any>([])
    const [ addDialogOpen, setAddDialogOpen ] = useState<boolean>(false)
    const [ editDialogOpen, setEditDialogOpen ] = useState<boolean>(false)
    const [ editProductindex, setEditProductIndex ] = useState<number>(-1)
    const [ addItemDialogOpen, setAddItemDialogOpen ] = useState<boolean>(false)
    const [ editItemDialogOpen, setEditItemDialogOpen ] = useState<boolean>(false)
    const [ editItemTitleDialogOpen, setEditItemTitleDialogOpen ] = useState<boolean>(false)
    const [ delItemDialogOpen, setDelItemDialogOpen ] = useState<boolean>(false)
    const [ delCategoryDialogOpen, setDelCategoryDialogOpen ] = useState<boolean>(false)
    const [ moveCategoryDialogOpen, setMoveCategoryDialogOpen ] = useState<boolean>(false)
    const [ editItemIndex, setEditItemIndex ] = useState<number>(-1)
    const [ editItemTitleIndex, setEditItemTitleIndex ] = useState<number>(-1)
    const [ delItemIndex, setDelItemIndex ] = useState<number>(-1)
    const [ delCategoryIndex, setDelCategoryIndex ] = useState<number>(-1)
    const [ moveCategoryIndex, setMoveCategoryIndex ] = useState<number>(-1)
    const [ isLoading, setIsLoading ] = useState<boolean>(false)
    // const [ isFirstLoading, setIsFirstLoading ] = useState<boolean>(false)

    useEffect(() => {
        try {
            (async () => {
                props.setIsFirstLoading(true)

                const result:any = await get_products_with_family_name(list_name)
                if( result.status === 'success' ) {
                    let products = result.data.products
                    products = products.map((product:any, index:number) => {
                        product = Object.assign(product, {active: false})
                        return product
                    })
                    setProducts(products)
                    
                    props.setIsFirstLoading(false)
                } else if ( result.status === 'error' ) {
                    props.setIsFirstLoading(false)
                    
                    history.push('/admin/signin')
                }
                
            })()
        } catch ( e ) {
            // console.log(e)
        }
    }, [list_name])

    useEffect(() => {
        try {
            (async () => {
                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                if ( active_product_index !== -1 ) {
                    setContentItems(products[active_product_index].items)
                } else if ( active_product_index === -1 ) {
                    setContentItems([])
                }
            })()
        } catch ( e ) {
            // console.log(e)
        }
    }, [products])


    const clicked_category = (active: boolean, index: number, product_name: string, product_id: string) => {
        if ( active === true ) {

        } else if ( active === false ) {
            setProducts((prev: any[]) => {
                let next = [...prev]

                let prev_active_index = next.findIndex(ele => ele.active === true)

                if ( prev_active_index !== -1 ) {
                    next[prev_active_index].active = false
                }

                next[index].active = true
                
                return next
            })

            // setContentItems(products[index].items)
        }
    }

    const clicked_move_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()

        setMoveCategoryDialogOpen(true)
        setMoveCategoryIndex(index)
    }

    const clicked_edit_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()
        setEditDialogOpen(true)
        setEditProductIndex(index)
    }

    const clicked_delete_category = (e: any, index: number, product_name: string, product_id: string) => {
        e.stopPropagation()
        setDelCategoryIndex(index)
        setDelCategoryDialogOpen(true)
            
    }

    const clicked_row = (e:any, index:number, item:any) => {
        e.stopPropagation()

        setEditItemTitleDialogOpen(true)
        setEditItemTitleIndex(index)
    }

    const clicked_edit_item = (e:any, index:number, item: any) => {
        e.stopPropagation()

        setEditItemDialogOpen(true)
        setEditItemIndex(index)
    }

    const clicked_delete_item = (e:any, index:number, item: any) => {
        e.stopPropagation()
        setDelItemIndex(index)
        setDelItemDialogOpen(true)
    }

    const clicked_pos_up = (e:any, index:number, item: any) => {
        (async () => {
            e.stopPropagation()

            if ( content_items.length === 1 ) {
    
            } else if ( content_items.length > 1 ) {
                setIsLoading(true)

                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                let product_id = products[active_product_index]._id
                let update_result = await update_item_position(product_id, content_items, index, 'up')

                if ( update_result.status === "success" ) {
                    setIsLoading(false)
                    await new Promise(r => setTimeout(r, 300))
                    // setContentItems(update_result.update_result.items)
                    
                    setProducts((prev:any[]) => {
                        let next = [...prev]
                        next[active_product_index].items = update_result.update_result.items

                        return next
                    })
                } else if ( update_result.status === "error" ) {
                    setIsLoading(false)
                }
                // if ( index === 0 ) {
                //     setContentItems((prev: any[]) => {
                //         let next = [...prev]
                //         let temp_item = next[index]
    
                //         next.shift()
                //         next.push(temp_item)
    
                //         return next
                //     })
                // } else {
                //     setContentItems((prev: any[]) => {
                //         let next = [...prev]
                //         let temp_item = next[index]
    
                //         next.splice(index, 1)
                //         next.splice(index - 1, 0, temp_item)
    
                //         return next
                //     })
                // }
            }
        })()
    }

    const clicked_pos_down = (e:any, index:number, item: any) => {
        (async () => {
            e.stopPropagation()
       
            if ( content_items.length === 1 ) {
    
            } else if ( content_items.length > 1 ) {
                setIsLoading(true)

                let active_product_index = products.findIndex((ele:any) => ele.active === true)
                let product_id = products[active_product_index]._id
                let update_result = await update_item_position(product_id, content_items, index, 'down')

                if ( update_result.status === "success" ) {
                    setIsLoading(false)
                    await new Promise(r => setTimeout(r, 300))
                    // setContentItems(update_result.update_result.items)
                    
                    setProducts((prev:any[]) => {
                        let next = [...prev]
                        next[active_product_index].items = update_result.update_result.items

                        return next
                    })
                } else if ( update_result.status === "error" ) {
                    setIsLoading(false)
                }

            }
        })()
    }

    const add_category = () => {
        setAddDialogOpen(true)
    }

    const add_item = () => {
        setAddItemDialogOpen(true)
    }

    return (
        // isLoading === true ?
        // <>
        //     <Backdrop
        //         sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        //         open={isLoading}
        //         // onClick={handleClose}
        //     >
        //         <CircularProgress color="inherit"/>
        //         <p>&nbsp;&nbsp; Saving Data ...</p>
        //     </Backdrop>
        //     <CustomGrid container spacing={0}>
        //         <CustomGrid item xs={3} >
        //             <CustomDiv className={"add_category_div"}>
        //                 <CustomListItemButton className={"min-width-0 add_category_icon"} onClick={(e)=> add_category()}>
        //                     <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/>
        //                 </CustomListItemButton>
        //             </CustomDiv>
        //             <CustomDiv className={"list_div"}>
                        
        //                 {
        //                     products.length > 0 && products.map((product:any, index:number) => (
        //                         <CustomDiv key={index} className={"btn_div"}>
        //                             <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_category(product.active, index, product.product_name, product._id)}>
        //                                 <CustomDiv className={"txt_div"}>
        //                                     {product.product_name}
        //                                 </CustomDiv>                                        
        //                                 <CustomDiv className={"min-width-0 edit_icon"} onClick={(e:any)=> clicked_edit_category(e, index, product.product_name, product._id)}>
        //                                     <ModeEditOutlineIcon fontSize="small"/>
        //                                 </CustomDiv>
        //                                 <CustomDiv className={"min-width-0 edit_icon"} onClick={(e:any)=> clicked_move_category(e, index, product.product_name, product._id)}>
        //                                     <DriveFileMoveOutlinedIcon fontSize="small"/>
        //                                 </CustomDiv>
        //                                 <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_delete_category(e, index, product.product_name, product._id)}>
        //                                     <DeleteOutlineIcon fontSize="small" />
        //                                 </CustomDiv>
        //                             </CustomDiv>
        //                         </CustomDiv>
        //                     ))
        //                 }
        //             </CustomDiv>
        //         </CustomGrid>
        //         <CustomGrid item xs={9} >
        //             <CustomDiv className={"add_item_div"}>
        //                 <CustomListItemButton className={"min-width-0 add_item_icon"} onClick={(e)=> add_item()}>
        //                     {
        //                         products.findIndex((ele:any) => ele.active === true) === -1 ? <></>
        //                          : products.findIndex((ele:any) => ele.active === true) !== -1 ? <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/> 
        //                          : <></>
        //                     }
                            
        //                 </CustomListItemButton>
        //             </CustomDiv>
        //             <CustomDiv className={"content_div"}>
                        
        //                 <TableContainer component={Paper}>
        //                     <Table aria-label="simple table">
        //                         <TableBody>
        //                         {
        //                             content_items.length > 0 && content_items.map((item:any, index:any) => (
        //                                 <CustomTableRow
        //                                     hover
        //                                     key={index}
        //                                     className={"row_style"}
        //                                     onClick={(e:any) => {clicked_row(e, index, item)}}
        //                                 >
        //                                     <CustomTableCell component="th" scope="row">
        //                                         {/* {item.DetailPageURL.slice(0, item.DetailPageURL.indexOf('?') + 6) + "..."} */}
        //                                         {item.DetailPageURL.length > 0 && item.DetailPageURL.slice(0, 70) + "..."}
        //                                     </CustomTableCell>
        //                                     <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_edit_item(e, index, item)}} className={"edit_cell"}>{"edit"}</CustomListItemButton></CustomTableCell>
        //                                     <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_delete_item(e, index, item)}} className={"delete_cell"}>{"delete"}</CustomListItemButton></CustomTableCell>
        //                                     <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_up(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowUpIcon /></CustomListItemButton></CustomTableCell>
        //                                     <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_down(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowDownIcon /></CustomListItemButton></CustomTableCell>
        //                                 </CustomTableRow>
        //                             ))
        //                         }
        //                         </TableBody>
        //                     </Table>
        //                     </TableContainer>
        //             </CustomDiv>
        //         </CustomGrid>
        //     </CustomGrid>
        //     <AddCategoryDialog open={addDialogOpen} setOpen={setAddDialogOpen} family_name={list_name} products={products} setProducts={setProducts} />
        //     <EditCategoryDialog open={editDialogOpen} setOpen={setEditDialogOpen} edit_product_index={editProductindex} setEditProductIndex={setEditProductIndex} products={products} setProducts={setProducts} />
        //     <AddItemDialog open={addItemDialogOpen} setOpen={setAddItemDialogOpen} products={products} setProducts={setProducts} />
        //     <EditItemDialog open={editItemDialogOpen} setOpen={setEditItemDialogOpen} edit_item_index={editItemIndex} setEditItemIndex={setEditItemIndex} products={products} setProducts={setProducts} />
        //     <DelItemDialog open={delItemDialogOpen} setOpen={setDelItemDialogOpen} index={delItemIndex} setDelItemIndex={setDelItemIndex} products={products} setProducts={setProducts} />
        //     <DelCategoryDialog open={delCategoryDialogOpen} setOpen={setDelCategoryDialogOpen} index={delCategoryIndex} setDelCategoryIndex={setDelCategoryIndex} products={products} setProducts={setProducts} />
        // </>
        // :
        <>
            {/* {
                isFirstLoading === true ?
                <>
                    <Backdrop
                        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                        open={isFirstLoading}
                        // onClick={handleClose}
                    >
                        <CircularProgress color="inherit"/>
                        <p>&nbsp;&nbsp; Loading Data ...</p>
                    </Backdrop>
                </>
                :<></>
            } */}
            {
                isLoading === true ?
                 <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                >
                    <CircularProgress color="inherit"/>
                    <p>&nbsp;&nbsp; Saving Data ...</p>
                </Backdrop> :<></>
            }
            <CustomGrid container spacing={0}>
                <CustomGrid item xs={3} >
                    <CustomDiv className={"add_category_div"}>
                        <CustomListItemButton className={"min-width-0 add_category_icon"} onClick={(e)=> add_category()}>
                            <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/>
                        </CustomListItemButton>
                    </CustomDiv>
                    <CustomDiv className={"list_div"}>
                        
                        {
                            products.length > 0 && products.map((product:any, index:number) => (
                                <CustomDiv key={index} className={"btn_div"}>
                                    <CustomDiv className={product.active === true ? "active_list_item btn_style" : "btn_style"} onClick={() => clicked_category(product.active, index, product.product_name, product._id)}>
                                        <CustomDiv className={"txt_div"}>
                                            {product.product_name}
                                        </CustomDiv>                                        
                                        <CustomDiv className={"min-width-0 edit_icon"} onClick={(e:any)=> clicked_edit_category(e, index, product.product_name, product._id)}>
                                            <ModeEditOutlineIcon fontSize="small"/>
                                        </CustomDiv>
                                        <CustomDiv className={"min-width-0 move_icon"} onClick={(e:any)=> clicked_move_category(e, index, product.product_name, product._id)}>
                                            <DriveFileMoveOutlinedIcon fontSize="small"/>
                                        </CustomDiv>
                                        <CustomDiv className={"min-width-0 delete_icon"} onClick={(e:any) => clicked_delete_category(e, index, product.product_name, product._id)}>
                                            <DeleteOutlineIcon fontSize="small" />
                                        </CustomDiv>
                                        
                                    </CustomDiv>
                                </CustomDiv>
                            ))
                        }
                    </CustomDiv>
                </CustomGrid>
                <CustomGrid item xs={9} >
                    <CustomDiv className={"add_item_div"}>
                        <CustomListItemButton className={"min-width-0 add_item_icon"} onClick={(e)=> add_item()}>
                            {
                                products.findIndex((ele:any) => ele.active === true) === -1 ? <></>
                                 : products.findIndex((ele:any) => ele.active === true) !== -1 ? <PlaylistAddIcon sx={{'width': '32px', 'height': '32px'}}/> 
                                 : <></>
                            }
                            
                        </CustomListItemButton>
                    </CustomDiv>
                    <CustomDiv className={"content_div"}>
                        
                        <TableContainer component={Paper}>
                            <Table aria-label="simple table">
                                <TableBody>
                                {
                                    content_items.length > 0 && content_items.map((item:any, index:any) => (
                                        <CustomTableRow
                                            hover
                                            key={index}
                                            className={"row_style"}
                                            onClick={(e:any) => {clicked_row(e, index, item)}}
                                        >
                                            <CustomTableCell component="th" scope="row">
                                                {/* {item.DetailPageURL.slice(0, item.DetailPageURL.indexOf('?') + 6) + "..."} */}
                                                {item.DetailPageURL.length > 0 && item.DetailPageURL.slice(0, 70) + "..."}
                                            </CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_edit_item(e, index, item)}} className={"edit_cell"}>{"edit"}</CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_delete_item(e, index, item)}} className={"delete_cell"}>{"delete"}</CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_up(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowUpIcon /></CustomListItemButton></CustomTableCell>
                                            <CustomTableCell align="right"><CustomListItemButton onClick={(e:any) => {clicked_pos_down(e, index, item)}} className={"arrow"}><KeyboardDoubleArrowDownIcon /></CustomListItemButton></CustomTableCell>
                                        </CustomTableRow>
                                    ))
                                }
                                </TableBody>
                            </Table>
                            </TableContainer>
                    </CustomDiv>
                </CustomGrid>
            </CustomGrid>
            <AddCategoryDialog open={addDialogOpen} setOpen={setAddDialogOpen} family_name={list_name} products={products} setProducts={setProducts} />
            <EditCategoryDialog open={editDialogOpen} setOpen={setEditDialogOpen} edit_product_index={editProductindex} setEditProductIndex={setEditProductIndex} products={products} setProducts={setProducts} />
            <AddItemDialog open={addItemDialogOpen} setOpen={setAddItemDialogOpen} products={products} setProducts={setProducts} />
            <EditItemDialog open={editItemDialogOpen} setOpen={setEditItemDialogOpen} edit_item_index={editItemIndex} setEditItemIndex={setEditItemIndex} products={products} setProducts={setProducts} />
            
            <EditItemTitleDialog open={editItemTitleDialogOpen} setOpen={setEditItemTitleDialogOpen} edit_item_title_index={editItemTitleIndex} setEditItemTitleIndex={setEditItemTitleIndex} products={products} setProducts={setProducts} />
            
            <DelItemDialog open={delItemDialogOpen} setOpen={setDelItemDialogOpen} index={delItemIndex} setDelItemIndex={setDelItemIndex} products={products} setProducts={setProducts} />
            <DelCategoryDialog open={delCategoryDialogOpen} setOpen={setDelCategoryDialogOpen} index={delCategoryIndex} setDelCategoryIndex={setDelCategoryIndex} products={products} setProducts={setProducts} />
            <MoveCategoryDialog open={moveCategoryDialogOpen} setOpen={setMoveCategoryDialogOpen} move_list_array={props.move_list_array} index={moveCategoryIndex} setMoveCategoryIndex={setMoveCategoryIndex} products={products} setProducts={setProducts} />
        </>
    )
}

const CustomGrid = styled(Grid)`

`

const CustomTableRow = styled(TableRow)`
    &.row_style {
        cursor: pointer!important;
    }
`

const CustomTableCell = styled(TableCell)`
    
`

const CustomListItemButton = styled(ListItemButton)`
    &.active_list_item {
        background-color: #1e947e;
        color: white;

    }
    &.edit_cell {
        display: flex;
        justify-content: center;
    }
    &.delete_cell {
        display: flex;
        justify-content: center;
    }
    &.edit_cell:hover {
        color: #009fff;
        background-color: #0db9f51f;
    }
    &.delete_cell:hover {
        color: #ff6000;
        background-color: #ff60002e;
    }

    &.add_category_icon {
        display: flex;
        justify-content: center;
        color: #1e947e;
    }

    &.add_item_icon{
        display: flex;
        justify-content: center;
        color: #1e947e;
    }
    &.add_category_icon: hover {
        color: #1e947e;
    }

    &.add_item_icon: hover {
        color: #1e947e;
    }

    &.arrow {
        display: flex;
        justify-content: center;
    }
`

const CustomDiv = styled.div`
    &.add_category_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
    }

    &.add_item_div {
        display: flex;
        justify-content: center;
        padding: 0px 0px 5px 0px;
    }

    &.txt_div {
        flex: 1 1 auto!important;
    }

    &.list_div {
        height: 75vh;
        overflow: auto;
        border-right: 1px solid darkgray!important;
        padding-right: 5px!important;
        padding-top: 5px!important;
    }

    &.content_div {
        height: 75vh;
        overflow: auto;
        padding-left: 5px!important;
        padding-right: 5px!important;
        padding-top: 5px!important;
    }

    &.btn_style {
        display: flex;
        padding: 8px 16px;
        justify-content: space-between;
        align-items: center;
        cursor: pointer;
    }

    &.btn_div {
       margin: 2px 0px;
    }
    &.btn_div:hover {
       background-color: #00000022!important;
    }

    &.active_list_item {
        background-color: #1e947e;
        color: white;

    }

    &.min-width-0 {
        min-width: 0px!important;
        margin: 0 1px!important;
    }

    &.move_icon: hover {
        color: #fded0a!important
    }

    &.edit_icon: hover {
        color: #00ceff!important;
    }

    &.delete_icon: hover {
        color: #ff3100cf!important;
    }
`

export default TabPanelDiv