import { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useLocation, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBox from './components/ProductBox';
import { getVariation, checkAndAdd_userAndId } from 'modules/api/market-api'
import { setCurrentProductAsin, setCurrentGclidAndCurrentSearch } from 'logic/action/base.action'
import MobileDiv from './MobileDiv'
import history from 'modules/app/components/history'


const AsinProduct = (props: any) => {
    const dispatch = useDispatch()
    const selector = useSelector((state: any) => state)
    const location = useLocation()
    const { asin } = useParams<{ asin: string }>()
    const [ title, setTitle ] = useState<string>('')
    const [ itemBrand, setItemBrand ] = useState<string>('')
    const [ mainProduct, setMainProduct ] = useState<any>([])
    const [ variations, setVariations ] = useState<any>([])

    useEffect(() => {
        let search = location.search
        let gclid = location.search
        let msclkid = location.search
        
        if(gclid.indexOf('gclid=') === -1) {
            gclid = '0'
        } else {
            gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
            if(gclid.indexOf('&') !== -1){
                gclid = gclid.slice(0, gclid.indexOf('&'))
            }
        }
        
        if(msclkid.indexOf('msclkid=') === -1) {
            msclkid = '0'
        } else {
            msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
            if(msclkid.indexOf('&') !== -1){
                msclkid = msclkid.slice(0, msclkid.indexOf('&'))
            }
        }

        // dispatch(setCurrentGclidAndCurrentSearch(gclid, msclkid, search))

        localStorage.setItem('gclid', gclid)
        localStorage.setItem('msclkid', msclkid)
        localStorage.setItem('search', search)

        dispatch(setCurrentProductAsin(asin))
    }, [asin])

    useEffect(() => {
        
        if(selector.product.currentProductAsin !== undefined && selector.product.currentProductAsin !== '' ){
                // checkAndAdd_userAndId(selector.gclid.currentGclid, selector.gclid.currentMsclkid, selector.gclid.currentSearch)
                // checkAndAdd_userAndId(gclid, msclkid, search)
                checkAndAdd_userAndId()
                .then(result1 => {
                    getVariation(selector.product.currentProductAsin)
                        .then(result => {
                            if(result.success === true) {
                                let main_product = result.main_data
                                main_product = main_product.map((item: any, index: number) => {
                                    item.DetailPageURL = item.DetailPageURL.replace('?tag=revisions05-20', '?tag=' + localStorage.getItem('amazon_tracking_id'))
                                    return item
                                })
                                setMainProduct(main_product)
                                if(result.main_data[0].ItemInfo.ByLineInfo != null && result.main_data[0].ItemInfo.ByLineInfo.Brand != null){
                                    setItemBrand(result.main_data[0].ItemInfo.ByLineInfo.Brand.DisplayValue)
                                }

                                let titleStr = result.main_data[0].ItemInfo.Title.DisplayValue.split(' ').slice(0, 10).join(' ')
                               
                                setTitle(titleStr)

                                let variation_data = result.variation_data
                                variation_data = variation_data.map((item: any, index: number) => {
                                    item.DetailPageURL = item.DetailPageURL.replace('?tag=revisions05-20', '?tag=' + localStorage.getItem('amazon_tracking_id'))
                                    return item
                                })
                                setVariations(variation_data.filter((ele: any) => ele.ASIN !== result.main_data[0].ASIN))
                            } else if(result.success === false) {
                            }
                        })
                        .catch(error => {
                            // console.log(error)
                        })
                })
            
        }
    }, [selector.product.currentProductAsin])

    const goStartPage = () => {
        history.push(`/`)
    }


    return (
        <>
            <CustomWrapperItems>
                <CustomGrid container spacing={0} >
                    <CustomGrid item container spacing={0} md={12} className="grid-header-left grid-flex-item-start">
                        <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                            <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                <ShortSpan onClick={goStartPage}>
                                    <HomeIcon  />
                                </ShortSpan>
                                {
                                    itemBrand !== '' ?
                                    <>
                                        <ChevronRightIcon />
                                        <ShortSpan className="lastSpan">
                                            {itemBrand}
                                        </ShortSpan>
                                    </> :
                                    ''
                                }
                               
                            </CustomGrid>
                        </CustomGrid>
                        <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                            <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                                {/* <CategoryTitle>Best {product} of 2022</CategoryTitle> */}
                                <CategoryTitle>{title}</CategoryTitle>
                            </CustomGrid>
                        </CustomGrid>
                        <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                            <CustomGrid item sm={12} className="margin-padding-0_v-center">
                                <UpdatedSpan>Last Updated September 2023</UpdatedSpan>
                            </CustomGrid>
                        </CustomGrid>
                    </CustomGrid>
                </CustomGrid> 
                <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} />
                <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '20px!important'}}>
                    <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                        {
                            mainProduct.length === 0 ? 
                            'No Product' : 
                            <>
                                <ProductBox item={mainProduct[0]} />
                            </>
                        }
                        {   variations.length !== 0 ?
                            <>
                                <RelatedTitle>
                                    Related Products
                                </RelatedTitle>
                                {
                                    variations.length > 0 && variations.map((variation: any, index: number)=> {
                                        return <ProductBox key={index} item={variation} />
                                    })
                                }
                            </>
                            : ''
                        }
                    </CustomGrid>
                </CustomGrid>
            </CustomWrapperItems> 

            <MobileDiv mainProduct={mainProduct} items={variations} itemBrand={itemBrand} title={title} />
        </>                                                              
    );
};

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (max-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`



const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 8px 20px;
  margin: 0px!important;

  img {
    width: 192px;
    height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
        color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 30px;
`
const UpdatedSpan = styled.span`
    font-family: system-ui;
    color: gray!important;
    font-size: 18px;
`
const RelatedTitle = styled.span`
    background-color: white!important;
    font-family: sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 20px;   
    margin-top: 20px; 
`
export default AsinProduct