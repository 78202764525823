import { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { useLocation } from 'react-router-dom'
import styled1 from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'


const AdminHeader = (props: any) => {
  const location = useLocation();
  const [ current_url, setCurrentUrl ] = useState('')
  
  useEffect(() => {
    setCurrentUrl(location.pathname)
  }, [location])

  const goStartPage = () => {
      history.push(`/`)
  } 

  const goto_link = ( e:any, link_name:string) => {
    e.stopPropagation()

    if ( link_name === 'data' ) {
      history.push(`/admin/data`)
    } else if ( link_name === 'product' ) {
      history.push(`/admin/product`)
    } else if ( link_name === 'quiz' ) {
      history.push(`/admin/quiz`)
    } else if ( link_name === 'quiz_analyze' ) {
      history.push(`/admin/quiz-analyze`)
    } else if ( link_name === 'quiz_analyze_users' ) {
      history.push(`/admin/quiz-analyze-users`)
    } 
  }

  return (
    <>
      <HeaderContainer>
        <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
          <CustomGrid item xs={2} className="grid-header-left header_logo" onClick={goStartPage} >
            <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
            &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
          </CustomGrid>
          <CustomGrid item xs={10} className="grid-flex-item-end grid-header-right" >
            <CustomDiv className={"nav_link"} onClick={(e:any) => {goto_link(e, 'product')}}><span className={"nav_txt"} style={current_url === '/admin/product' ? {color: '#2563eb'} : {}}>Products</span></CustomDiv>
            <CustomDiv className={"nav_link"} onClick={(e:any) => {goto_link(e, 'data')}}><span className={"nav_txt"}  style={current_url === '/admin/data' ? {color: '#2563eb'} : {}}>Data</span></CustomDiv>
            <CustomDiv className={"nav_link"} onClick={(e:any) => {goto_link(e, 'quiz')}}><span className={"nav_txt"} style={current_url === '/admin/quiz' ? {color: '#2563eb'} : {}}>Quiz</span></CustomDiv>
            <CustomDiv className={"nav_link"} onClick={(e:any) => {goto_link(e, 'quiz_analyze')}}><span className={"nav_txt"} style={current_url === '/admin/quiz-analyze' ? {color: '#2563eb'} : {}}>Quiz Analyze</span></CustomDiv>
            <CustomDiv className={"nav_link"} onClick={(e:any) => {goto_link(e, 'quiz_analyze_users')}}><span className={"nav_txt"} style={current_url === '/admin/quiz-analyze-users' ? {color: '#2563eb'} : {}}>Quiz Analyze Users</span></CustomDiv>
          </CustomGrid>
        </CustomGrid>
        <Divider />
      </HeaderContainer>
    </>
    

  )
}

const HeaderContainer = styled1.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
const BoldSpan = styled1.span`
  font-weight: 700;
`

const CustomGrid = styled1(Grid)`
  background-color: white!important;
  padding: 4px 10px;
  margin: 0px!important;
  
  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }
`

const CustomDiv = styled1.div`
  &.nav_link {
    display: flex;
    justify-content: center;
    width: 200px;
    transition: 0.7s;
    text-decoration: none;
    padding: 0px 15px;
    border: 1px solid transparent;
    border-radius: 10px;
    margin: 0 10px;
    cursor: pointer;
  }

  &.nav_link:hover {
    border-color: #5989f161;
    box-shadow: 0 2px 5px 0 rgb(15 122 233 / 25%), 0 3px 10px 5px rgb(13 115 245 / 12%) !important;
    .nav_txt {
      color: #2563eb;
      letter-spacing: 1px;
    }
  }

  &.nav_link .nav_txt{
    color: #0e0e0e82;
    font-size: 20px;
    font-weight: 500;
    transition: 0.5s;
  }
`

export default withRouter(AdminHeader)
