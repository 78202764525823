import styled from 'styled-components'
import { colors, screenSizes } from 'shared/styles/theme'


///////////////////////////////////////////////////////////////////////////////////////
export const CardBText = styled.span`
  font-size: 15px;
  line-height: 24px;
  color: #2e3635;
  font-family: 'Montserrat',sans-serif;
  margin: 0;
  text-align: center;
`


 const PCard = styled.div`
  background: ${colors.white};
  box-shadow: 0px 3px 10px #00000033;
  border: 1px solid ${colors.borderColor};
  border-radius: 10px;
  box-sizing: border-box;
  @supports (-moz-appearance:none) {
    background: ${colors.white};
  }
`

export const ActionCard = styled(PCard)`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding: 40px 30px 30px 30px;
  margin: 0 10px;
  // cursor: pointer;
  width: auto;
  height: 105px;
`
export const ImageAndText = styled.div`
  font-family: GT Pressura,sans-serif;

  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    width: 40px!important;
    height: 40px!important;
    margin-right: 5px;
  }
`

export const CardSubText = styled.p`
  font-size: 20px;
  line-height: 20px;
  font-weight: 800;
  color: #1e947e;
  // color: #06bbf1;
  font-family: 'Montserrat', sans-serif;
 margin: 0;
`



///////////////////////////////////////////////////////////////////////////////////////

