import { createGlobalStyle } from 'styled-components'
import GTPressuraProBold from 'assets/fonts/GT-Pressura-Pro-Bold.ttf'
import Inter from 'assets/fonts/Inter.ttf'

export interface Colors {
  themeBackground: string
  white: string
  background: string
  transBackground: string
  hoverRowBg: string
  hoverSwitchBg: string
  borderColor: string
  secondaryBorder: string
  yellow: string
  yellowTransBg: string
  yellowTransHover: string
  lightGreen: string
  green: string
  greenBackground: string
  darkGreenBackground: string
  darkGreen: string
  red: string
  redTransBg: string
  redTransHover: string
  redBackground: string
  darkRedBackground: string
  blue: string
  lightBlue: string
  blueTransBg: string
  blueTransHover: string
  blueBackground: string
  darkBlueBackground: string
  grey: string
  darkGrey: string
  black: string
  inputBg: string
  purple: string
}

export const colors: Colors = {
  themeBackground: '#000000',
  background: '#16102F',
  transBackground: 'rgba(22,16,47, 0.1)',
  white: '#FFFFFF',
  borderColor: '#FFFFFF26',
  blue: '#3037DA',
  lightBlue: '#55B7FF',
  blueTransBg: '#55B7FF33',
  blueTransHover: 'rgba(85, 183, 255, 0.3)',
  lightGreen: '#55FF76',
  green: '#27AC40',
  hoverRowBg: '#271258',
  hoverSwitchBg: '#1C1731',
  secondaryBorder: 'rgba(112, 112, 112, 0.15)',
  yellow: '#FFE555',
  yellowTransBg: 'rgba(255,229,85, 0.2)',
  yellowTransHover: 'rgba(255,229,85, 0.3)',
  greenBackground: '#2A414C',
  darkGreenBackground: '#031923',
  darkGreen: '#73BB59',
  red: '#FF5555',
  redTransBg: 'rgba(255,85,85, 0.2)',
  redTransHover: 'rgba(255,85,85, 0.3)',
  redBackground: '#FF555533',/*'#4C2143',*/
  darkRedBackground: '#35032b',
  blueBackground: '#233054',
  darkBlueBackground: '#0f2152',
  grey: '#00000033',
  darkGrey: '#707070',
  black: '#000000',
  inputBg: '#080808',
  purple: '#9D55FF'
}

export interface ThemeStarter {
  [propName: string]: string | undefined
  themeBackground: string
  white: string
  background: string
  transBackground: string
  hoverRowBg: string
  hoverSwitchBg: string
  borderColor: string
  secondaryBorder: string
  yellow: string
  yellowTransBg: string
  lightGreen: string
  green: string
  greenBackground: string
  darkGreenBackground: string
  darkGreen: string
  red: string
  redTransBg: string
  redBackground: string
  darkRedBackground: string
  blue: string
  lightBlue: string
  blueTransBg: string
  blueBackground: string
  darkBlueBackground: string
  grey: string
  darkGrey: string
  black: string
  inputBg: string
  purple: string
}

export const theme: ThemeStarter = {
  themeBackground: colors.themeBackground,
  white: colors.white,
  background: colors.background,
  transBackground: colors.transBackground,
  hoverRowBg: colors.hoverRowBg,
  hoverSwitchBg: colors.hoverSwitchBg,
  borderColor: colors.borderColor,
  secondaryBorder: colors.secondaryBorder,
  yellow: colors.yellow,
  yellowTransBg: colors.yellowTransBg,
  lightGreen: colors.lightGreen,
  green: colors.green,
  greenBackground: colors.greenBackground,
  darkGreenBackground: colors.darkGreenBackground,
  darkGreen: colors.darkGreen,
  red: colors.red,
  redTransBg: colors.redTransBg,
  redBackground: colors.redBackground,
  darkRedBackground: colors.darkRedBackground,
  blue: colors.blue,
  lightBlue: colors.lightBlue,
  blueTransBg: colors.blueTransBg,
  blueBackground: colors.blueBackground,
  darkBlueBackground: colors.darkBlueBackground,
  grey: colors.grey,
  darkGrey: colors.darkGrey,
  black: colors.black,
  inputBg: colors.inputBg,
  purple: colors.purple
}

export interface ScreenSizes {
  mediaXS: number
  mediaS: number
  mediaM: number
  mediaL: number
  mediaXL: number
  mediaXLL: number
  mediaXXL: number
  mediaXXXX: number
}
export const screenSizes: ScreenSizes = {
  mediaXS: 360,
  mediaS: 640,
  mediaM: 800,
  mediaL: 1024,
  mediaXL: 1280,
  mediaXLL: 1440,
  mediaXXL: 1600,
  mediaXXXX: 1800,
}

export const GlobalStyle = createGlobalStyle`
  @font-face {
    font-family: 'Inter';
    src:url(${Inter}) format('truetype');
    font-display: swap;
  }
  @font-face {
    font-family: GT Pressura,sans-serif;
    src:url(${GTPressuraProBold}) format('truetype');
    font-display: swap;
  }
  
  body {
    background: #efefef !important;
    .css-1poimk-MuiPaper-root-MuiMenu-paper-MuiPaper-root-MuiPopover-paper {
      box-shadow: 0px 0px 0px 0px rgb(0 0 0 / 12%),0px 0px 0px 0px rgb(35 24 62 / 14%),3px 3px 2px 3px rgb(0 10 0 / 12%)!important;
    }
    
    .css-kk1bwy-MuiButtonBase-root-MuiMenuItem-root {
      color: gray;
      margin: 3px 3px;
      border: 1px solid white;
      border-radius: 3px;
      &:hover{
        background: transparent;
        border: 1px solid #1e947e;
        border-radius: 3px;
      }
    }
    .md-button {    
      text-transform: none!important 
    }
  }
`