import { useState } from 'react'
import styled from 'styled-components'
import { Grid, Divider } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import HomeIcon from '@mui/icons-material/Home';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ProductBoxMobile from './components/ProductBoxMobile';
import history from 'modules/app/components/history'
import TrustBoxMobile from './components/TrustBoxMobile'
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import Box from '@mui/material/Box';
import OverviewBoxMobile from './components/OverviewBoxMobile'
import BuyersGuideBoxMobile from './components/BuyersGuideBoxMobile'

const MobileDiv = (props: any) => {
    const { items, title, product, overview, buyer_guide, buyer_guide_title, review_number, evaluated_number } = props

    const [value, setValue] = useState<string>("t1");

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <CustomWrapperItems>
          
            <CustomGrid container spacing={0} >
                <CustomGrid item container spacing={0} md={12} className="grid-header-left grid-flex-item-start">
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item sm={12} className="margin-padding-0_v-center">
                            <ShortSpan onClick={goStartPage}>
                                <HomeIcon  />
                            </ShortSpan>
                            <CustomChevronRightIcon />
                            <ShortSpan>
                                {title}
                            </ShortSpan>
                            <CustomChevronRightIcon />
                            <ShortSpan className="lastSpan">
                                {product}
                            </ShortSpan>
                        </CustomGrid>
                    </CustomGrid>
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item  sm={12} className="margin-padding-0_v-center">
                            <CategoryTitle>Best {product} of 2023</CategoryTitle>
                        </CustomGrid>
                    </CustomGrid>
                    <CustomGrid item container spacing={0} className="margin-padding-0_v-center">
                        <CustomGrid item sm={12} className="margin-padding-0_v-center">
                            <UpdatedSpan>Last Updated September 2023</UpdatedSpan>
                        </CustomGrid>
                    </CustomGrid>
                </CustomGrid>
                <CustomGrid item md={12} className="grid-header-right grid-flex-item-start">
                    <div>
                        <ImageAndTextM>
                            <img src={require('assets/nstar1.svg').default} alt="" width="25" height="25" />
                            {/* <img src={require('assets/star1.svg').default} alt="" /> */}
                            {/* <CardSubTextM>889</CardSubTextM> */}
                            <CardSubTextM>{evaluated_number}</CardSubTextM>
                        </ImageAndTextM>
                    </div>
                    <div className="imageTxt">Products</div>
                    <div>
                        <ImageAndTextM>
                            <img src={require('assets/nstar2.svg').default} alt="" width="25" height="25" />
                            {/* <img src={require('assets/star2.svg').default} alt="" /> */}
                            {/* <CardSubTextM>3189</CardSubTextM> */}
                            <CardSubTextM>{review_number}</CardSubTextM>
                        </ImageAndTextM>
                    </div>
                    <div className="imageTxt">Reviews</div>
                </CustomGrid>
            </CustomGrid> 
            <Divider sx={{ boxShadow: '0px 1px 2px #0a0909'}} />
            {/* <Box sx={{ width: '100%', typography: 'body1' }}> */}
                <TabContext value={value}>
                    <CustomBox sx={{ borderBottom: 1, borderColor: 'divider' }} className={"tab_box"}>
                      <TabList onChange={handleTabChange} aria-label="lab API tabs example" >
                          <CustomTab label="Overview" value="t2" className='lowcasetab' />
                          <CustomTab label="Rankings" value="t1" className='lowcasetab' />
                      </TabList>
                    </CustomBox>
                    {/* <TabPanel value="1">Item One</TabPanel> */}
                    <TabPanel value="t2" sx={{padding: "0"}}>
                      <OverviewBoxMobile overview={overview} />
                      <BuyersGuideBoxMobile buyer_guide={buyer_guide} product={product} buyer_guide_title={buyer_guide_title} />
                    </TabPanel>
                    <TabPanel value="t1" sx={{padding: "0"}}>
                      <CustomGrid container spacing={0} sx={{background: 'transparent!important', marginTop: '5px!important'}}>
                        <CustomGrid item container spacing={0} sm={12} className="grid-header-left grid-header-right grid-flex-item-start" sx={{background: 'transparent!important'}} >
                            {   items.length !== 0 ?
                                items.length > 0 && items.map((item: any, index: number)=> {
                                  if( index < 3 ) {
                                    return <ProductBoxMobile key={index} item={item} num={index + 1}/>
                                  } else {
                                    return <div key={index}></div>
                                  }
                                }) : ''
                            }
                            { <TrustBoxMobile /> }
                            {   items.length !== 0 ?
                                items.length > 0 && items.map((item: any, index: number)=> {
                                  if( index >= 3 ) {
                                    return <ProductBoxMobile key={index} item={item} num={index + 1}/>
                                  } else {
                                    return <div key={index}></div>
                                  }
                                }) : ''
                            }
                        </CustomGrid>
                      </CustomGrid>
                    </TabPanel>
                </TabContext>
            {/* </Box> */}
            

        </CustomWrapperItems> 
    );
};

const CustomBox = styled(Box)`
  &.tab_box {
    span.css-1aquho2-MuiTabs-indicator {
      background-color: #1e947e!important;
    }
    span.css-ttwr4n {
      background-color: #1e947e!important;
    }
  }
`

const CustomChevronRightIcon = styled(ChevronRightIcon)`
    margin: 0 5px;
`

const ImageAndTextM = styled.div`
  display: flex;
  align-items: center;
  // margin-bottom: 5px;
  img {
    // width: 25px!important;
    // height: 25px!important;
    margin-right: 5px;
  }
`

const CardSubTextM = styled.p`
    font-size: 15px;
    line-height: 15px;
    font-weight: 600;
    color: #187664;
    // color: #06bbf1;
    // font-family: 'Montserrat',sans-serif;
    font-family: GT Pressura, sans-serif;
    margin: 0;
`

const CustomWrapperItems = styled.div`
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: start;
    @media (min-width: ${screenSizes.mediaM}px) {
        display: none;
    }
`

const CustomGrid = styled(Grid)`
  background-color: white!important;

  padding: 2px 10px;
  margin: 0px!important;

  img {
    // width: 192px;
    // height: 48px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-left: 105px!important;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-left: 155px!important;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-left: 185px!important;
    }
  }

  .grid-header-right {
    align-items: center;
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }
  .margin-padding-0_v-center {
    display: flex;
    align-items: center;
    margin: 2px 0!important;
    padding: 0!important;
  }
 
  .imageTxt {
      margin: 0 10px 0 5px;
      color: gray!important;
  }
`
const ShortSpan = styled.span`
    cursor: pointer!important;
    font-family: sans-serif;
    color: gray;
    &:hover {
        color: black;
    }
    &.lastSpan {
        color: #187664!important;
        // color: #37a4f1!important;
    }
`
const CategoryTitle = styled.span`
    font-family: GT Pressura,sans-serif;
    color: black!important;
    font-weight: 600;
    font-size: 20px;
`
const UpdatedSpan = styled.span`
    font-family: system-ui;
    color: gray!important;
    font-size: 15px;
`
const CustomTab = styled(Tab)`
    &.lowcasetab {
      text-transform: none!important;
      width: 50%!important;
        
    } 
    &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
      color: #1e947e!important;
    }
    &.css-1q2h7u5.Mui-selected {
      color: #1e947e!important;
    }
`
export default MobileDiv