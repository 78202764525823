import { useState, useEffect } from 'react'
import styled from 'styled-components'
import jwt_decode from 'jwt-decode'
import { Grid, Divider, Box, Tab,  } from '@mui/material'
import { screenSizes } from 'shared/styles/theme'
import history from 'modules/app/components/history'
// import TabContext from '@mui/lab/TabContext';
// import TabList from '@mui/lab/TabList';
// import TabPanel from '@mui/lab/TabPanel';
import TabPanelDiv from './sub_components/TabPanelDiv'
// import { get_menu_data } from 'modules/api/market-api'
import AdminHeader from 'shared/AdminHeader'

const Admin_Quiz = (props: any) => {
    // const [ menu_data, setMenuData ] = useState<any>([])

    const [value, setValue] = useState<string>('0');

    const handleTabChange = (event: React.SyntheticEvent, newValue: string) => {
      setValue(newValue);
    };

    useEffect(() => {
      (async () => {
        if ( localStorage.getItem('jwtToken') != null && localStorage.getItem('userDbId') != null ) {
            const decoded:any = jwt_decode(JSON.stringify(localStorage.getItem('jwtToken')))
            if ( decoded.id == localStorage.getItem('userDbId') ) {
              // let menu_data_result = await get_menu_data()
              // setMenuData(menu_data_result.menu_data)
            } else {
                history.push(`/admin/signin`)
            }
        } else {
            history.push(`/admin/signin`)
        }
      })()
        
    }, [])

    const goStartPage = () => {
        history.push(`/`)
    }

    return (
        <>
          <AdminHeader />
          {/* <HeaderContainer>
            <CustomGrid container spacing={0} sx={{paddingTop: '10px'}}>
              <CustomGrid item xs={4} className="grid-header-left header_logo" onClick={goStartPage} >
                <img src={require('assets/logo_avatar3.png').default} style={{width: '52px!important'}} alt="" />
                &nbsp;<span><BoldSpan>T</BoldSpan>op<BoldSpan>B</BoldSpan>est<BoldSpan>S</BoldSpan>ellers</span>
              </CustomGrid>
              <CustomGrid item xs={8} className="grid-flex-item-end grid-header-right" >
                
              </CustomGrid>
            </CustomGrid>
            <Divider />
          </HeaderContainer> */}
          <CustomGrid container spacing={0} className={"pt-0"}>
            <CustomGrid item xs={12}  className={"pt-0"}>
              <Box sx={{ typography: 'body1' }}>
                <TabPanelDiv list_name={""} />
              </Box>
            </CustomGrid>
          </CustomGrid>
        </>
    );
};

// const CustomBox = styled(Box)`
//   &.tab_box {
//     span.css-1aquho2-MuiTabs-indicator {
//       background-color: #1e947e!important;
//     }
//     span.css-ttwr4n {
//       background-color: #1e947e!important;
//     }
//   }
// `

// const CustomTab = styled(Tab)`
//     &.lowcasetab {
//       text-transform: none!important;
//     } 
//     &.css-1h9z7r5-MuiButtonBase-root-MuiTab-root.Mui-selected {
//       color: #1e947e!important;
//     }
//     &.css-1q2h7u5.Mui-selected {
//       color: #1e947e!important;
//     }
// `

const CustomGrid = styled(Grid)`
  background-color: white!important;
  padding: 4px 10px;
  margin: 0px!important;

  .header_logo{
    display: flex;
    align-items: center;
    font-size: 18px;
    cursor: pointer;
  }

  img {
    width: 25px;
    height: 25px;
  }

  .grid-flex-item-end {
    display: flex;
    justify-content: end;
  }

  .grid-flex-item-start {
    display: flex;
    justify-content: start;
  }

  .grid-header-left {
   
  }

  .grid-header-right {
    @media (min-width: ${screenSizes.mediaXLL}px) {
      padding-right: 105px;
    }
    @media (min-width: ${screenSizes.mediaXXL}px) {
      padding-right: 155px;
    }
    @media (min-width: ${screenSizes.mediaXXXX}px) {
      padding-right: 185px;
    }
  }

  .custom-paper {
    @media (min-width: ${screenSizes.mediaM}px) {
      width: 300px;
    }
    @media (min-width: ${screenSizes.mediaXL}px) {
      width: 355px;
    }
  }

  .pt-0 {
    padding-top: 0px!important;
  }
`


const HeaderContainer = styled.div`
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
`
const BoldSpan = styled.span`
  font-weight: 700;
`
export default Admin_Quiz