/* global chrome */

import * as React from 'react'
import { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Grid, Button } from '@mui/material'
import CheckIcon from '@mui/icons-material/Check'
import { screenSizes } from 'shared/styles/theme'
import './quiz.css'
import LinearProgress from '@mui/material/LinearProgress'
import { useLocation, useParams } from 'react-router-dom'
import {
  read_quiz_with_quiz_url,
  generate_result,
  checkAndAdd_userAndId,
  update_model_of_user_info_on_quiz,
  update_text_link_of_user_info_on_quiz,
  update_image_link_of_user_info_on_quiz,
  record_each_question_answers,
  record_user_initially,
  record_best_url_visited_user,
} from 'modules/api/market-api'
import Mobile_Quiz from './Mobile_Quiz'
import CircularProgress, { CircularProgressProps } from '@mui/material/CircularProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'
import Backdrop from '@mui/material/Backdrop'
import { recordUserAndIds } from 'modules/api/market-api'

export default function Quiz() {
  const location = useLocation()

  const { quiz_url } = useParams<{ quiz_url: string }>()

  const [quiz, setQuiz] = useState<any>('')
  const [page_status, setPageStatus] = useState<string>('')
  const [activeQuestion, setActiveQuestion] = useState<number>(0)
  const [showResult, setShowResult] = useState(false)
  const [selectedAnswerIndex, setSelectedAnswerIndex] = useState<any>([])
  const [result, setResult] = useState<any>([])
  const [visible, setVisible] = useState<boolean>(false)
  const [progress, setProgress] = useState<number>(0)

  const [quiz_id, setQuizId] = useState<string>('')
  const [image_url, setImageUrl] = useState<string>('')
  const [product_url, setProductUrl] = useState<string>('')
  const [model_obj, setModelObj] = useState<any>(undefined)
  const [questions, setQuestions] = useState<any>([])
  const [question, setQuestion] = useState<any>('')
  const [quiz_img_url, setQuizImgUrl] = useState<any>('')
  const [choices, setChoices] = useState<any>([])
  const [model_name, setModelName] = useState<string>('')
  const [iframe_link, setIframLink] = React.useState<string>('')
  const [description, setDescription] = useState<string>('')
  const [models, setModels] = useState<any>([])
  const [analyze_result, setAnalyzeResult] = useState<string>('')
  const [str_arr, setStrArr] = useState<any>([])
  const [comp_arr, setCompArr] = useState<any>([])
  const [isLoading, setIsLoading] = useState<boolean>(false)
  const [isCircleLoading, setIsCircleLoading] = useState<boolean>(false)

  const [circleprogress, setCircleProgress] = React.useState(10)
  const [product_name, setProductName] = React.useState('')

  useEffect(() => {
    ;(async () => {
      await new Promise((r) => setTimeout(r, 500))

      setVisible(true)
    })()
  }, [])

  useEffect(() => {
    ;(async () => {
      setIsLoading(true)

      let link = window.location.href
      let search = location.search
      let gclid = location.search
      let msclkid = location.search

      if (gclid.indexOf('gclid=') === -1) {
        gclid = '0'
      } else {
        gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
        if (gclid.indexOf('&') !== -1) {
          gclid = gclid.slice(0, gclid.indexOf('&'))
        }
      }

      if (msclkid.indexOf('msclkid=') === -1) {
        msclkid = '0'
      } else {
        msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
        if (msclkid.indexOf('&') !== -1) {
          msclkid = msclkid.slice(0, msclkid.indexOf('&'))
        }
      }

      localStorage.setItem('gclid', gclid)
      localStorage.setItem('msclkid', msclkid)
      localStorage.setItem('search', search)
      localStorage.setItem('link', link)

      await checkAndAdd_userAndId()

      const read_quiz_result = await read_quiz_with_quiz_url(quiz_url)
      const quiz = read_quiz_result.result

      // console.log(quiz)

      setQuiz(quiz)

      if (quiz.best_url?.best_url === '' || quiz.best_url?.best_url === undefined || quiz.best_url?.best_url?.trim() === '') {
        setPageStatus('a')
      } else {
        setPageStatus('b')
      }

      setIsLoading(false)
    })()
  }, [quiz_url])

  useEffect(() => {
    ;(async () => {
      if (page_status === 'a') {
        setIsLoading(true)

        // let link = window.location.href
        // let search = location.search
        // let gclid = location.search
        // let msclkid = location.search

        // if(gclid.indexOf('gclid=') === -1) {
        //     gclid = '0'
        // } else {
        //   gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
        //   if(gclid.indexOf('&') !== -1){
        //       gclid = gclid.slice(0, gclid.indexOf('&'))
        //   }
        // }

        // if(msclkid.indexOf('msclkid=') === -1) {
        //     msclkid = '0'
        // } else {
        //   msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
        //   if(msclkid.indexOf('&') !== -1){
        //       msclkid = msclkid.slice(0, msclkid.indexOf('&'))
        //   }
        // }

        // localStorage.setItem('gclid', gclid)
        // localStorage.setItem('msclkid', msclkid)
        // localStorage.setItem('search', search)
        // localStorage.setItem('link', link)

        // await checkAndAdd_userAndId()

        // const read_quiz_result = await read_quiz_with_quiz_url(quiz_url)
        // const quiz = read_quiz_result.result

        // console.log(' here: first record of the user ... ')

        await record_user_initially(quiz._id)

        setQuizId(quiz._id)
        setQuestions(quiz.quiz)

        ///////////////////////////////////// cache images //////////////////////////////////////////////////
        let quiz_images_arr = quiz.quiz.map((ele: any, index: number) => {
          return ele.image_url
        })

        quiz_images_arr = quiz_images_arr.filter((ele: any) => ele !== undefined && ele.trim() !== '')

        cacheImages(quiz_images_arr)

        await new Promise((r) => setTimeout(r, 1000))
        //////////////////////////////////// cache images ///////////////////////////////////////////////////

        setProductName(quiz.product_name)
        setDescription(quiz.models[quiz.selected_model_index].description)

        let temp_models = quiz.models

        temp_models = temp_models.map((item: any, index: number) => {
          if (item.product_url.indexOf('tag=') !== -1) {
            let temp_str = item.product_url.substring(item.product_url.indexOf('tag='))
            let length = temp_str.indexOf('&')
            let temp_str1

            if (length === -1) {
              temp_str1 = item.product_url.substring(item.product_url.indexOf('tag='))
            } else if (length !== -1) {
              temp_str1 = item.product_url.substr(item.product_url.indexOf('tag='), length)
            }

            item.product_url = item.product_url.replace(temp_str1, 'tag=' + localStorage.getItem('amazon_tracking_id'))
          } else if (item.product_url.indexOf('tag=') === -1) {
            if (item.product_url.indexOf('?') === -1) {
              item.product_url = item.product_url + '?tag=' + localStorage.getItem('amazon_tracking_id')
            } else {
              item.product_url = item.product_url + '&tag=' + localStorage.getItem('amazon_tracking_id')
            }
          }

          return item
        })

        setModels(temp_models)
        setIsLoading(false)
      } else if (page_status === 'b') {
      }
    })()
  }, [page_status])
  // }, [quiz_url])

  useEffect(() => {
    if (questions.length > 0) {
      setQuestion(questions[activeQuestion].question)
      setChoices(questions[activeQuestion].choices)
      setQuizImgUrl(questions[activeQuestion].image_url)
    }
  }, [questions, activeQuestion])

  useEffect(() => {
    ;(async () => {
      if (showResult === true) {
        setIsLoading(true)
        setIsCircleLoading(true)

        const timer = setInterval(() => {
          setCircleProgress((prevProgress: number) => {
            if (prevProgress === 100) {
              ;(async () => {
                clearInterval(timer)

                await new Promise((r) => setTimeout(r, 2000))
                setIsCircleLoading(false)
                return prevProgress
              })()
              return prevProgress
            } else {
              let next = prevProgress + 1
              return next
            }
          })
        }, 60)

        let question_answer_str = ''

        question_answer_str = question_answer_str + '"\n'
        for (let i = 0; i < result.length; i++) {
          question_answer_str += 'question: '
          question_answer_str += result[i].question
          question_answer_str += '\n'
          question_answer_str += 'answer: '
          question_answer_str += result[i].answer
          question_answer_str += '\n'
        }
        question_answer_str = question_answer_str + '"'

        const result_of_choice = await generate_result(question_answer_str, model_name, description, quiz_id, result)

        await update_model_of_user_info_on_quiz(quiz_id, result_of_choice.model_name)

        let result_str = result_of_choice.result_str

        result_str = result_str.trim()
        if (result_str[0] === '"' || result_str[0] === "'") {
          result_str = result_str.slice(1)
        }

        if (result_str[result_str.length - 1] === '"' || result_str[result_str.length - 1] === "'") {
          result_str = result_str.slice(0, result_str.length - 1)
        }

        let str_arr = []
        let end_flag = false
        let temp_result_str = result_str

        while (end_flag === false) {
          for (let i = 0; i < models.length; i++) {
            if (temp_result_str.indexOf(models[i].model_name.trim()) !== -1) {
              let model_name_str_index = temp_result_str.indexOf(models[i].model_name.trim())

              str_arr.push({
                str: temp_result_str.substr(0, model_name_str_index),
                comp: models[i],
              })

              temp_result_str = temp_result_str.substr(model_name_str_index + models[i].model_name.trim().length)

              break
            } else {
            }

            if (temp_result_str.indexOf(models[i].model_name.trim()) === -1 && i === models.length - 1) {
              end_flag = true
            }
          }
        }

        str_arr.push({
          str: temp_result_str,
          comp: {},
        })

        setStrArr(str_arr)
        setImageUrl(result_of_choice.image_url)
        setModelName(result_of_choice.model_name)

        let temp_product_url: any = ''
        for (let i = 0; i < models.length; i++) {
          if (models[i].model_name.trim() === result_of_choice.model_name.trim()) {
            temp_product_url = models[i].product_url
            setModelObj(models[i])
            setIframLink(models[i].iframe_url)
            break
          }
        }

        if (temp_product_url === '') {
          temp_product_url = result_of_choice.product_url
        }

        if (temp_product_url.indexOf('tag=') !== -1) {
          let temp_str = temp_product_url.substring(temp_product_url.indexOf('tag='))
          let length = temp_str.indexOf('&')
          let temp_str1

          if (length === -1) {
            temp_str1 = temp_product_url.substring(temp_product_url.indexOf('tag='))
          } else if (length !== -1) {
            temp_str1 = temp_product_url.substr(temp_product_url.indexOf('tag='), length)
          }

          temp_product_url = temp_product_url.replace(temp_str1, 'tag=' + localStorage.getItem('amazon_tracking_id'))
        } else if (temp_product_url.indexOf('tag=') === -1) {
          if (temp_product_url.indexOf('?') === -1) {
            temp_product_url = temp_product_url + '?tag=' + localStorage.getItem('amazon_tracking_id')
          } else {
            temp_product_url = temp_product_url + '&tag=' + localStorage.getItem('amazon_tracking_id')
          }
        }
        setProductUrl(temp_product_url)
        setAnalyzeResult(result_str)
        setIsLoading(false)
      }
    })()
  }, [showResult])

  const cacheImages = async (srcArray: any) => {
    const promises = await srcArray.map((src: any) => {
      return new Promise(function (resolve: any, reject: any) {
        const img = new Image()

        img.src = src
        img.onload = resolve()
        img.onerror = reject()
      })
    })

    await Promise.all(promises)
  }

  const onAnswerSelected = (question: string, answer: any, index: any) => {
    ;(async () => {
      setSelectedAnswerIndex((prev: any) => {
        let next = [...prev]
        next[activeQuestion] = index
        return next
      })

      setVisible(false)

      await record_each_question_answers(quiz_id, activeQuestion, answer.choice)

      await new Promise((r) => setTimeout(r, 800))

      setResult((prev: any) => {
        let new_result = [...prev]
        new_result.push({ question, answer: answer.choice })
        return new_result
      })

      setProgress((100 / questions.length) * (activeQuestion + 1))

      if (activeQuestion !== questions.length - 1) {
        setActiveQuestion((prev) => prev + 1)
      } else {
        setShowResult(true)
      }

      setVisible(true)
    })()
  }

  const addLeadingZero = (number: number) => (number > 9 ? number : `0${number}`)

  const click_prev = () => {
    if (activeQuestion !== 0) {
      setResult((prev: any) => {
        let new_result = [...prev]
        new_result.pop()
        return new_result
      })
      setProgress((100 / questions.length) * (activeQuestion - 1))
      setActiveQuestion((prev) => prev - 1)
    }
  }

  function CircularProgressWithLabel(props: CircularProgressProps & { value: number; text_size: any }) {
    return (
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props} />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant={props.text_size} component={props.text_size} color="text.secondary">{`${Math.round(
            props.value
          )}%`}</Typography>
        </Box>
      </Box>
    )
  }

  function createMarkup(str: string) {
    return { __html: str }
  }

  const click_model_a = (url: string, model: any) => {
    // console.log(model)
    let gclid = localStorage.getItem('gclid')
    let msclkid = localStorage.getItem('msclkid')
    let search = localStorage.getItem('search')
    let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
    let _id = localStorage.getItem('_id')
    recordUserAndIds(gclid, msclkid, amazon_tracking_id, url, search, _id, model.levanta_obj)
      .then((result) => {
        ;(async () => {
          await update_text_link_of_user_info_on_quiz(quiz_id, url)
        })()
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  const click_model_a_image = (url: string, model: any) => {
    // console.log(model)
    let gclid = localStorage.getItem('gclid')
    let msclkid = localStorage.getItem('msclkid')
    let search = localStorage.getItem('search')
    let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
    let _id = localStorage.getItem('_id')
    recordUserAndIds(gclid, msclkid, amazon_tracking_id, url, search, _id, model.levanta_obj)
      .then((result) => {
        ;(async () => {
          await update_image_link_of_user_info_on_quiz(quiz_id, url)
        })()
      })
      .catch((err) => {
        // console.log(err)
      })
  }

  const goto_quiz_page = () => {
    setPageStatus('a')
  }

  const goto_best_url = () => {
    (async () => {
      // setIsLoading(true)

      // let link = window.location.href
      // let search = location.search
      // let gclid = location.search
      // let msclkid = location.search

      // if(gclid.indexOf('gclid=') === -1) {
      //     gclid = '0'
      // } else {
      //   gclid = gclid.slice(gclid.indexOf('gclid=') + 6)
      //   if(gclid.indexOf('&') !== -1){
      //       gclid = gclid.slice(0, gclid.indexOf('&'))
      //   }
      // }

      // if(msclkid.indexOf('msclkid=') === -1) {
      //     msclkid = '0'
      // } else {
      //   msclkid = msclkid.slice(msclkid.indexOf('msclkid=') + 8)
      //   if(msclkid.indexOf('&') !== -1){
      //       msclkid = msclkid.slice(0, msclkid.indexOf('&'))
      //   }
      // }

      // localStorage.setItem('gclid', gclid)
      // localStorage.setItem('msclkid', msclkid)
      // localStorage.setItem('search', search)
      // localStorage.setItem('link', link)

      // await checkAndAdd_userAndId()

      // const read_quiz_result = await read_quiz_with_quiz_url(quiz_url)
      // const quiz = read_quiz_result.result

      // setIsLoading(false)
      if (quiz.best_url.best_url === undefined || quiz.best_url.best_url.trim() === '') {
      } else {
        window.open(`${quiz.best_url.best_url}`, '_blank')
        // console.log(quiz._id)
        // console.log('record best url visited user ... ')
        record_best_url_visited_user(quiz._id)
      }
    })()
  }

  return (
    <>
      {page_status === 'a' ? (
        <>
          {
            // isLoading === true || isCircleLoading === true ?
            isCircleLoading === true ? (
              <CustomWrapperItems>
                <div className="quiz_header">
                  <div className="quiz_header_container">
                    <div className="quiz_col_2 quiz_p_0 quiz_col_2_left">
                      {!showResult ? (
                        <div onClick={() => click_prev()} className="quiz_back_href">
                          <svg width="24" height="24" viewBox="0 0 24 24">
                            <path
                              fillRule="evenodd"
                              clipRule="evenodd"
                              d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"
                            ></path>
                          </svg>
                        </div>
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="quiz_col_auto quiz_p_0">
                      <div className="quiz_navbar_brand">
                        <a href="/quiz/list/all" className="quiz_brand_href">
                          Product Matcher
                        </a>
                      </div>
                    </div>
                    <div className="quiz_col_2 quiz_p_0 quiz_col_2_right">
                      <div className="quiz_step_count">
                        {!showResult ? (
                          <div>
                            <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                            <span className="total-question">/{addLeadingZero(questions.length)}</span>
                          </div>
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="progress_div">
                    <CustomLinearProgress variant="determinate" value={progress} color="primary" />
                  </div>
                </div>
                <div className={visible ? 'quiz_div_fadeIn' : 'quiz_div_fadeOut'}>
                  <div className="result">
                    <CustomGrid container spacing={0} className="container_grid">
                      <CustomGrid item sm={12} className="circle_loading_main_grid">
                        <CircularProgressWithLabel value={circleprogress} size={250} text_size="h2" />
                      </CustomGrid>
                      <CustomGrid container spacing={0} className="circle_loading_text_div">
                        Finding the perfect {product_name} specifically for you
                      </CustomGrid>
                    </CustomGrid>
                  </div>
                </div>
              </CustomWrapperItems>
            ) : (
              <CustomWrapperItems>
                {isLoading ? (
                  <Backdrop
                    sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                    open={isLoading}
                    // onClick={handleClose}
                  >
                    <CircularProgress color="inherit" />
                    <p>&nbsp;&nbsp; Loading ...</p>
                  </Backdrop>
                ) : (
                  <>
                    <div className="quiz_header">
                      <div className="quiz_header_container">
                        <div className="quiz_col_2 quiz_p_0 quiz_col_2_left">
                          {!showResult ? (
                            <div onClick={() => click_prev()} className="quiz_back_href">
                              <svg width="24" height="24" viewBox="0 0 24 24">
                                <path
                                  fillRule="evenodd"
                                  clipRule="evenodd"
                                  d="M3.414 10.657L3.071 11 2 12.071l1.414 1.414 6.364 6.364a1 1 0 101.414-1.414L5.757 13h14.314a1 1 0 100-2H5.9l5.293-5.293a1 1 0 00-1.414-1.414l-6.364 6.364z"
                                ></path>
                              </svg>
                            </div>
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="quiz_col_auto quiz_p_0">
                          <div className="quiz_navbar_brand">
                            <a href="/quiz/list/all" className="quiz_brand_href">
                              Product Matcher
                            </a>
                          </div>
                        </div>
                        <div className="quiz_col_2 quiz_p_0 quiz_col_2_right">
                          <div className="quiz_step_count">
                            {!showResult ? (
                              <div>
                                <span className="active-question-no">{addLeadingZero(activeQuestion + 1)}</span>
                                <span className="total-question">/{addLeadingZero(questions.length)}</span>
                              </div>
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                      <div className="progress_div">
                        <CustomLinearProgress variant="determinate" value={progress} color="primary" />
                      </div>
                    </div>
                    <div className={visible ? 'quiz_div_fadeIn' : 'quiz_div_fadeOut'}>
                      {!showResult ? (
                        <div className="parent_container">
                          <div className="quiz-container" style={quiz_img_url === '' || quiz_img_url === undefined || quiz_img_url.trim() === '' ? {width: '100%'} : {}}>
                          {/* <div className="quiz-container"> */}
                            <div>
                              <div>
                                <h2>{question}</h2>
                                {/* <span>{question}</span> */}
                              </div>
                            </div>
                            <div className="choice_div">
                              <div>
                                <ul>
                                  {choices.length > 0 &&
                                    choices.map((answer: any, index: number) => (
                                      <li
                                        onClick={() => onAnswerSelected(question, answer, index)}
                                        key={index}
                                        className={selectedAnswerIndex[activeQuestion] === index ? 'selected-answer' : undefined}
                                      >
                                        {answer.choice}
                                        <div
                                          className={
                                            selectedAnswerIndex[activeQuestion] === index ? 'check_div_fadeIn' : 'check_div_fadeOut'
                                          }
                                        >
                                          {selectedAnswerIndex[activeQuestion] === index ? <CheckIcon color="primary" /> : <></>}
                                        </div>
                                      </li>
                                    ))}
                                </ul>
                              </div>
                            </div>
                          </div>
                          <div className={'quiz-img-container'} style={quiz_img_url === '' || quiz_img_url === undefined || quiz_img_url.trim() === '' ? {display: 'none'} : {}}>
                          {/* <div className="quiz-img-container"> */}
                            <img
                              src={
                                quiz_img_url === '' || quiz_img_url === undefined || quiz_img_url.trim() === ''
                                  ? require('assets/empty.png').default
                                  : quiz_img_url
                              }
                              onLoad={() => {
                                URL.revokeObjectURL(
                                  quiz_img_url === '' || quiz_img_url === undefined || quiz_img_url.trim() === ''
                                    ? require('assets/empty.png').default
                                    : quiz_img_url
                                )
                              }}
                            />
                          </div>
                        </div>
                      ) : (
                        <div className="result">
                          <CustomGrid container spacing={0} className="container_grid">
                            <CustomGrid item sm={12} className="main_grid">
                              <h3>{model_name}</h3>
                            </CustomGrid>
                          </CustomGrid>

                          <CustomGrid container spacing={0} className="container_grid result_container_grid">
                            <CustomGrid item sm={6} className="result_div">
                              <Page>
                                <section className="imginner">
                                  <aside className="imageupload">
                                    <div
                                      className="imageuploadinner"
                                      onClick={() => {
                                        click_model_a_image(product_url, model_obj)
                                        window.open(`${product_url}`, '_blank')
                                      }}
                                    >
                                      <img
                                        src={image_url}
                                        onLoad={() => {
                                          URL.revokeObjectURL(image_url)
                                        }}
                                      />
                                    </div>
                                  </aside>
                                  <div className="dropzoneOuter"></div>
                                </section>
                              </Page>
                              <div>
                                <CustomBox>
                                  <CustomA
                                    href={product_url}
                                    target="_blank"
                                    onClick={() => {
                                      click_model_a_image(product_url, model_obj)
                                    }}
                                  >
                                    <CustomButton sx={{ textTransform: 'none' }}>Check Price</CustomButton>
                                  </CustomA>
                                  {product_url.indexOf('amazon.com') > -1 ? (
                                    <img src={require('assets/buttonUnderImage.webp').default} alt="" />
                                  ) : (
                                    <></>
                                  )}
                                </CustomBox>
                              </div>
                            </CustomGrid>
                            <CustomGrid item sm={6} className="analyze_result_div">
                              {/* <div dangerouslySetInnerHTML={createMarkup(analyze_result)} /> */}
                              {str_arr.length > 0 &&
                                str_arr.map((ele: any, index: number) => {
                                  // console.log(index)
                                  if (index === str_arr.length - 1) {
                                    return <span>{ele.str}</span>
                                  } else {
                                    return (
                                      <span key={index}>
                                        {ele.str}
                                        <a
                                          href={ele.comp.product_url}
                                          target="blank"
                                          onClick={() => click_model_a(ele.comp.product_url, ele.comp)}
                                        >
                                          {ele.comp.model_name.trim()}
                                        </a>
                                      </span>
                                    )
                                  }
                                })}
                            </CustomGrid>
                            <CustomGrid item sm={12} className={'iframe_div'}>
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center', padding: '15px 0px' }}>
                                {iframe_link !== '' && iframe_link !== undefined ? (
                                  <iframe
                                    src={iframe_link}
                                    height="880"
                                    width="90%"
                                    title="quiz iframe"
                                    style={{ borderStyle: 'ridge' }}
                                  ></iframe>
                                ) : (
                                  <></>
                                )}
                              </div>
                            </CustomGrid>
                          </CustomGrid>
                        </div>
                      )}
                    </div>
                  </>
                )}
              </CustomWrapperItems>
            )
          }

          <Mobile_Quiz
            showResult={showResult}
            activeQuestion={activeQuestion}
            questions={questions}
            progress={progress}
            visible={visible}
            question={question}
            choices={choices}
            selectedAnswerIndex={selectedAnswerIndex}
            isLoading={isLoading}
            analyze_result={analyze_result}
            click_prev={click_prev}
            addLeadingZero={addLeadingZero}
            onAnswerSelected={onAnswerSelected}
            circleprogress={circleprogress}
            isCircleLoading={isCircleLoading}
            CircularProgressWithLabel={CircularProgressWithLabel}
            product_name={product_name}
            model_name={model_name}
            image_url={image_url}
            product_url={product_url}
          />
        </>
      ) : page_status === 'b' ? (
        <>
          <CustomWrapperItems1>
            {isLoading ? (
              <Backdrop
                sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                open={isLoading}
                // onClick={handleClose}
              >
                <CircularProgress color="inherit" />
                <p>&nbsp;&nbsp; Loading ...</p>
              </Backdrop>
            ) : (
              <></>
            )}
            <div className="quiz_header">
              <div className="quiz_header_container">
                <div className="quiz_col_2 quiz_p_0 quiz_col_2_left"></div>
                <div className="quiz_col_auto quiz_p_0">
                  <div className="quiz_navbar_brand">
                    <a href="/quiz/list/all" className="quiz_brand_href">
                      Product Matcher
                    </a>
                  </div>
                </div>
                <div className="quiz_col_2 quiz_p_0 quiz_col_2_right">
                  <div className="quiz_step_count"></div>
                </div>
              </div>
              <div className="progress_div"></div>
            </div>
            <div className={visible ? 'quiz_div_fadeIn' : 'quiz_div_fadeOut'}>
              <div className="quiz-container1">
                <div>
                  <div>
                    <h2>
                      Would you like to answer a few short questions so we can find the single best {quiz.product_name} for your needs?
                    </h2>
                    <h4>We will narrow down the options from 450 available models.</h4>
                    {/* <span>{question}</span> */}
                  </div>
                </div>
                <div className="choice_div">
                  <ul>
                    <li
                      onClick={() => {
                        goto_quiz_page()
                      }}
                      // key={index}
                      className={''}
                    >
                      <div className={'txt_index'}>A</div>
                      &nbsp;&nbsp;&nbsp;Yes
                      <div className={'check_div_fadeIn'}>
                        {/* {selectedAnswerIndex[activeQuestion] === index ? <CheckIcon color='primary' />: <></>} */}
                      </div>
                    </li>

                    <li
                      onClick={() => {
                        goto_best_url()
                      }}
                      // key={index}
                      className={''}
                    >
                      <div className={'txt_index'}>B</div>
                      &nbsp;&nbsp;&nbsp;No, just take me to the best one
                      <div className={'check_div_fadeIn'}>
                        {/* {selectedAnswerIndex[activeQuestion] === index ? <CheckIcon color='primary' />: <></>} */}
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </CustomWrapperItems1>
        </>
      ) : (
        <>
          {isLoading ? (
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
              open={isLoading}
              // onClick={handleClose}
            >
              <CircularProgress color="inherit" />
              <p>&nbsp;&nbsp; Loading ...</p>
            </Backdrop>
          ) : (
            <></>
          )}
        </>
      )}
    </>
  )
}

const CustomA = styled.a`
  text-decoration: none;
  // width: 100%;
`

const CustomButton = styled(Button)``

const CustomBox = styled(Box)`
  display: flex;
  flex-direction: column;
  padding: 0 auto;
  justify-content: center;
  align-items: center;
  padding: 5px;

  img {
    width: 133px;
    height: 24px;
    margin-top: 5px;
  }

  ${CustomButton} {
    width: 133px;
    padding: 15px;
    cursor: pointer;
    font-family: sans-serif;
    font-weight: 600;
    line-height: 1.33;
    font-size: 15px !important;
    letter-spacing: 0.03em !important;
    color: white !important;
    background-color: #f2a842 !important;
    border: 1px solid #f09637 !important;

    &:hover {
      border: 1px solid #f2a842 !important;
      background-color: #f09637 !important;
    }
  }
`

const CustomWrapperItems = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  @media (max-width: ${screenSizes.mediaM}px) {
    display: none;
  }
  // background: linear-gradient(145deg,#ffffff 0.03%,#147cfd 99.96%)!important;
  background: #fff;
`

const CustomWrapperItems1 = styled.div`
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: start;
  // @media (max-width: ${screenSizes.mediaM}px) {
  //     display: none;
  // }
  // background: linear-gradient(145deg,#ffffff 0.03%,#147cfd 99.96%)!important;
  background: #fff;
`

const CustomGrid = styled(Grid)`
  @media (max-width: ${screenSizes.mediaXLL}px) {
    &.container_grid {
      padding: 0px 20px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXLL}px) {
    &.container_grid {
      padding: 0px 30px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXL}px) {
    &.container_grid {
      padding: 0px 40px !important;
    }
  }
  @media (min-width: ${screenSizes.mediaXXXX}px) {
    &.container_grid {
      padding: 0px 50px !important;
    }
  }

  &.result_container_grid {
    padding: 5px 0px 15px !important;
  }

  .main_grid {
    // background: linear-gradient(90.04deg,#0d5ff5 0.03%,#c0e5ff 99.96%)!important;
    // background: linear-gradient(145deg,#5c8fed 0.03%,#d1f0f7 99.96%)!important;
    padding: 0px 50px 0px !important;
    display: flex;
    justify-content: center;
  }
  .circle_loading_main_grid {
    padding: 50px 50px 0px !important;
    display: flex;
    justify-content: center;
  }

  .circle_loading_text_div {
    display: flex !important;
    justify-content: center !important;
    font-size: 22px !important;
    font-weight: 500 !important;
    padding: 20px 0 !important;
    text-align: center !important;
  }

  .result_div {
    border-right: 1px solid gray !important;
    padding: 10px !important;
  }
  .analyze_result_div {
    white-space: pre-wrap !important;
    padding: 0 20px !important;
    font-size: 18px !important;
  }
  .iframe_div {
  }
`
const CustomLinearProgress = styled(LinearProgress)`
  &.css-eglki6-MuiLinearProgress-root {
    background-color: #f0f1f1a8;
  }
`

const Page = styled.div`
  color: #fff;
  // border: 1px solid rgba(0,0,0,0.05);
  // border-radius: 24px;
  // width: fit-content;
  // min-width: 400px;
  // min-height: 360px;
  // margin-left: 20%;
  display: flex;
  justify-content: Center;
  align-items: Center;
  // @media (max-width: 1200px) {
  //   margin: auto;
  //   margin-bottom: 30px;
  // }
  .imageupload {
    display: flex;
    justify-content: Center;
    // background: #b4fbf8c7;
    // padding: 40px 130px;
    border-radius: 10px;
    position: relative;
    .imageuploadinner {
      position: relative;
      width: 500px;
      height: 500px;
      border: 1px solid gray;
      border-radius: 5px;
      // border-radius: 50%;
      overflow: hidden;
      cursor: pointer;
      img {
        height: 100%;
        width: 100%;
        object-fit: contain;
      }
    }
  }
`
