import { useSelector } from 'react-redux'
import styled from 'styled-components'
import { Button, Box   } from '@mui/material'
import { recordUserAndIds } from 'modules/api/market-api'

interface Props {
    item: any,
    num: number,
}

const ProductBox = (props: Props) => {
    const { item, num } = props;
    const selector = useSelector((state: any) => state)

    const recordIds = (e: any) => {
        e.stopPropagation()
        let gclid = localStorage.getItem('gclid')
        let msclkid = localStorage.getItem('msclkid')
        let search = localStorage.getItem('search')
        let amazon_tracking_id = localStorage.getItem('amazon_tracking_id')
        let _id = localStorage.getItem('_id')
        // recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.DetailPageURL, search, _id, undefined)
        // console.log(item.levanta_obj)
        recordUserAndIds(gclid, msclkid, amazon_tracking_id, item.DetailPageURL, search, _id, item.levanta_obj)
            .then(result => {
            })
            .catch(err => {
                // console.log(err)
            })
    }

    return (
        <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}>
        {/* // <div> */}
            <CustomBox className="parent_box">
                <CustomBox className="left_box">
                    <img src={item.Images.Primary.Large.URL ? item.Images.Primary.Large.URL : require('assets/1.jpg').default} alt="" width="120" height="120" />
                </CustomBox>
                <CustomBox className="center_box">
                    <Box className="main_box">
                        <Box className="part_box1">
                            <Box className="part_box1_right">{item.ItemInfo.ByLineInfo != null && item.ItemInfo.ByLineInfo.Brand != null ? item.ItemInfo.ByLineInfo.Brand.DisplayValue : ''}</Box>
                        </Box>
                        <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}><Box className="part_box2">{item.ItemInfo.Title.DisplayValue}</Box></CustomA>
                    </Box>
                </CustomBox>
                <CustomBox className="right_box">
                    
                    <Box>
                        <CustomA href={item.DetailPageURL} target="_blank" onClick={(e:any) => recordIds(e)}><CustomButton sx={{ textTransform: "none" }} >Check Price</CustomButton></CustomA>
                        <img src={require('assets/buttonUnderImage.webp').default} alt="" />
                    </Box>
                </CustomBox>
                <img src={require('assets/nbage1.svg').default} style={{ position: 'absolute', width: '52px', height: '32px', left: '-15px', top: '15px'}} alt="" />
                <img src={require('assets/nbage2.svg').default} style={{ position: 'absolute', width: '52px', height: '32px', left: '-22px', top: '43px', zIndex: '-1'}} alt="" />
                <OrderNumber >{num}</OrderNumber>
            </CustomBox>
        </CustomA>
        // {/* </div> */}
    )
}

const CustomA = styled.a`
    text-decoration: none;
    width: 100%;

`

const CustomButton = styled(Button)`
`
const OrderNumber = styled.span`
    position: absolute;
    width: 52px;
    height: 32px;
    left: 5px;
    top: 18px;
    font-size: 21px;
    font-weight: 600;
    color: white;
    font-family: Inter;
`

const CustomBox = styled(Box)`
        width: 100%;
        height: 180px;
        display: flex;
        background: white;
        margin: 10px 0;
        border-radius: 10px;
        position: relative;
        .left_box {
            background: white;
            margin: 0;
            width: 180px;
            display: flex!important;
            align-items: center!important;
            justify-content: center!important;
            padding-left: 30px;
            img { 
                // width: 90%!important;
                // height: 90%!important;
                object-fit: contain;
                
            }
        }
        .center_box {
            background: white;
            margin: 0;
            width: 100%;
            flex-grow: 1;
            display: flex!important;
            align-items: center!important;
            justify-content: start!important;
            padding-left: 30px;
            padding-right: 30px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .main_box {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                .part_box1{
                    display: flex;
                    flex-direction: row;
                    justify-content: flex-start;
                    align-items: center;
                    margin-bottom: 10px;
                    .part_box1_left {
                        height: 32px;
                        margin-right: 10px;
                        background: #0c90f1;
                        color: white;
                        border-radius: 4px;
                        display: flex;
                        -webkit-box-pack: center;
                        justify-content: center;
                        -webkit-box-align: center;
                        align-items: center;
                        font-weight: 600;
                        font-size: 1rem;
                        line-height: 1.5rem;
                        padding: 0 5px;
                    }
                    .part_box1_right {
                        font-family: GT Pressura,sans-serif;
                        font-weight: 700;
                        line-height: 1.33;
                        font-size: 24px;
                        letter-spacing: 0.01em;
                        color: #0f1a16;
                    }
                }
                .part_box2{
                    margin: 0px;
                    color: #008472;
                    // color: #1275bb;
                    display: -webkit-box;
                    -webkit-line-clamp: 2;
                    -webkit-box-orient: vertical;
                    overflow: hidden;
                    font-family: Inter, Arial, sans-serif;
                    font-weight: 600;
                    cursor: pointer;
                    &:hover {
                        background-color: #edecec;
                    }
                }
            }
        }
        .right_box {
            background: white;
            margin: 0;
            width: 200px;
            display: flex!important;
            align-items: center!important;
            justify-content: end!important;
            padding-right: 45px;
            img {
                width: 133px;
                height: 24px;
                margin-top: 5px;
              }
            .MuiBox-root {
                display: flex;
                flex-direction: column;
                align-items: center;
            }
        }
        ${CustomButton} {
            width: 133px;
            padding: 15px;
            cursor: pointer;
            font-family: sans-serif;
            font-weight: 600;
            line-height: 1.33;
            font-size: 15px!important;
            letter-spacing: 0.03em!important;
            color: white!important;
            background-color: #f2a842!important;
            border: 1px solid #f09637!important;
            
            &:hover {
                border: 1px solid #f2a842!important;
                background-color: #f09637!important;
            }  
        }
`

export default ProductBox